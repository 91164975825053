<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-container>
    <h1 class="text-h4 mb-3">Topwater Privacy Policy</h1>
    <v-card class="pa-4">
      <p class="mb-4">
        This privacy policy has been compiled to better serve those who are
        concerned with how their 'Personally Identifiable Information' (PII) is
        being used online. PII, as described in US privacy law and information
        security, is information that can be used on its own or with other
        information to identify, contact, or locate a single person, or to
        identify an individual in context. Please read our privacy policy
        carefully to get a clear understanding of how we collect, use, protect
        or otherwise handle your Personally Identifiable Information in
        accordance with our website.
      </p>
      <p class="mb-4">
        What personal information do we collect from the people that visit our
        blog, website or app?
      </p>
      <p class="mb-4">
        When ordering or registering on our site, as appropriate, you may be
        asked to enter your name, email address, phone number or other details
        to help you with your experience.
      </p>
      <p class="mb-1"><strong>When do we collect information?</strong></p>
      <p class="mb-4">
        We collect information from you when you register on our site or enter
        information on our site.
      </p>
      <p class="mb-1"><strong>How do we use your information?</strong></p>
      <p class="mb-4">
        We may use the information we collect from you when you register, make a
        purchase, sign up for our newsletter, respond to a survey or marketing
        communication, surf the website, or use certain other site features in
        the following ways:
        <v-alert color="secondary" variant="tonal" class="mt-3">
          <ul>
            <li>
              - To personalize your experience and to allow us to deliver the
              type of content and product offerings in which you are most
              interested.
            </li>
            <li>- To improve our website in order to better serve you.</li>
            <li>
              - To allow us to better service you in responding to your customer
              service requests.
            </li>
            <li>
              - To administer a contest, promotion, survey or other site
              feature.
            </li>
          </ul>
        </v-alert>
      </p>
      <p class="mb-1"><strong>How do we protect your information?</strong></p>
      <p class="mb-4">
        We do not use vulnerability scanning and/or scanning to PCI standards.
      </p>
      <p class="mb-2">
        We only provide articles and information. We never ask for credit card
        numbers.
      </p>
      <p class="mb-2">We use regular Malware Scanning.</p>
      <p class="mb-2">
        Your personal information is contained behind secured networks and is
        only accessible by a limited number of persons who have special access
        rights to such systems, and are required to keep the information
        confidential. In addition, all sensitive/credit information you supply
        is encrypted via Secure Socket Layer (SSL) technology.
      </p>
      <p class="mb-2">
        We implement a variety of security measures when a user enters, submits,
        or accesses their information to maintain the safety of your personal
        information.
      </p>
      <p class="mb-4">
        All transactions are processed through a gateway provider and are not
        stored or processed on our servers.
      </p>
      <p class="mb-1"><strong>Do we use 'cookies'?</strong></p>
      <p class="mb-2">
        Yes. Cookies are small files that a site or its service provider
        transfers to your computer's hard drive through your Web browser (if you
        allow) that enables the site's or service provider's systems to
        recognize your browser and capture and remember certain information. For
        instance, we use cookies to help us remember and process the items in
        your shopping cart. They are also used to help us understand your
        preferences based on previous or current site activity, which enables us
        to provide you with improved services. We also use cookies to help us
        compile aggregate data about site traffic and site interaction so that
        we can offer better site experiences and tools in the future.
      </p>
      <v-alert
        title="We use cookies to:"
        class="mb-4"
        color="secondary"
        variant="tonal"
        text="Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third-party services that track this information on our behalf."
      ></v-alert>
      <p class="mb-4">
        If you turn cookies off, Some of the features that make your site
        experience more efficient may not function properly.It won't affect the
        user's experience that make your site experience more efficient and may
        not function properly.
      </p>
      <p class="mb-1"><strong>Third-party disclosure</strong></p>
      <p class="mb-4">
        We do not sell, trade, or otherwise transfer to outside parties your
        Personally Identifiable Information unless we provide users with advance
        notice. This does not include website hosting partners and other parties
        who assist us in operating our website, conducting our business, or
        serving our users, so long as those parties agree to keep this
        information confidential. We may also release information when it's
        release is appropriate to comply with the law, enforce our site
        policies, or protect ours or others' rights, property or safety.
      </p>
      <p class="mb-1"><strong>Third-party links</strong></p>
      <p class="mb-4">
        Occasionally, at our discretion, we may include or offer third-party
        products or services on our website. These third-party sites have
        separate and independent privacy policies. We therefore have no
        responsibility or liability for the content and activities of these
        linked sites. Nonetheless, we seek to protect the integrity of our site
        and welcome any feedback about these sites.
      </p>
      <p class="mb-1"><strong>Google</strong></p>
      <p class="mb-4">
        Google's advertising requirements can be summed up by Google's
        Advertising Principles. They are put in place to provide a positive
        experience for users.
        https://support.google.com/adwordspolicy/answer/1316548?hl=en
      </p>
      <p class="mb-4">We use Google AdSense Advertising on our website.</p>
      <p class="mb-4">
        Google, as a third-party vendor, uses cookies to serve ads on our site.
        Google's use of the DART cookie enables it to serve ads to our users
        based on previous visits to our site and other sites on the Internet.
        Users may opt-out of the use of the DART cookie by visiting the Google
        Ad and Content Network privacy policy.
      </p>
      <v-alert
        variant="tonal"
        color="secondary"
        title="We have implemented the following:"
        class="mb-4"
      >
        - Remarketing with Google AdSense<br />
        - Google Display Network Impression<br />
        - Reporting Demographics and Interests Reporting
      </v-alert>
      <p class="mb-4">
        We, along with third-party vendors such as Google use first-party
        cookies (such as the Google Analytics cookies) and third-party cookies
        (such as the DoubleClick cookie) or other third-party identifiers
        together to compile data regarding user interactions with ad impressions
        and other ad service functions as they relate to our website.
      </p>
      <p class="mb-1"><strong>Opting out:</strong></p>
      <p class="mb-4">
        Users can set preferences for how Google advertises to you using the
        Google Ad Settings page. Alternatively, you can opt out by visiting the
        Network Advertising Initiative Opt Out page or by using the Google
        Analytics Opt Out Browser add on.v
      </p>
      <p class="mb-1">
        <strong>California Online Privacy Protection Act</strong>
      </p>
      <p class="mb-4">
        CalOPPA is the first state law in the nation to require commercial
        websites and online services to post a privacy policy. The law's reach
        stretches well beyond California to require any person or company in the
        United States (and conceivably the world) that operates websites
        collecting Personally Identifiable Information from California consumers
        to post a conspicuous privacy policy on its website stating exactly the
        information being collected and those individuals or companies with whom
        it is being shared. - See more at:
        http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
      </p>
      <p class="mb-4">According to CalOPPA, we agree to the following:</p>
      <p class="mb-4">Users can visit our site anonymously.</p>
      <p class="mb-4">
        Once this privacy policy is created, we will add a link to it on our
        home page or as a minimum, on the first significant page after entering
        our website.
      </p>
      <p class="mb-4">
        Our Privacy Policy link includes the word 'Privacy' and can easily be
        found on the page specified above.
      </p>
      <v-alert
        variant="tonal"
        color="secondary"
        title="We have implemented the following:"
        class="mb-4"
      >
        - On our Privacy Policy Page
      </v-alert>
      <v-alert
        variant="tonal"
        color="secondary"
        title="Can change your personal information:"
        class="mb-4"
      >
        - By logging in to your account
      </v-alert>
      <p class="mb-1">
        <strong>How does our site handle Do Not Track signals?</strong>
      </p>
      <p class="mb-4">
        We honor Do Not Track signals and Do Not Track, plant cookies, or use
        advertising when a Do Not Track (DNT) browser mechanism is in place.
      </p>
      <p class="mb-1">
        <strong>Does our site allow third-party behavioral tracking?</strong>
      </p>
      <p class="mb-4">
        It's also important to note that we allow third-party behavioral
        tracking
      </p>
      <p class="mb-4">COPPA (Children Online Privacy Protection Act)</p>
      <p class="mb-4">
        When it comes to the collection of personal information from children
        under the age of 13 years old, the Children's Online Privacy Protection
        Act (COPPA) puts parents in control. The Federal Trade Commission,
        United States' consumer protection agency, enforces the COPPA Rule,
        which spells out what operators of websites and online services must do
        to protect children's privacy and safety online.
      </p>
      <p class="mb-4">
        We do not specifically market to children under the age of 13 years old.
      </p>
      <p class="mb-4">Fair Information Practices</p>
      <p class="mb-4">
        The Fair Information Practices Principles form the backbone of privacy
        law in the United States and the concepts they include have played a
        significant role in the development of data protection laws around the
        globe. Understanding the Fair Information Practice Principles and how
        they should be implemented is critical to comply with the various
        privacy laws that protect personal information.
      </p>
      <p class="mb-4">
        In order to be in line with Fair Information Practices we will take the
        following responsive action, should a data breach occur:
      </p>
      <v-alert
        variant="tonal"
        color="secondary"
        title="We will notify you via email"
        class="mb-4"
      >
        - Within 7 business days
      </v-alert>
      <p class="mb-4">
        We also agree to the Individual Redress Principle which requires that
        individuals have the right to legally pursue enforceable rights against
        data collectors and processors who fail to adhere to the law. This
        principle requires not only that individuals have enforceable rights
        against data users, but also that individuals have recourse to courts or
        government agencies to investigate and/or prosecute non-compliance by
        data processors.
      </p>
      <p class="mb-1"><strong>CAN SPAM Act</strong></p>
      <p class="mb-4">
        The CAN-SPAM Act is a law that sets the rules for commercial email,
        establishes requirements for commercial messages, gives recipients the
        right to have emails stopped from being sent to them, and spells out
        tough penalties for violations.
      </p>
      <v-alert
        variant="tonal"
        color="secondary"
        title="We collect your email address in order to:"
        class="mb-4"
      >
        - Send information, respond to inquiries, and/or other requests or
        questions <br />
        - Market to our mailing list or continue to send emails to our clients
        after the original transaction has occurred.
      </v-alert>
      <v-alert
        variant="tonal"
        color="secondary"
        title="To be in accordance with CANSPAM, we agree to the following:"
        class="mb-4"
      >
        - Allow users to unsubscribe by using the link at the bottom of each
        email.
      </v-alert>
      <v-alert
        variant="tonal"
        color="secondary"
        title="If at any time you would like to unsubscribe from receiving future emails, you can email us at"
        class="mb-4"
      >
        - Follow the instructions at the bottom of each email. and we will
        promptly remove you from ALL correspondence.
      </v-alert>
      <p class="mb-1"><strong>Contacting Us</strong></p>
      <p class="mb-4">
        If there are any questions regarding this privacy policy, you may
        contact us using the information below.
      </p>
      <p><a href="./">topwaterseries.com</a></p>
      <p><a href="./ContactUs">topwaterseries.com/ContactUs</a></p>
      <small class="text-small">Last Edited on 2020-04-24</small>
    </v-card>
  </v-container>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
export default defineComponent({
  name: "PrivacyPolicy",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
  },
  data: () => ({}),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
