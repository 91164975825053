<template>
  <!-- <v-card class="pa-4" variant="tonal" color="secondary">
    <router-link
      class="pa-2"
      :to="item.url"
      exact
      v-for="item in angleSidebar"
      :key="item"
    >
      <v-icon class="mr-3" width="30">{{ item.icon }}</v-icon>
      {{ item.title }}
    </router-link>
  </v-card> -->
  <v-navigation-drawer
    v-model="drawer"
    :rail="rail"
    permanent
    color="grey-lighten-3"
    @click="rail = false"
  >
    <v-list-item
      prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg"
      title="John Leider"
      nav
    >
      <template v-slot:append>
        <v-btn
          variant="text"
          icon="mdi:mdi-chevron-left"
          @click.stop="rail = !rail"
        ></v-btn>
      </template>
    </v-list-item>

    <v-divider></v-divider>
    <v-list density="compact" nav>
      <v-list-item
        v-for="item in angleSidebar"
        :key="item"
        :prepend-icon="item.icon"
      >
        <router-link :to="item.url" exact>
          {{ item.title }}
        </router-link>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "AnglerSidebar",

  data: () => ({
    drawer: true,
    rail: true,
    angleSidebar: [
      {
        title: "Dashboard",
        url: "/AnglerDashboard",
        icon: "mdi:mdi-home-outline",
      },
      { title: "Live well", url: "/LiveWell", icon: "mdi:mdi-fish" },
      {
        title: "View Standings",
        url: "/ViewStandings",
        icon: "mdi:mdi-star-outline",
      },
      {
        title: "Register for a Tournament",
        url: "/registerTournament",
        icon: "mdi:mdi-file-document-edit-outline",
      },
      {
        title: "Identifiers/Codes",
        url: "/TopwaterIdentifiers",
        icon: "mdi:mdi-barcode-scan",
      },
      {
        title: "Profile",
        url: "/AnglerProfile",
        icon: "mdi:mdi-account-outline",
      },
      {
        title: "Profile Settings",
        url: "/ProfileSettings",
        icon: "mdi:mdi-cog-outline",
      },
      {
        title: "Messaging",
        url: "/AnglerInbox",
        icon: "mdi:mdi-message-outline",
      },
      {
        title: "Store",
        url: "/store",
        icon: "mdi:mdi-cart-outline",
      },
      {
        title: "Add/ Withdrawl Funds",
        url: "/AddWithdrawl",
        icon: "mdi:mdi-currency-usd",
      },
      {
        title: "Topbox",
        url: "/TopBox",
        icon: "mdi:mdi-star-box-multiple-outline",
      },
    ],
  }),
};
</script>
<style lang="scss" scoped>
a {
  display: block;
  color: #3a3a3a;
  white-space: nowrap;
  &:hover {
    color: #077198;
  }
}
.router-link-active {
  color: #077198;
  border-radius: 4px;
  &.router-link-exact-active {
    color: #077198;
  }
}
</style>
