<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <!---Special Events container -->
  <v-parallax :src="topoImage">
    <div
      class="d-flex flex-column fill-height justify-center align-center text-white"
    >
      <v-container class="pb-10">
        <h1 class="text-h4 mb-7 mt-3">Specail Events</h1>
        <v-item-group v-model="selection" multiple>
          <v-row>
            <v-col
              v-for="(item, i) in items"
              :key="i"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-item v-slot="{ isSelected, toggle }">
                <v-hover v-slot="{ isHovering, props }" open-delay="200">
                  <v-card
                    :elevation="isHovering ? 16 : 2"
                    :class="{ 'on-hover': isHovering }"
                    v-bind="props"
                  >
                    <v-img
                      :src="`${item.src}`"
                      cover
                      class="text-right pa-2"
                      @click="toggle"
                    >
                      <v-btn
                        color="red"
                        class="p-0"
                        :icon="`${
                          isSelected ? 'mdi:mdi-heart' : 'mdi:mdi-heart-outline'
                        }`"
                      ></v-btn>
                    </v-img>

                    <v-card-title class="pt-1 pb-1">
                      {{ item.eventTitle }}
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="pt-2 pb-2">
                      <p class="mb-1">
                        <v-icon color="primary" class="mr-1"
                          >mdi:mdi-calendar-outline</v-icon
                        >{{ item.eventDate }}
                      </p>
                      <p>
                        <v-icon color="primary" class="mr-1"
                          >mdi:mdi-map-marker-outline</v-icon
                        >{{ item.eventLocation }}
                      </p>
                    </v-card-text>
                    <v-btn
                      varient="text"
                      :rounded="0"
                      color="secondary"
                      size="large"
                      block
                      @click="item.reveal = true"
                    >
                      Register
                    </v-btn>
                    <v-expand-transition>
                      <v-card
                        v-if="item.reveal"
                        class="v-card--reveal d-flex flex-direction-column justify-space-between"
                        style="height: 100%"
                        color="primary"
                      >
                        <v-card-text class="pb-0 text-center">
                          <p class="text-h5 text--primary">
                            {{ item.eventTitle }}
                          </p>
                          <v-divider class="mt-4 mb-4"></v-divider>
                          <p class="mb-10 text-h6 font-weight-light">
                            Thankyou for registering for the
                            {{ item.eventTitle }}.
                          </p>
                        </v-card-text>
                        <v-card-actions class="pt-0">
                          <v-btn
                            variant="text"
                            color="white"
                            block
                            @click="item.reveal = false"
                          >
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-expand-transition>
                  </v-card>
                </v-hover>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-container>
    </div>
  </v-parallax>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
export default defineComponent({
  name: "HomeView",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
  },
  data: () => ({
    topoImage: require("../assets/images/topomap.png"),

    items: [
      {
        src: require("../assets/images/special-events/Artboard1.png"),
        eventTitle: "Open Series",
        eventDate: "20 May 2023",
        eventLocation: "Kalamazoo River",
      },
      {
        src: require("../assets/images/special-events/Artboard2.png"),
        eventTitle: "Open Series",
        eventDate: "15 July",
        eventLocation: "Kalamazoo River",
      },
      {
        src: require("../assets/images/special-events/Artboard3.png"),
        eventTitle: "Open Series",
        eventDate: "23 September",
        eventLocation: "Kalamazoo River",
      },
      {
        src: require("../assets/images/special-events/Artboard4.png"),
        eventTitle: "Open Series",
        eventDate: "01 July - 01 August",
        eventLocation: "Virtual Online",
      },
      {
        src: require("../assets/images/special-events/Artboard1.png"),
        eventTitle: "Open Series",
        eventDate: "20 May 2023",
        eventLocation: "Kalamazoo River",
      },
      {
        src: require("../assets/images/special-events/Artboard2.png"),
        eventTitle: "Open Series",
        eventDate: "15 July",
        eventLocation: "Kalamazoo River",
      },
      {
        src: require("../assets/images/special-events/Artboard3.png"),
        eventTitle: "Open Series",
        eventDate: "23 September",
        eventLocation: "Kalamazoo River",
      },
      {
        src: require("../assets/images/special-events/Artboard4.png"),
        eventTitle: "Open Series",
        eventDate: "01 July - 01 August",
        eventLocation: "Virtual Online",
      },
    ],
    selection: [],
  }),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
<style lang="scss" scoped>
.v-card.on-hover.v-theme--dark {
  background-color: rgba(#fff, 0.8);
  > .v-card__text {
    color: #000;
  }
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
  flex-direction: column;
}
</style>
