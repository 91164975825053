<template>
  <v-container>
    <a class="d-block mb-10" href="#">
      <v-img :src="trackerTopwater" :alt="trackerTopwater" />
    </a>
  </v-container>
</template>
<script>
export default {
  name: "FooterComponent",

  data: () => ({
    trackerTopwater: require("../assets/images/TRACKERADBOX.png"),
  }),
};
</script>
