<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <!---Billing Container -->
  <v-responsive class="mb-10 mx-auto pa-4" max-width="1024">
    <h1 class="text-h4 mt-4 mb-4">Find A Topwater Event Near You</h1>
    <v-tabs v-model="findLeagueTabs" color="primary" show-arrows>
      <v-tab value="findLeague" prepend-icon="mdi:mdi-sail-boat">League</v-tab>
      <v-tab value="findTournament" prepend-icon="mdi:mdi-tournament"
        >Tournament</v-tab
      >
    </v-tabs>

    <v-window v-model="findLeagueTabs">
      <!--findLeague tab-->
      <v-window-item value="findLeague">
        <v-card class="mt-10">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                prepend-inner-icon="mdi:mdi-magnify"
                color="primary"
                class="text-white"
                variant="solo"
                label="City, State or Zip code"
              >
              </v-text-field>
              <v-sheet class="overflow-auto" height="250">
                <v-card
                  color="secondary"
                  title="6.1 Miles"
                  subtitle="Topwater Lakeshore League"
                  variant="tonal"
                  text="Tuesday Nights"
                  class="mb-3"
                ></v-card>
                <v-card
                  color="primary"
                  title="6.1 Miles"
                  subtitle="Topwater Lakeshore League"
                  variant="tonal"
                  text="Tuesday Nights"
                  class="mb-3"
                ></v-card>
                <v-card
                  color="secondary"
                  title="6.1 Miles"
                  subtitle="Topwater Lakeshore League"
                  variant="tonal"
                  text="Tuesday Nights"
                  class="mb-3"
                ></v-card>
                <v-card
                  color="primary"
                  title="6.1 Miles"
                  subtitle="Topwater Lakeshore League"
                  variant="tonal"
                  text="Tuesday Nights"
                  class="mb-3"
                ></v-card>
              </v-sheet>
            </v-col>
            <v-col cols="12" sm="6">
              <GMapMap
                :center="center"
                :zoom="10"
                map-type-id="terrain"
                style="width: 100%; height: 330px"
                class="mb-7"
              >
                <MarkerClusterer>
                  <GMapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="false"
                    :icon="customMarker"
                    @click="center = m.position"
                  />
                </MarkerClusterer>
              </GMapMap>
            </v-col>
          </v-row>
        </v-card>
      </v-window-item>
      <!--findTournament tab-->
      <v-window-item value="findTournament">
        <v-card class="mt-10">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                prepend-inner-icon="mdi:mdi-magnify"
                color="secondary"
                class="text-white"
                variant="solo"
                label="City, State or Zip code"
              >
              </v-text-field>
              <v-sheet class="overflow-auto" height="250">
                <v-card
                  color="primary"
                  title="6.1 Miles"
                  subtitle="Topwater Lakeshore League"
                  variant="tonal"
                  text="Tuesday Nights"
                  class="mb-3"
                ></v-card>
                <v-card
                  color="secondary"
                  title="6.1 Miles"
                  subtitle="Topwater Lakeshore League"
                  variant="tonal"
                  text="Tuesday Nights"
                  class="mb-3"
                ></v-card>
                <v-card
                  color="primary"
                  title="6.1 Miles"
                  subtitle="Topwater Lakeshore League"
                  variant="tonal"
                  text="Tuesday Nights"
                  class="mb-3"
                ></v-card>
                <v-card
                  color="secondary"
                  title="6.1 Miles"
                  subtitle="Topwater Lakeshore League"
                  variant="tonal"
                  text="Tuesday Nights"
                  class="mb-3"
                ></v-card>
              </v-sheet>
            </v-col>
            <v-col cols="12" sm="6">
              <GMapMap
                :center="center"
                :zoom="10"
                map-type-id="terrain"
                style="width: 100%; height: 330px"
                class="mb-7"
              >
                <MarkerClusterer>
                  <GMapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="false"
                    :icon="customMarker"
                    @click="center = m.position"
                  />
                </MarkerClusterer>
              </GMapMap>
            </v-col>
          </v-row>
        </v-card>
      </v-window-item>
    </v-window>
  </v-responsive>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
import MarkerClusterer from "@googlemaps/markerclusterer";
export default defineComponent({
  name: "HomeView",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
    MarkerClusterer,
  },

  data: () => ({
    findLeagueTabs: null,
    center: { lat: 40.273502, lng: -86.126976 },
    customMarker: require("../assets/images/fish.svg"),
    markers: [
      {
        position: {
          lat: 40.273502,
          lng: -86.126976,
        },
      },
      {
        position: {
          lat: 40.173502,
          lng: -86.026976,
        },
      },
      {
        position: {
          lat: 40.373502,
          lng: -86.125974,
        },
      },
    ],
  }),

  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
