<template>
  <div class="quicklinks-holder">
    <div class="quicklinks-slider">
      <Splide
        :options="{
          rewind: true,
          perPage: 7,
          perMove: 1,
          autoplay: true,
          pagination: false,
          wheel: false,
          drag: free,
          snap: true,
          keyboard: true,
          focus: 'center',
          slideFocus: true,
          arrows: false,
          breakpoints: {
            992: {
              perPage: 5,
            },
            640: {
              perPage: 3,
            },
          },
        }"
        aria-label="My Favorite Images"
      >
        <SplideSlide
          v-for="n in quickLinks"
          :key="n.id"
          data-splide-interval="3000"
        >
          <a class="quicklink" :href="n.linkUrl"
            ><span>{{ n.linkName }}</span></a
          >
        </SplideSlide>
      </Splide>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    model: null,
    quickLinks: [
      { linkName: "Results", id: 1, linkUrl: "/results" },
      { linkName: "Upcoming Tournaments", id: 2, linkUrl: "/tournaments" },
      { linkName: "News", id: 3, linkUrl: "/news" },
      { linkName: "AOY Scores", id: 4, linkUrl: "/aoy" },
      { linkName: "Sponsors", id: 5, linkUrl: "/sponsors" },
      { linkName: "Anglers of the week", id: 6, linkUrl: "/anglers" },
      { linkName: "Reminders", id: 7, linkUrl: "/reminders" },
      { linkName: "Results", id: 1, linkUrl: "/results" },
      { linkName: "Upcoming Tournaments", id: 2, linkUrl: "/tournaments" },
      { linkName: "News", id: 3, linkUrl: "/news" },
      { linkName: "AOY Scores", id: 4, linkUrl: "/aoy" },
      { linkName: "Sponsors", id: 5, linkUrl: "/sponsors" },
      { linkName: "Anglers of the week", id: 6, linkUrl: "/anglers" },
      { linkName: "Reminders", id: 7, linkUrl: "/reminders" },
    ],
  }),
};
</script>
<style lang="scss" scoped>
.quicklinks-holder {
  z-index: 999;
  position: sticky;
  top: 68px;
  background: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  overflow: hidden;
  .quicklinks-slider {
    margin: 0 -0.7rem;
  }
}

.quicklink {
  background: transparent;
  color: #fff;
  border-radius: 0;
  position: relative;
  border-right: 1px solid #fff;
  transition: all 0.5s;
  font-weight: 300;
  height: 70px;
  text-align: center;
  z-index: 1;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  span {
    width: 100px;
  }
  @media only screen and (max-width: 550px) {
    font-size: 12px;
  }
  &:hover {
    background: rgba(255, 137, 14, 0.7);
    color: #000;
    font-weight: 600;
    transition: all 0.5s;
  }
}
.is-visible.is-active {
  .quicklink {
    background: rgba(255, 137, 14, 0.7);
    color: #000;
    font-weight: 600;
    transition: all 0.5s;
  }
}
</style>
