<template>
  <!-- header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-container>
    <h1 class="text-h4 mb-7 mt-2">Register</h1>
    <v-divider class="mb-7"></v-divider>
    <!-- code varification -->
    <v-form v-if="!showVerifyTick" @submit.prevent>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="verifyCode"
            :rules="rules"
            label="Verificaion Code"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn
            class="mr-5"
            color="secondary"
            variant="flat"
            type="submit"
            width="200"
            height="50"
          >
            Verify
          </v-btn>
          <v-btn
            class="text-decoration-none"
            href="/register"
            color="default"
            variant="flat"
            height="50"
          >
            Resend Code
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="verifyCode.length > 4" class="text-success mt-2 mb-4">
        <v-alert type="success" variant="outlined">
          Thankyou, Please select a plan below
        </v-alert>
      </div>
    </v-form>
    <!-- select membership -->
    <h3 class="mb-7">Supporting Opitonal Membership</h3>
    <v-sheet elevation="4" class="pa-7 hover-sheet mb-8">
      <v-row class="justify-content-center align-center">
        <v-col cols="12" md="3">
          <h3 class="text-h3 text-primary font-weight-light mb-4">$75</h3>
          <v-btn
            color="secondary"
            width="200"
            height="50"
            @click.prevent="$router.push('/billing')"
            >SELECT</v-btn
          >
        </v-col>
        <v-col cols="12" md="9">
          <span class="text-subtitle-1 font-weight-bold">Includes:</span>
          T-shirt, hat, decal, unlimited entry, discount codes, 10% discount on
          topwater merchandise, and automatic entry to the championship. Able to
          collect badges. Angler Account: Displays profle picture, biography,
          about me section, social media links, photo gallery, and fshing stats.
          Gives you options to post videos, and pictures, and write blogs that
          will have the chance to appear on the home page. Also, receive the
          certifed member fsh emblem next to your name.
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet elevation="4" class="pa-7 hover-sheet mb-8">
      <v-row class="justify-content-center align-center">
        <v-col cols="12" md="3">
          <h3 class="text-h3 text-primary font-weight-light mb-4">FREE</h3>
          <v-btn color="secondary" width="200" height="50">SELECT</v-btn>
        </v-col>
        <v-col cols="12" md="9">
          <span class="text-subtitle-1 font-weight-bold">Includes:</span>
          T-shirt, hat, decal, unlimited entry, discount codes, 10% discount on
          topwater merchandise, and automatic entry to the championship. Able to
          collect badges. Angler Account: Displays profle picture, biography,
          about me section, social media links, photo gallery, and fshing stats.
          Gives you options to post videos, and pictures, and write blogs that
          will have the chance to appear on the home page. Also, receive the
          certifed member fsh emblem next to your name.
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet elevation="4" class="pa-7 hover-sheet mb-8">
      <v-row class="justify-content-center align-center">
        <v-col cols="12" md="3">
          <h3 class="text-h3 text-primary font-weight-light mb-4">$75</h3>
          <v-btn color="secondary" width="200" height="50">SELECT</v-btn>
        </v-col>
        <v-col cols="12" md="9">
          <span class="text-subtitle-1 font-weight-bold">Includes:</span>
          T-shirt, hat, decal, unlimited entry, discount codes, 10% discount on
          topwater merchandise, and automatic entry to the championship. Able to
          collect badges. Angler Account: Displays profle picture, biography,
          about me section, social media links, photo gallery, and fshing stats.
          Gives you options to post videos, and pictures, and write blogs that
          will have the chance to appear on the home page. Also, receive the
          certifed member fsh emblem next to your name.
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";

// Components
import HeaderComponent from "../../../components/layout/HeaderComponent.vue";
import QuickLinks from "../../../components/QuickLinks.vue";

export default defineComponent({
  name: "RegisterMembership",

  components: {
    HeaderComponent,
    QuickLinks,
  },

  data: () => ({
    verifyCode: "",
    showVerifyTick: null,
    rules: [
      (value) => {
        if (value) return true;

        return "You must enter verification Code.";
      },
    ],
  }),

  methods: {
    async verityOTP() {
      // errors.value = {};
      try {
        // const result = await api.users.verifyOTP({ verifyCode });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
<style lang="scss" scoped>
.hover-sheet {
  &:hover {
    background: #ecfaff;
  }
}
</style>
