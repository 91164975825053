<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-layout>
    <AnglerSidebar />
    <v-main>
      <!---Angler dashboard container -->
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <h1 class="text-h5">{{ pageTitle }}</h1>
          <v-sheet class="justify-end d-flex">
            <AnglerSearch />
            <AnglerLocation />
          </v-sheet>
        </div>
        <v-divider class="mt-2 mb-7"></v-divider>
        <!-- Choose option to view Standing-->
        <v-sheet max-width="400">
          <v-select
            label="Choose League"
            variant="solo"
            :items="['Topwater', 'Mainstream']"
          ></v-select>
        </v-sheet>
        <!-- View Standing grid-->
        <v-table theme="dark" class="mb-6">
          <thead>
            <tr>
              <th>Place</th>
              <th>Angler</th>
              <th>Total</th>
              <th>Fish 1</th>
              <th>Fish 2</th>
              <th>Fish 3</th>
              <th>Fish 4</th>
              <th>Fish 5</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td class="text-orange">Bob</td>
              <td>73.25</td>
              <td>16.50</td>
              <td>14.25</td>
              <td>14.25</td>
              <td>14.25</td>
              <td>14.00</td>
            </tr>
            <tr>
              <td>2</td>
              <td class="text-orange">Dan VanDusen</td>
              <td>73.25</td>
              <td>16.50</td>
              <td>14.25</td>
              <td>14.25</td>
              <td>14.25</td>
              <td>14.00</td>
            </tr>
            <tr>
              <td>3</td>
              <td class="text-orange">Steven Crooks</td>
              <td>73.25</td>
              <td>16.50</td>
              <td>14.25</td>
              <td>14.25</td>
              <td>14.25</td>
              <td>14.00</td>
            </tr>
          </tbody>
        </v-table>
        <v-divider class="mb-5"></v-divider>
        <v-row class="mb-6">
          <!-- Payouts -->
          <v-col cols="12" sm="4">
            <h2 class="text-h6 mb-3 font-weight-light">
              <v-icon class="mr-3" color="primary">mdi:mdi-gift-outline</v-icon>
              Payouts
            </h2>
            <v-card class="pa-2">
              <p class="mb-1 mt-3 d-flex">
                <strong class="mr-1">1st:</strong>
                <span class="payout-text text-secondary"
                  >Bob
                  <p class="font-weight-light text-black">$1000</p></span
                >
              </p>
              <v-divider class="mb-2 mt-2"></v-divider>
              <p class="mb-1 mt-3 d-flex">
                <strong class="mr-1">2nd:</strong>
                <span class="payout-text text-secondary"
                  >Dan VanDusen
                  <p class="font-weight-light text-black">$800</p></span
                >
              </p>
              <v-divider class="mb-2 mt-2"></v-divider>
              <p class="mb-1 mt-3 d-flex">
                <strong class="mr-1">3rd:</strong>
                <span class="payout-text text-secondary"
                  >Steven Crooks
                  <p class="font-weight-light text-black">$600</p></span
                >
              </p>
            </v-card>
          </v-col>
          <!-- Identifier Code -->
          <v-col cols="12" sm="4">
            <h2 class="text-h6 mb-3 font-weight-light">
              <v-icon class="mr-3" color="primary">mdi:mdi-barcode-scan</v-icon
              >Identifier Code
            </h2>
            <v-card class="pa-2">
              <v-chip-group class="text-black font-weight-bold">
                <v-chip> 6 </v-chip>
                <v-chip> 9 </v-chip>
                <v-chip> 2 </v-chip>
                <v-chip> 5 </v-chip>
              </v-chip-group>
            </v-card>
          </v-col>
          <!-- Location -->
          <v-col cols="12" sm="4">
            <h2 class="text-h6 mb-3 font-weight-light">
              <v-icon class="mr-3" color="primary"
                >mdi:mdi-map-marker-outline</v-icon
              >Location
            </h2>
            <GMapMap
              :center="center"
              :zoom="10"
              map-type-id="terrain"
              style="width: 100%; height: 192px"
              class="mb-7"
            >
              <MarkerClusterer>
                <GMapMarker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :icon="customMarker"
                  @click="center = m.position"
                />
              </MarkerClusterer>
            </GMapMap>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-layout>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
import AnglerSidebar from "../components/anglerDashboard/AnglerSidebar.vue";
import AnglerSearch from "../components/anglerDashboard/AnglerSearch.vue";
import AnglerLocation from "../components/anglerDashboard/AnglerLocation.vue";
import MarkerClusterer from "@googlemaps/markerclusterer";
export default defineComponent({
  name: "ViewStandings",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
    AnglerSidebar,
    AnglerSearch,
    AnglerLocation,
    MarkerClusterer,
  },
  data: () => ({
    pageTitle: "Tournamnet Details- View Standings",
    center: { lat: 40.666893, lng: -111.887993 },
    customMarker: require("../assets/images/map-marker.svg"),
    markers: [
      {
        position: {
          lat: 40.666893,
          lng: -111.887993,
        },
      },
    ],
  }),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
<style scoped>
.payout-text {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
</style>
