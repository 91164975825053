<template>
  <!-- header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks sticky />
  <!-- basic registeration form -->
  <v-container class="mx-auto pa-6">
    <v-form @submit.prevent>
      <h1 class="text-h4 mb-7">Register</h1>
      <p>
        Please enter your email below. An verification code will be sent to your
        email to create your account
      </p>
      <v-divider class="mb-10 mt-2"></v-divider>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            label="First Name"
            type="text"
            hint="Enter your First Name"
            :rules="rules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Last Name"
            type="text"
            hint="Enter your Last Name"
            :rules="rules"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            label="Phone Number"
            type="phone"
            hint="Enter your Phone Number"
            :rules="rules"
            required
          ></v-text-field>
        </v-col>
        <!-- email field -->
        <v-col cols="12" md="6">
          <v-text-field
            label="Email"
            type="email"
            hint="Enter your Email"
            :rules="rules"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" sm="5">
              <v-btn
                type="button"
                :rounded="0"
                color="secondary"
                size="large"
                block
                >Send Code
              </v-btn>
            </v-col>
            <v-col cols="12" sm="7">
              <v-btn
                type="submit"
                :rounded="0"
                color="primary"
                size="large"
                block
                class="text-decoration-none"
                href="/registermembership"
                >NEXT
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
  <!--footer component -->
  <FooterComponent />
</template>

<script>
import { defineComponent } from "vue";

// Components
import HeaderComponent from "../../../components/layout/HeaderComponent.vue";
import QuickLinks from "../../../components/QuickLinks.vue";
import FooterComponent from "../../../components/layout/FooterComponent.vue";

export default defineComponent({
  name: "HomeView",

  components: {
    HeaderComponent,
    QuickLinks,
    FooterComponent,
  },

  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        phone: "",
      },
    };
  },

  methods: {
    async register() {
      // errors.value = {};
      try {
        // const result = await api.users.createUser({ user: form });
        // updateUser(result.data.user);
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
