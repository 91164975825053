<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-layout>
    <AnglerSidebar />
    <v-main>
      <!---Angler dashboard container -->
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <h1 class="text-h5">{{ pageTitle }}</h1>
          <v-sheet class="justify-end d-flex">
            <AnglerSearch />
            <AnglerLocation />
          </v-sheet>
        </div>
        <v-divider class="mt-2 mb-7"></v-divider>
        <v-row>
          <v-col cols="12" sm="5" md="4">
            <v-card class="pa-4">
              <!-- Profile Image -->
              <v-img class="mb-4" :src="anglerImage"></v-img>
              <!-- Angler Description -->
              <v-card class="mb-4" color="dark" variant="tonal">
                <v-card-title>Jhon Doe</v-card-title>
                <v-card-text class="text-small">
                  Each Topwater Media, Inc. (“TM”) Challenge, Tournament, or
                  (referred to herein as “Competition”) is a CPR
                  (Catch-Photo-Release) contest in which each Competitor catches
                  his own fish in places, during times, and by procedures
                  permitted in all pertinent rules and regulations.
                </v-card-text>
                <v-btn variant="tonal" block color="dark"
                  ><v-icon>mdi:mdi-pencil</v-icon>Edit</v-btn
                >
              </v-card>
              <!-- Angler nick name -->
              <div>
                <strong>Nick Name</strong>
                <p class="text-secondary">Jhon</p>
              </div>
              <v-divider class="mt-3 mb-3"></v-divider>
              <!-- Angler Hobbies -->
              <div>
                <strong>Hobbies</strong>
                <ul class="pl-4">
                  <li>Travelling</li>
                  <li>Fishing</li>
                </ul>
              </div>
              <v-divider class="mt-3 mb-3"></v-divider>
              <!-- Angler Favorite Spot -->
              <div>
                <strong>Favorite Fishing Spot</strong>
                <p class="text-secondary">Reeds Lake</p>
              </div>
              <v-divider class="mt-3 mb-3"></v-divider>
              <!-- Angler sponsors -->
              <div>
                <strong>Sponsors</strong>
                <ul class="pl-4">
                  <li>
                    <a href="https://basscrackjigs.com/">basscrackjigs.com</a>
                  </li>
                  <li>
                    <a href="https://www.anchorwizard.com/">anchorwizard.com</a>
                  </li>
                </ul>
              </div>
              <v-divider class="mb-3 mt-3"></v-divider>
              <!-- Angler Socail networks -->
              <div class="social-nav">
                <strong>Socail Networks</strong>
                <br />
                <a class="mr-3" href="https://www.facebook.com/TopwaterSeries/"
                  ><v-icon color="primary" width="50">
                    mdi:mdi-facebook
                  </v-icon></a
                >
                <a
                  class="mr-3"
                  href="https://www.youtube.com/channel/UC0zYeoqEQ2KaOcdEN5rCrNw?view_as=subscriber"
                  ><v-icon color="primary" width="50">
                    mdi:mdi-youtube
                  </v-icon></a
                >
                <a class="mr-3" href="https://www.instagram.com/topwaterseries/"
                  ><v-icon color="primary" width="50">
                    mdi:mdi-instagram
                  </v-icon></a
                >
              </div>
              <v-divider class="mb-3 mt-3"></v-divider>
              <!-- Angler Stats -->
              <div class="d-flex justify-space-between">
                <strong>Total Tournament</strong>
                <div>10</div>
              </div>
              <v-divider class="mb-2 mt-2"></v-divider>
              <div class="d-flex justify-space-between">
                <span>1st Place Finishes</span>
                <div>4</div>
              </div>
              <v-divider class="mb-2 mt-2"></v-divider>
              <div class="d-flex justify-space-between">
                <span>2nd Place Finishes</span>
                <div>3</div>
              </div>
              <v-divider class="mb-2 mt-2"></v-divider>
              <div class="d-flex justify-space-between">
                <span>3rd Place Finishes</span>
                <div>3</div>
              </div>
              <v-divider class="mb-2 mt-2"></v-divider>
              <div class="d-flex justify-space-between">
                <span>Top ten Finishes</span>
                <div>10</div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="7" md="8">
            <!-- Angler Standings -->
            <v-card class="mb-4">
              <v-card-title>Standings</v-card-title>
              <v-card-text>
                <p class="mb-1 mt-3 d-flex">
                  <strong class="mr-1">1st:</strong>
                  <span class="payout-text text-secondary"
                    >Bob
                    <p class="font-weight-light text-black">$1000</p></span
                  >
                </p>
                <v-divider class="mb-2 mt-2"></v-divider>
                <p class="mb-1 mt-3 d-flex">
                  <strong class="mr-1">2nd:</strong>
                  <span class="payout-text text-secondary"
                    >Dan VanDusen
                    <p class="font-weight-light text-black">$800</p></span
                  >
                </p>
                <v-divider class="mb-2 mt-2"></v-divider>
                <p class="mb-1 mt-3 d-flex">
                  <strong class="mr-1">3rd:</strong>
                  <span class="payout-text text-secondary"
                    >Steven Crooks
                    <p class="font-weight-light text-black">$600</p></span
                  >
                </p>
              </v-card-text>
            </v-card>
            <!-- Angler Tournaments -->
            <v-card class="mb-4">
              <v-card-title>Tournaments</v-card-title>
              <v-card-text class="pb-0">
                <strong>Upcoming</strong>
                <v-card class="pa-4 mb-4 mt-2">
                  <div
                    class="d-flex justify-space-between tournament-item"
                    @click="informationDialog = true"
                  >
                    <span>Travesre</span>
                    <div>
                      <v-icon color="green" class="mr-3"
                        >mdi:mdi-cloud-outline</v-icon
                      >
                      <v-icon color="red">mdi:mdi-radio-tower</v-icon>
                    </div>
                  </div>
                  <v-divider class="mt-3 mb-3"></v-divider>
                  <div
                    class="d-flex justify-space-between tournament-item"
                    @click="informationDialog = true"
                  >
                    <span>Tournament one</span>
                    <div>
                      <v-icon color="red" class="mr-3"
                        >mdi:mdi-cloud-outline
                      </v-icon>
                      <v-icon color="green">mdi:mdi-radio-tower</v-icon>
                    </div>
                  </div>
                  <v-divider class="mt-3 mb-3"></v-divider>
                  <div
                    class="d-flex justify-space-between tournament-item"
                    @click="informationDialog = true"
                  >
                    <span>Tournament two</span>
                    <div>
                      <v-icon color="green" class="mr-3"
                        >mdi:mdi-cloud-outline</v-icon
                      >
                      <v-icon color="green">mdi:mdi-radio-tower</v-icon>
                    </div>
                  </div>
                </v-card>
                <!-- Tournament Information dialog -->
                <v-dialog v-model="informationDialog" max-width="800">
                  <v-card>
                    <v-card-title>Traverse: Grand Traverse Bay</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <div class="d-flex flex-column">
                            <strong>Date</strong>
                            <span>06-04-2023</span>
                          </div>
                          <v-divider class="mb-2 mt-2"></v-divider>
                          <div class="d-flex flex-column">
                            <strong>Time</strong>
                            <span>11:30 AM</span>
                          </div>
                          <v-divider class="mb-2 mt-2"></v-divider>
                          <div class="d-flex flex-column">
                            <strong>Luanch Location</strong>
                            <span>Reeds Lake</span>
                          </div>
                          <v-divider class="mb-2 mt-2"></v-divider>
                          <div class="d-flex flex-column">
                            <strong>Requirements</strong>
                            <span>N/A</span>
                          </div>
                          <v-divider class="mb-2 mt-2"></v-divider>
                          <div class="d-flex flex-column">
                            <strong>Important Information</strong>
                            <span>N/A</span>
                          </div>
                          <v-divider class="mb-2 mt-2"></v-divider>
                          <div class="d-flex flex-column">
                            <strong>Current Angler Count</strong>
                            <span>100</span>
                          </div>
                          <v-divider class="mb-2 mt-2"></v-divider>
                          <div class="d-flex flex-column">
                            <strong>Current Payout</strong>
                            <span>$4500</span>
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <div class="d-flex flex-column mb-5">
                            <strong>Current Weather Conditions</strong>
                            <v-img :src="weatherImage"></v-img>
                          </div>
                          <v-btn
                            class="mb-3"
                            color="error"
                            block
                            variant="tonal"
                            @click="cancelDialog = true"
                            >Cancel Registration</v-btn
                          >
                          <v-btn
                            color="dark"
                            variant="tonal"
                            block
                            @click="informationDialog = false"
                            >Close</v-btn
                          >
                        </v-col>
                        <v-dialog v-model="cancelDialog" max-width="400">
                          <v-card class="text-center">
                            <v-card-text>
                              <v-alert
                                color="error"
                                class="mb-5"
                                variant="outlined"
                              >
                                We hate to see you cancel but you suck</v-alert
                              >
                              <v-btn
                                class="mb-3"
                                color="error"
                                variant="tonal"
                                @click="cancelDialog = false"
                                >Cancel</v-btn
                              >
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-card-text>
              <!-- Angler past Tournaments  -->
              <v-card-text class="pt-0">
                <strong>Past</strong>
                <v-card class="pa-4 mb-4 mt-2">
                  <div class="d-flex justify-space-between">
                    <span>Travesre</span>
                  </div>
                  <v-divider class="mt-3 mb-3"></v-divider>
                  <div class="d-flex justify-space-between">
                    <span>Tournament one</span>
                  </div>
                  <v-divider class="mt-3 mb-3"></v-divider>
                  <div class="d-flex justify-space-between">
                    <span>Tournament two</span>
                  </div>
                </v-card>
              </v-card-text>
            </v-card>
            <!-- Angler Images gallery -->
            <v-row class="mb-5">
              <v-col cols="6" sm="4" v-for="img in anglerImages" :key="img">
                <v-img :src="img.imageUpload"></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-layout>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
import AnglerSidebar from "../components/anglerDashboard/AnglerSidebar.vue";
import AnglerSearch from "../components/anglerDashboard/AnglerSearch.vue";
import AnglerLocation from "../components/anglerDashboard/AnglerLocation.vue";
export default defineComponent({
  name: "AnglerProfile",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
    AnglerSidebar,
    AnglerSearch,
    AnglerLocation,
  },
  data: () => ({
    pageTitle: "Welcome Angler",
    anglerImage: "https://i.pravatar.cc/300?img=14",
    informationDialog: false,
    cancelDialog: false,
    weatherImage: require("../assets/images/weatherImage.jpg"),
    anglerImages: [
      {
        imageUpload: require("../assets/images/banner-right-slider/118138434_10222130733004717_2873624900032292766_n.jpg"),
      },
      {
        imageUpload: require("../assets/images/banner-right-slider/172060844_6035113706506174_7530080638951900575_n.jpg"),
      },
      {
        imageUpload: require("../assets/images/banner-right-slider/178940404_10215161011816897_5116340172277446720_n.jpg"),
      },
      {
        imageUpload: require("../assets/images/banner-right-slider/118138434_10222130733004717_2873624900032292766_n.jpg"),
      },
      {
        imageUpload: require("../assets/images/banner-right-slider/172060844_6035113706506174_7530080638951900575_n.jpg"),
      },
      {
        imageUpload: require("../assets/images/banner-right-slider/178940404_10215161011816897_5116340172277446720_n.jpg"),
      },
    ],
  }),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
<style lang="scss" scoped>
.payout-text {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.tournament-item {
  cursor: pointer;
  &:hover {
    background: #f7f7f7;
  }
}
</style>
