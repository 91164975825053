<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <!---Billing Container -->
  <v-responsive class="mb-10 mx-auto pa-4" max-width="800">
    <h1 class="text-h4 mt-4 mb-4">Start A Topwater League In Your Area</h1>
    <v-sheet
      varient="tonal"
      bordered
      class="pa-4 mb-6 mx-auto"
      max-width="800"
      elevation="4"
    >
      <p class="mb-2">
        Here we explain what this means and what is expected of them. Also where
        to go with any questions they might have insert email address here.
      </p>
      <v-card
        title="Difference between Partner and Affiliate Leagues."
        subtitle="Partner and Affiliate Leagues"
        text="Here we explain what this means and what is expected of them. Also where
        to go with any questions they might have insert email address here."
        class="mb-4"
      ></v-card>
      <v-card
        title="Difference between Partner and Affiliate Leagues."
        subtitle="Partner and Affiliate Leagues"
        text="Here we explain what this means and what is expected of them. Also where
        to go with any questions they might have insert email address here."
        class="mb-4"
      ></v-card>
    </v-sheet>
    <p class="mb-4">
      If you feel that this is something you are interested in apply here!
    </p>
    <v-dialog v-model="dialog" persistent width="1024">
      <template v-slot:activator="{ props }">
        <v-btn color="secondary" v-bind="props" width="200"> Apply </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Start A Topwater League</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4" class="pb-0 pt-0">
                <v-text-field
                  label="First name*"
                  required
                  hint="Enter your First Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pb-0 pt-0">
                <v-text-field
                  label="Last Name"
                  hint="Enter your Last Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pb-0 pt-0">
                <v-text-field
                  label="Phone*"
                  hint="Enter Your Phone Number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12"> Location Of Seires </v-col>
              <v-col cols="12" sm="6" md="4" class="pb-0 pt-0">
                <v-text-field
                  label="City*"
                  hint="Enter Your City Name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pb-0 pt-0">
                <v-text-field
                  label="State*"
                  hint="Enter Your State Name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pb-0 pt-0">
                <v-text-field
                  label="Zip Code*"
                  hint="Enter Your Zip Code"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-responsive>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
export default defineComponent({
  name: "StartLeague",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
  },

  data: () => ({
    dialog: false,
  }),

  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
