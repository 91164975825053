<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-container>
    <h1 class="text-h4 mb-3">AOY Standings</h1>
    <v-divider class="mb-5"></v-divider>
    <v-row>
      <v-col cols="12" sm="6" class="pb-0">
        <v-select
          chips
          label="MICHIGAN"
          color="secondary"
          hint="MICHIGAN"
          persistent-hint
          variant="solo"
          :items="[
            'California',
            'Colorado',
            'Florida',
            'Georgia',
            'Texas',
            'Wyoming',
          ]"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" class="pb-0">
        <v-select
          chips
          label="INDIANA"
          hint="INDIANA"
          persistent-hint
          variant="solo"
          :items="[
            'California',
            'Colorado',
            'Florida',
            'Georgia',
            'Texas',
            'Wyoming',
          ]"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          chips
          label="MISSOURI"
          hint="MISSOURI"
          persistent-hint
          variant="solo"
          :items="[
            'California',
            'Colorado',
            'Florida',
            'Georgia',
            'Texas',
            'Wyoming',
          ]"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          chips
          label="ONTARIO, CANADA"
          hint="ONTARIO, CANADA"
          persistent-hint
          variant="solo"
          :items="[
            'California',
            'Colorado',
            'Florida',
            'Georgia',
            'Texas',
            'Wyoming',
          ]"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          chips
          label="SPECIAL EVENTS"
          hint="SPECIAL EVENTS"
          persistent-hint
          variant="solo"
          :items="[
            'California',
            'Colorado',
            'Florida',
            'Georgia',
            'Texas',
            'Wyoming',
          ]"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
export default defineComponent({
  name: "HomeView",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
  },
  data: () => ({}),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
