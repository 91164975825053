<template>
  <!-- forgot password form -->
  <v-form @submit.prevent>
    <v-responsive class="mx-auto pa-6" max-width="400">
      <v-img :width="400" aspect-ratio="16/9" cover :src="logoImg"></v-img>
      <v-text-field
        label="New Password"
        type="password"
        hint="Enter your new password"
        :rules="rules"
        required
      ></v-text-field>
      <v-text-field
        label="Confirm Password"
        type="password"
        hint="Confirm your new password"
        :rules="rules"
        required
      ></v-text-field>
      <v-btn
        type="submit"
        :rounded="0"
        color="orange-accent-4"
        size="large"
        block
        >Change Password
      </v-btn>
      <div class="text-center not-member mt-3">
        <a href="/login">Back To Login</a>
      </div>
    </v-responsive>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    firstName: "",
    rules: [
      (value) => {
        if (value) return true;

        return "this is required field.";
      },
    ],
    logoImg: require("../../../assets/TOPLOGO.png"),
  }),
};
</script>
<style scoped>
.forgot-password,
.not-member a {
  text-align: center;
  text-decoration: none;
  color: #077198;
  font-size: 12px;
}
.forgot-password:hover,
.not-member a:hover {
  color: #000;
  text-decoration: underline;
}
.not-member {
  font-size: 14px;
  color: #000;
}
.not-member a {
  font-size: 14px;
  color: #077198;
}
</style>
