<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-layout>
    <AnglerSidebar />
    <v-main>
      <!---Angler dashboard container -->
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <h1 class="text-h5">{{ pageTitle }}</h1>
          <v-sheet class="justify-end d-flex">
            <AnglerSearch />
            <AnglerLocation />
          </v-sheet>
        </div>
        <v-divider class="mt-2 mb-7"></v-divider>
        <v-row>
          <v-col cols="12" sm="3" md="4">
            <FilePreview pImage="previewImage" />
          </v-col>
          <v-col cols="12" sm="9" md="8">
            <v-sheet max-width="300">
              <v-form validate-on="submit lazy" @submit.prevent="submit">
                <v-text-field label="Length"></v-text-field>

                <v-btn
                  size="large"
                  type="submit"
                  block
                  class="mt-2"
                  color="secondary"
                  >Submit</v-btn
                >
              </v-form>
            </v-sheet>
          </v-col>
        </v-row>
        <v-divider class="mt-6 mb-6"></v-divider>
        <v-row class="mb-10">
          <v-col cols="6" sm="4" md="3" v-for="fish in fishImages" :key="fish">
            <v-card bordered class="pa-7 position-relative">
              <v-img :src="fish.fishImage" class="mb-4"></v-img>
              <p>
                Length: <strong class="text-h5 font-weight-bold">22.25"</strong>
              </p>
              <v-card color="dark" variant="tonal" class="delete-image pa-2"
                ><v-icon color="primary">mdi:mdi-delete-outline</v-icon></v-card
              >
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-layout>

  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
import AnglerSidebar from "../components/anglerDashboard/AnglerSidebar.vue";
import AnglerSearch from "../components/anglerDashboard/AnglerSearch.vue";
import AnglerLocation from "../components/anglerDashboard/AnglerLocation.vue";
import FilePreview from "../components/FilePreview.vue";
export default defineComponent({
  name: "LiveWell",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
    AnglerSidebar,
    AnglerSearch,
    AnglerLocation,
    FilePreview,
  },
  data: () => ({
    pageTitle: "Livewell",
    fishImages: [
      { fishImage: require("../assets/images/sponsors/MAINSTREAM.png") },
      { fishImage: require("../assets/images/sponsors/MAINSTREAM.png") },
      { fishImage: require("../assets/images/sponsors/MAINSTREAM.png") },
      { fishImage: require("../assets/images/sponsors/MAINSTREAM.png") },
      { fishImage: require("../assets/images/sponsors/MAINSTREAM.png") },
      { fishImage: require("../assets/images/sponsors/MAINSTREAM.png") },
    ],
  }),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
<style lang="scss" scoped>
.delete-image {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    background: rgba(0, 0, 0, 1);
    transition: all 0.4s;
  }
}
</style>
