<template>
  <div class="topwaterAds">
    <v-container>
      <v-row class="justify-center align-center">
        <v-col>
          <v-img :src="adsImageLeft" alt="ads left image" cover />
        </v-col>
        <v-col cols="7">
          <v-img :src="adsImageCenter" alt="ads center image" cover />
        </v-col>
        <v-col>
          <v-img :src="adsImageRight" alt="ads-right image" cover />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "TopwaterAds",

  data: () => ({
    adsImageLeft: require("../assets/images/OLDTOWN1.png"),
    adsImageCenter: require("../assets/images/OLDTOWN3.png"),
    adsImageRight: require("../assets/images/OLDTOWN2.png"),
  }),
};
</script>
<style lang="scss" scoped>
.topwaterAds {
  background: #edf6f9;
  margin: 0 0 50px;
}
</style>
