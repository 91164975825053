<template>
  <v-parallax :src="bannerImage" class="home-banner">
    <div class="d-flex flex-column fill-height justify-center">
      <v-row>
        <v-col cols="12" md="6" class="d-flex align-center">
          <div class="imageHolder">
            <h1 class="imageH1">
              TOPWATER <span class="text-primary">SERIES</span>
            </h1>
            <p class="mb-8">
              By creating an account you will be able to shop faster, be up to
              date on an order's status, and keep track of the orders you have
              previously made.
              <v-chip
                size="small"
                color="secondary"
                prepend-icon="mdi:mdi-account-circle"
                rounded="0"
                ><a class="text-uppercase" href="/register">Sign up</a>
              </v-chip>
            </p>
            <v-row>
              <v-col sm="6">
                <v-btn
                  type="button"
                  :rounded="0"
                  color="primary"
                  size="large"
                  block
                  href="FindLeague"
                  >Find a League Near You
                </v-btn>
              </v-col>
              <v-col sm="6">
                <v-btn
                  type="button"
                  :rounded="0"
                  color="secondary"
                  size="large"
                  href="StartLeague"
                  block
                  >Create a New League
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="d-flex align-center pl-10 pr-10">
          <v-carousel
            hide-delimiters
            cycle
            interval="3000"
            :show-arrows="false"
            class="rounded-te-xl rounded-bs-xl"
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
              cover
            ></v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </div>
  </v-parallax>
</template>
<script>
export default {
  name: "HomeBanner",

  data: () => ({
    bannerImage: require("../assets/images/topomap1.png"),
    items: [
      {
        src: require("../assets/images/banner-right-slider/118138434_10222130733004717_2873624900032292766_n.jpg"),
      },
      {
        src: require("../assets/images/banner-right-slider/172060844_6035113706506174_7530080638951900575_n.jpg"),
      },
      {
        src: require("../assets/images/banner-right-slider/178940404_10215161011816897_5116340172277446720_n.jpg"),
      },
      {
        src: require("../assets/images/banner-right-slider/180283866_10215161020177106_1231587501461293653_n.jpg"),
      },
      {
        src: require("../assets/images/banner-right-slider/180430216_10215161017177031_8076434481606311442_n.jpg"),
      },
      {
        src: require("../assets/images/banner-right-slider/180599583_10215161021897149_5134812907257581644_n.jpg"),
      },
      {
        src: require("../assets/images/banner-right-slider/185284199_3944617215626190_2557989666833714171_n.jpg"),
      },
      {
        src: require("../assets/images/banner-right-slider/185329789_3944618312292747_7073434628442519865_n.jpg"),
      },
      {
        src: require("../assets/images/banner-right-slider/185718612_3944618248959420_2493296138029658252_n.jpg"),
      },
      {
        src: require("../assets/images/banner-right-slider/186585860_6155385551145655_8944174360639761989_n.jpg"),
      },
    ],
  }),
};
</script>
<style lang="scss" scoped>
.imageHolder {
  display: inline-block;
  max-width: 610px;
  margin: 0 30px;
  p {
    a {
      text-decoration: underline;
      color: #000;
      font-weight: 600;
      &:hover {
        text-decoration: none;
        color: #077198;
      }
    }
  }
}
.imageH1 {
  font-size: 70px;
  line-height: 70px;
  font-weight: 300;
  text-shadow: 0 0 1px rgb(0 0 0 / 70%);
  margin-bottom: 20px;
  @media only screen and (max-width: 950px) {
    font-size: 50px;
    line-height: 50px;
  }
}
</style>
