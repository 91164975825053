import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginViewVue from "../views/auth/login/LoginView.vue";
import ForgotPassword from "../views/auth/forgotPassword/ForgotPassword.vue";
import RegisterForm from "../views/auth/register/RegisterForm.vue";
import RegisterMembership from "../views/auth/register/RegisterMembership.vue";
import BillingPage from "../views/BillingView.vue";
import MemberRegistration from "../views/MemberRegistration.vue";
import StaffDirectory from "../views/StaffDirectory.vue";
import SpecialEvents from "../views/SpecialEvents.vue";
import AnglerDashboard from "../views/AnglerDashboard.vue";
import ProfileSettings from "../views/ProfileSettings.vue";
import LeaguesIndex from "../views/LeaguesIndex.vue";
import AboutView from "../views/AboutView.vue";
import ContactUs from "../views/ContactUs.vue";
import AoyStandings from "../views/AoyStandings.vue";
import AnglerListing from "../views/AnglersList.vue";
import FindLeague from "../views/FindLeague.vue";
import StartLeague from "../views/StartLeague.vue";
import TournamentResults from "../views/TournamentResults.vue";
import RefundPolicy from "../views/RefundPolicy.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TopwaterRules from "../views/TopwaterRules.vue";
import TopwaterHelp from "../views/TopwaterHelp.vue";
import TopwaterSponsors from "../views/TopwaterSponsors.vue";
import TermsConditions from "../views/TermsConditions.vue";
import TopwaterIdentifiers from "../views/TopwaterIdentifiers.vue";
import LiveWell from "../views/LiveWell.vue";
import ViewStandings from "../views/ViewStandings.vue";
import AnglerProfile from "../views/AnglerProfile.vue";
import AnglerInbox from "../views/AnglerInbox.vue";
import TopBox from "../views/TopBox.vue";
import AddWithdrawl from "../views/AddWithdrawl.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginViewVue,
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/register",
    name: "registerForm",
    component: RegisterForm,
  },
  {
    path: "/registermembership",
    name: "RegisterMembership",
    component: RegisterMembership,
  },
  {
    path: "/billing",
    name: "BillingPage",
    component: BillingPage,
  },
  {
    path: "/registermember",
    name: "MemberRegistration",
    component: MemberRegistration,
  },
  {
    path: "/staffdriectory",
    name: "StaffDirectory",
    component: StaffDirectory,
  },
  {
    path: "/specialEvents",
    name: "SpecialEvents",
    component: SpecialEvents,
  },
  {
    path: "/anglerdashboard",
    name: "AnglerDashboard",
    component: AnglerDashboard,
  },
  {
    path: "/ProfileSettings",
    name: "ProfileSettings",
    component: ProfileSettings,
  },
  {
    path: "/LeaguesIndex",
    name: "LeaguesIndex",
    component: LeaguesIndex,
  },
  {
    path: "/AboutUS",
    name: "AboutView",
    component: AboutView,
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/AoyStandings",
    name: "AoyStandings",
    component: AoyStandings,
  },
  {
    path: "/AnglerListing",
    name: "AnglerListing",
    component: AnglerListing,
  },
  {
    path: "/FindLeague",
    name: "FindLeague",
    component: FindLeague,
  },
  {
    path: "/StartLeague",
    name: "StartLeague",
    component: StartLeague,
  },
  {
    path: "/TournamentResults",
    name: "TournamentResults",
    component: TournamentResults,
  },
  {
    path: "/RefundPolicy",
    name: "RefundPolicy",
    component: RefundPolicy,
  },
  {
    path: "/PrivacyPolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/TopwaterRules",
    name: "TopwaterRules",
    component: TopwaterRules,
  },
  {
    path: "/TopwaterHelp",
    name: "TopwaterHelp",
    component: TopwaterHelp,
  },
  {
    path: "/TopwaterSponsors",
    name: "TopwaterSponsors",
    component: TopwaterSponsors,
  },
  {
    path: "/TermsConditions",
    name: "TermsConditions",
    component: TermsConditions,
  },
  {
    path: "/TopwaterIdentifiers",
    name: "TopwaterIdentifiers",
    component: TopwaterIdentifiers,
  },
  {
    path: "/LiveWell",
    name: "LiveWell",
    component: LiveWell,
  },
  {
    path: "/ViewStandings",
    name: "ViewStandings",
    component: ViewStandings,
  },
  {
    path: "/AnglerProfile",
    name: "AnglerProfile",
    component: AnglerProfile,
  },
  {
    path: "/AnglerInbox",
    name: "AnglerInbox",
    component: AnglerInbox,
  },
  {
    path: "/TopBox",
    name: "TopBox",
    component: TopBox,
  },
  {
    path: "/AddWithdrawl",
    name: "AddWithdrawl",
    component: AddWithdrawl,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
