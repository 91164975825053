<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <!---Staff Directory Container -->
  <v-container>
    <v-row>
      <v-col cols="12" sm="3" md="4" lg="5" class="d-flex align-center">
        <h1 class="text-h4">Anglers</h1>
      </v-col>
      <v-col cols="12" sm="9" md="8" lg="7">
        <v-row>
          <v-col cols="12" md="2" class="d-flex align-center">
            Member
            <v-icon
              icon="mdi:mdi-bug-check"
              color="primary"
              size="large"
            ></v-icon>
          </v-col>
          <v-col>
            <v-text-field
              label="Search for an angler"
              append-inner-icon="mdi:mdi-magnify"
              variant="solo"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="mb-7"></v-divider>
    <v-row>
      <v-col
        v-for="n in AnglersData"
        :key="n"
        class="d-flex child-flex position-relative hover-card-angler"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-img
          :src="n.src"
          :lazy-src="n.src"
          aspect-ratio="1"
          cover
          class="bg-grey-lighten-2"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="orange-darken-2"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <strong :class="n.member ? 'angler-name member-name' : 'angler-name'"
          >{{ n.title }}
          <span v-if="n.member" class="member-icon">
            <v-icon size="large" color="primary">mdi:mdi-bug-check</v-icon>
          </span>
        </strong>
      </v-col>
    </v-row>
  </v-container>

  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
export default defineComponent({
  name: "HomeView",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
  },
  data: () => ({
    tab: null,
    AnglersData: [
      {
        title: "Jhon Doe",
        src: "https://i.pravatar.cc/300?img=70",
        member: true,
      },
      {
        title: "Tyler Blue",
        src: "https://i.pravatar.cc/300?img=14",
      },
      {
        title: "Anderson Green",
        src: "https://i.pravatar.cc/300?img=8",
      },
      {
        title: "Tommy Green",
        src: "https://i.pravatar.cc/300?img=10",
        member: true,
      },
      {
        title: "Emmit Brown",
        src: "https://i.pravatar.cc/300?img=7",
      },
      {
        title: "Steve john",
        src: "https://i.pravatar.cc/300?img=12",
      },
      {
        title: "Tyler Blue",
        src: "https://i.pravatar.cc/300?img=14",
        member: true,
      },
      {
        title: "Anderson Green",
        src: "https://i.pravatar.cc/300?img=8",
      },
      {
        title: "Tommy Green",
        src: "https://i.pravatar.cc/300?img=10",
      },
      {
        title: "Emmit Brown",
        src: "https://i.pravatar.cc/300?img=7",
      },
      {
        title: "Steve john",
        src: "https://i.pravatar.cc/300?img=12",
      },
    ],
  }),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
<style lang="scss" scoped>
.btn-member {
  font-size: 28px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s linear;
  &:hover {
    font-size: 36px;
    transition: all 0.3s linear;
  }
}
.hover-card-angler {
  .angler-name {
    position: absolute;
    bottom: 24px;
    left: 24px;
    color: #fff;
    background: rgba(0, 0, 0, 0.8);
    padding: 5px 30px 5px;
    border-radius: 100px 0 100px 0;
    font-weight: normal;
    text-transform: capitalize;
    overflow: hidden;
    text-align: center;
    transition: all 0.2s linear;
    font-size: 16px;
    border: 1px solid #077198;
  }
  &:hover {
    cursor: pointer;
    .angler-name {
      transition: all 0.2s linear;
      border-radius: 0 100px 0 100px;
      border: 1px solid #000;
      background: rgba(7, 113, 152, 0.9);
    }
  }
}
</style>
>
