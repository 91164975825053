<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-layout>
    <AnglerSidebar />
    <v-main>
      <!---Angler dashboard container -->
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <h1 class="text-h5">{{ pageTitle }}</h1>
          <v-sheet class="justify-end d-flex">
            <AnglerSearch />
            <AnglerLocation />
          </v-sheet>
        </div>
        <v-divider class="mt-2 mb-7"></v-divider>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-hover>
              <template v-slot:default="{ isHovering, props }">
                <v-card
                  v-bind="props"
                  :color="isHovering ? 'primary' : dark"
                  class="d-flex flex-column text-center align-center pa-7"
                >
                  <v-icon size="large" class="text-h2"
                    >mdi:mdi-download-outline</v-icon
                  >
                  <span>Topwater Series Universal Identifier</span>
                </v-card>
              </template>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-hover>
              <template v-slot:default="{ isHovering, props }">
                <v-card
                  v-bind="props"
                  :color="isHovering ? 'primary' : dark"
                  class="d-flex flex-column text-center align-center pa-7"
                >
                  <v-icon size="large" class="text-h2"
                    >mdi:mdi-download-outline</v-icon
                  >
                  <span>KBF</span>
                </v-card>
              </template>
            </v-hover>
          </v-col>
          <v-col cols="6" sm="3"></v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-layout>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
import AnglerSidebar from "../components/anglerDashboard/AnglerSidebar.vue";
import AnglerSearch from "../components/anglerDashboard/AnglerSearch.vue";
import AnglerLocation from "../components/anglerDashboard/AnglerLocation.vue";
export default defineComponent({
  name: "LiveWell",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
    AnglerSidebar,
    AnglerSearch,
    AnglerLocation,
  },
  data: () => ({
    pageTitle: "Identifiers / Codes",
  }),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
