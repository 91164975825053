<template>
  <Splide
    :options="{
      rewind: true,
      perPage: 1,
      perMove: 1,
      autoplay: true,
      pagination: false,
      wheel: false,
      drag: free,
      snap: true,
      keyboard: true,
      slideFocus: true,
      arrows: false,
    }"
    aria-label="My Favorite Images"
  >
    <SplideSlide v-for="n in items" :key="n" data-splide-interval="3000">
      <v-img :src="n.src" cover></v-img>
    </SplideSlide>
  </Splide>
</template>
<script>
export default {
  name: "FeaturedEvents",

  data: () => ({
    items: [
      {
        src: require("../../assets/images/CHAMP.png"),
      },
      {
        src: require("../../assets/images/CHAMP.png"),
      },
    ],
  }),
};
</script>
