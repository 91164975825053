<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-responsive max-width="600" class="mx-auto pa-4 mb-7">
    <h1 class="text-h4 mb-3">Tournament Results</h1>
    <v-divider class="mb-5"></v-divider>
    <v-row>
      <v-col
        v-for="(item, index) in topWaterSeries"
        :key="item"
        cols="12"
        class="pb-0"
      >
        <v-card
          @click.stop="showTournaments(item)"
          :color="index % 2 === 1 ? 'secondary' : ''"
          variant="tonal"
        >
          <v-card-actions>
            <v-btn variant="text">
              {{ item.seriesName }}
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
              :icon="item.show ? 'mdi:mdi-chevron-up' : 'mdi:mdi-chevron-down'"
            ></v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="item.show">
              <v-list class="overflow-auto" max-height="250">
                <v-list-item v-for="t in item.tournamentsList" :key="t">
                  <v-divider class="mb-2"></v-divider>
                  <v-dialog v-model="t.dialog" max-width="1024">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" variant="text">
                        {{ t.tournamentName }}
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title
                        >League Series:
                        <span class="text-primary text-uppercase">{{
                          t.tournamentName
                        }}</span>
                        Results</v-card-title
                      >
                      <v-card-item v-for="r in t.tournamentResults" :key="r">
                        <v-divider class="mb-2"></v-divider>
                        <v-dialog v-model="r.dialog" max-width="600">
                          <template v-slot:activator="{ props }">
                            <v-btn
                              v-bind="props"
                              variant="text"
                              density="compact"
                              class="pa-0"
                            >
                              {{ r.resultName }}
                              <v-icon color="primary">mdi:mdi-more</v-icon>
                            </v-btn>
                            <span
                              class="text-small font-weight-light d-block"
                              >{{ r.resultDate }}</span
                            >
                          </template>
                          <v-card class="pa-3 pt-0">
                            <v-card-title class="mb-4 pl-0"
                              >League Series:
                              <span class="text-primary text-uppercase">{{
                                t.tournamentName
                              }}</span>
                              Results-
                              <span class="text-primary text-uppercase">{{
                                r.resultName
                              }}</span></v-card-title
                            >
                            <v-row
                              v-for="(w, wIndex) in r.tournamentWinner"
                              :key="w"
                            >
                              <v-col
                                :class="
                                  wIndex === 0
                                    ? 'bg-success font-weight-bold'
                                    : 'bg-white'
                                "
                                cols="4"
                                >{{ w.position }}</v-col
                              >
                              <v-col
                                :class="
                                  wIndex === 0 ? 'bg-success' : 'bg-white'
                                "
                                cols="4"
                                >{{ w.winnerName }}</v-col
                              >
                              <v-col
                                :class="
                                  wIndex === 0 ? 'bg-success' : 'bg-white'
                                "
                                cols="4"
                                >{{ w.winnerPayout }}</v-col
                              >
                            </v-row>
                            <v-btn
                              color="black"
                              class="pa-0 mt-4"
                              variant="text"
                              density="compact"
                              width="80"
                              @click.stop="r.dialog = false"
                              ><v-icon>mdi:mdi-arrow-left</v-icon>Back</v-btn
                            >
                          </v-card>
                        </v-dialog>
                      </v-card-item>
                      <v-card-actions>
                        <v-btn color="primary" @click.stop="t.dialog = false"
                          >Close</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-list-item>
              </v-list>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </v-responsive>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
export default defineComponent({
  name: "TournamentResults",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
  },
  data: () => ({
    topWaterSeries: [
      {
        seriesName: "MICHIGAN",
        tournamentsList: [
          {
            tournamentName: "Alpena",
            tournamentResults: [
              {
                resultName: "Lake Winyah",
                resultDate: "5 May 2023",
                tournamentWinner: [
                  {
                    position: "1ST",
                    winnerName: "JHON",
                    winnerPayout: "$1000",
                  },
                  {
                    position: "2ND",
                    winnerName: "Kyle",
                    winnerPayout: "$1000",
                  },
                  {
                    position: "3RD",
                    winnerName: "Mike",
                    winnerPayout: "$1000",
                  },
                ],
              },
              {
                resultName: "Lake One",
                resultDate: "5 May 2023",
              },
              {
                resultName: "Lake Two",
                resultDate: "5 May 2023",
              },
              {
                resultName: "Lake Winyah",
                resultDate: "5 May 2023",
              },
            ],
          },
          { tournamentName: "Genesee" },
          { tournamentName: "Huron" },
          { tournamentName: "Kalamazoo" },
          { tournamentName: "Lakeshore" },
          { tournamentName: "Roots" },
          { tournamentName: "Southwest" },
          { tournamentName: "Southeast" },
          { tournamentName: "Traverse" },
          { tournamentName: "Tri Cities" },
        ],
      },
      {
        seriesName: "INDIANA",
        tournamentsList: [
          { tournamentName: "101 Lakes" },
          { tournamentName: "Genesee" },
        ],
      },
      {
        seriesName: "MISSOURI",
        tournamentsList: [
          { tournamentName: "101 Lakes" },
          { tournamentName: "Genesee" },
        ],
      },
      {
        seriesName: "ONTARIO, CANADA",
        tournamentsList: [
          { tournamentName: "101 Lakes" },
          { tournamentName: "Genesee" },
        ],
      },
      {
        seriesName: "SPECIAL EVENTS",
        tournamentsList: [
          { tournamentName: "101 Lakes" },
          { tournamentName: "Genesee" },
        ],
      },
    ],
  }),
  methods: {
    showTournaments(i) {
      i.show = !i.show;
    },
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
