<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-container>
    <h1 class="text-h4 mb-3">Topwater Help</h1>
    <v-divider class="mb-5"></v-divider>
    <p class="mb-2">What can we do to help?</p>
    <p class="mb-7">
      Select from the below suggested help responses, otherwise contact us
      <a href="./ContactUs">here</a>.
    </p>
    <v-sheet max-width="400">
      <v-select
        label="FAQ"
        variant="solo"
        :items="[
          'Password Reset',
          'Waiver Wont Process',
          'Missing Submission',
          'When do Payouts Send Out',
          'How do I log into the Tracker App',
          'Can Youth Participate',
          'Withdraws & Refunds',
        ]"
      ></v-select>
    </v-sheet>
  </v-container>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
export default defineComponent({
  name: "TopwaterHelp",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
  },
  data: () => ({}),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
