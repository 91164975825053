<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-container>
    <h1 class="text-h4 mb-3">Topwater Series Rules</h1>
    <v-divider class="mb-5"></v-divider>
    <h1 class="text-h6 mb-3">Topwater Series Competition Rules</h1>
    <p class="mb-4">
      The terms and conditions presented in this document apply between Topwater
      Media, Inc. (“TM”) and those persons registering for and participating in
      TM competitive Events (“Competitor”). “Events” include both “Tournaments”
      (meet-up competition) and “Challenges” (virtual, online-only competition
      where participants do not congregate). The pronouns “he,” “him,”
      “himself,” and “his” herein are used as generic, gender-neutral terms and
      do not restrict or confirm access on the basis of sex.
    </p>
    <p class="mb-4">
      Each Topwater Media, Inc. (“TM”) Challenge, Tournament, or (referred to
      herein as “Competition”) is a CPR (Catch-Photo-Release) contest in which
      each Competitor catches his own fish in places, during times, and by
      procedures permitted in all pertinent rules and regulations, digitally
      photographs them according to the standards described herein, and submits
      the lengths and photographs for verification. These Competitions are
      conducted according to TM Competition Rules found in two documents:
    </p>
    <v-alert color="secondary" class="mb-4" variant="tonal">
      <p class="d-flex">
        <strong class="mr-1">1.</strong
        ><span
          >TM Competition Rules Standard (this document) covers about 90% of the
          terms, conditions and standards common to all TM Events.</span
        >
      </p>
      <p class="d-flex">
        <strong class="mr-1">2.</strong>
        <span
          >Event-specific rules located on the TM Event Page, which includes
          details such as dates, locations, times, eligible water boundaries,
          restrictions, and exceptions to the TM Rules</span
        >
        Standard.
      </p>
    </v-alert>
    <p class="mb-4">
      Together, these two documents comprise the TM Competition Rules. As a
      condition of participation in any TM Competition, the participating angler
      (“Competitor”) agrees to read, understand, and comply with all TM
      Competition Rules. Failure to comply will result in a penalty, which may
      range from points deductions to submission denial or disqualification.
    </p>
    <p class="mb-4">
      For each Event, the TM Event Coordinator and/or Tournament or Series
      Director (“TD”) will be identified on the TM Event Page. The Tournament or
      Series Director has responsibility for interpretation and enforcement of
      the TM Competition Rules and may amplify or clarify them to make them more
      relevant or complete. Tournament and Series Director decisions may be
      disputed, as described in Section 17.A. TM management will review the
      Tournament or Series Director’s decisions and revise the TM Competition
      Rules appropriately. Most recent TM Competition Rules Standard revisions
      will be summarized in a Revisions Log (above) and highlighted in red in
      the body of the rules standards. Announcement of the rule changes will
      also be posted fifteen (15) days prior to implementation in the Topwater
      Series Facebook Page, Topwater Group Page, Topwater Tournament Director
      Facebook Group Page.
    </p>
    <p class="mb-1 d-flex">
      <strong class="mr-1 text-primary">1.</strong>
      <span class="font-weight-bold">Participation and Eligibility</span>
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">A.</strong>
      <span
        >Professional Fishing Guides may register and compete in Events
        providing they do not guide-for-pay on Eligible Water within 30 days
        prior to the Event’s start-of-competition date.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">B.</strong
      ><span
        >Event Registration — All TM Competitions are conducted using a
        Tournament Management System (“TMS”). Each angler who wishes to
        participate in such an Event must create a TMS user account before the
        first day of competition and must sign up for the Event and pay the
        entry fee on the TMS or through the TM online store, as specified on the
        Event Page.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">C.</strong
      ><span
        >Entry Fees — Entry Fee amounts, administrative surcharges, registration
        deadlines, late fee exceptions, and refund or transfer restrictions are
        specified on the Event Page for each Competition.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">D.</strong
      ><span
        >No Satisfaction Guarantee — Competitor understands and agrees that
        payment for the Event does not secure a guarantee or promise of
        satisfaction.</span
      >
    </p>
    <v-alert color="secondary" variant="tonal" class="mb-2">
      Competitor’s failure or inability, for any reason except as otherwise
      provided herein, to participate in any or all of the activities of the
      Event does not relieve or suspend the Competitor’s obligation to make all
      payments required by TM Rules on a timely basis, nor entitle the Customer
      to a refund or credit, except as provided below.
    </v-alert>
    <p class="mb-2 d-flex">
      <strong class="mr-1">E.</strong
      ><span
        >Payment — The Competitor agrees that once an Event Registration is
        submitted, the terms and conditions of the TM Competition Rules
        constitute an Agreement that is a legally binding and enforceable
        obligation of the Competitor.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">F.</strong
      ><span
        >Competitor’s Right to Cancel — Any competitor wishing to withdraw from
        an event may due so by following instructions on the Refunds Page</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">G.</strong
      ><span
        >Pre-Tournament Check-in — Each Tournament Competitor is responsible for
        reviewing the Schedule of Events on the Event Page for pre-event
        check-in times and location. Each Competitor must check in with official
        TM Competition staff at the time and place designated in the Schedules
        of Event for verification of eligibility, signing of waivers if
        required, and rules acknowledgement. Late check-in and registration
        process (if permitted), event-specific instructions, and Event schedule
        are specified on the Event Page.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">H.</strong
      ><span
        >Captains Meeting Participation —Tournament Competitors are required to
        attend an on-site Captains Meeting or view a recorded “Virtual” Captains
        Meeting Webcast, as stipulated on the Event Page. Failure to either view
        a Virtual Captains Meeting or attend an on-site Captains Meeting results
        in a Competitor’s disqualification from the Event.</span
      >
    </p>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 d-flex">
      <strong class="mr-1 text-primary">2.</strong>
      <span class="font-weight-bold">Fish Eligibility</span>
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">A.</strong>
      <span
        >Species—For “Bass” TM Competitions, That designation includes the three
        most common species (Largemouth Bass – Micropterus salmoides, Smallmouth
        Bass – Micropterus dolomineu, and Spotted or Kentucky Bass – Micropterus
        punctulatus) as well as other less well-known species or
        varieties:</span
      >
    </p>
    <v-card color="dark" variant="outlined" class="pa-4 mb-2">
      <p class="mb-2 d-flex">
        <strong class="mr-1">i.</strong>
        <span>Shoal Bass – Micropterus cataactae</span>
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">ii.</strong>
        <span>Redeye Bass – Micropterus coosae</span>
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">iii.</strong>
        <span>Suwannee Bass – Micropterus notius</span>
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">iv.</strong>
        <span>Guadalupe Bass- Micropterus treculi</span>
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">v.</strong>
        <span>Bartram’s Bass – Micropterus bartrami</span>
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">vi.</strong>
        <span>Alabama Spotted Bass – Micropterus punctulatus henshalli</span>
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">vii.</strong>
        <span
          >Mean Mouth Bass – hybrid offspring of M. dolomieau and M.
          punctulatus</span
        >
      </p>
    </v-card>
    <p class="mb-2 d-flex">
      <strong class="mr-1">B.</strong>
      <span
        >Length—The minimum length of bass scored in competition is 12 inches.
        Photos of bass shorter than the minimum will be denied.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">C.</strong>
      <span
        >Condition—Fish must be hooked in the mouth or jaw. It may not appear to
        have snagged, snatched, or hooked only in the body or a fin. If hooked
        in the mouth, a second treble hook that snags a gill plate, fin, or body
        is considered incidental and does not eliminate that fish.</span
      >
    </p>
    <v-alert class="mb-2" variant="tonal" color="secondary">
      Fish must appear to be alive. If fish appears to be dead, mutilated,
      frozen, mangled, or otherwise damage, or it appears to have been mashed,
      mauled, or otherwise altered, the photo will be denied.
    </v-alert>
    <p class="mb-2 d-flex">
      <strong class="mr-1">D.</strong>
      <span
        >Caudal (Tail) Fin—For bass, the tip of at least one lobe of the caudal
        (tail) fin must be in contact with the measuring board. If the bass’
        entire caudal (tail) fin is lifted away from the measuring board so that
        neither tip remains in contact with the board, the photo will be
        denied</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">E.</strong>
      <span
        >Eye—The fish’s left eye must be visible in photo. Violation will result
        in denial of the photo.</span
      >
    </p>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 d-flex">
      <strong class="mr-1 text-primary">3.</strong>
      <span class="font-weight-bold">Competition Period</span>
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">A.</strong>
      <span
        >Competition start and end dates and times are stated on the Event Page.
        Any Competitor who launches (i.e., places his watercraft on the water,
        occupies it, and goes under way) before the stated “Earliest Launch”
        time or begins fishing before the official Competition Start Time on any
        day of competition will be disqualified from the Event. When launch site
        congestion prevents others from launching safely, Competitors may place
        their watercraft in the water, occupy them, shove off and then stage
        (float, drift, or anchor) in the immediate area until the official
        “Earliest Launch,” at which time they may proceed under way. Under no
        circumstance will a fish photographed outside of the official
        Competition Start and End times be considered for scoring.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">B.</strong>
      <span
        >Check-in—Tournament Competitors who have already submitted digital
        photos by upload to the TMS must be in the Judges’ Check-in Line at
        Tournament HQ by the time specified on the Event Page. Failure to be in
        or through the Judges’ Check-in Line by that time will result in
        disqualification from the Event.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">C.</strong>
      <span
        >Early Check-in—Tournament Competitors who could not or did not upload
        their photo submissions to the TMS must report to the Judges’ Check-in
        Line at an “Early Check-in Time” specified on the Event Page and turn in
        their digital media (i.e., memory card), camera, or camera phone with
        USB cable in order for judges or tournament staff to download them to a
        computer and then manually upload them to the TMS for subsequent
        evaluation and scoring.</span
      >
    </p>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 d-flex">
      <strong class="mr-1 text-primary">4.</strong>
      <span class="font-weight-bold">Pre-fishing</span>
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">A.</strong>
      <span
        >Practice in Eligible Water is permitted on the day prior to Competition
        until the time specified in event-specific rules on the TM Event Page .
        If no pre-fishing deadline is stated on the Event Page, then practice
        may continue up until six hours prior to competition start time. There
        are no restrictions regarding areas, type of watercraft, or types of
        bait used in pre-fishing. Fishing on the event’s eligible water after
        the specified prefishing deadline will result in disqualification from
        the Event. (Refer to Rule 6 regarding pre-fishing with a professional
        fishing guide.)</span
      >
    </p>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 d-flex">
      <strong class="mr-1 text-primary">5.</strong>
      <span class="font-weight-bold">Competition Area</span>
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">A.</strong>
      <span
        >Competitive fishing is allowed only on publicly-accessible waters
        within the geographic area designated on the Event Page as “Eligible
        Watermore info.” Photos of fish caught outside of eligible water
        boundaries will be denied.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">B.</strong>
      <span
        >Competitors may launch from any public-access shore directly onto
        Eligible Water or may launch and then paddle or portage to Eligible
        Water. Crossing restricted property to reach Eligible Water is not
        permitted. Tributaries, canals, sloughs or estuaries separated from the
        rest of eligible water by temporary obstructions (e.g., fallen trees,
        log jams, beaver dams) that, if removed, could be entered by
        floating/paddling are considered eligible waters in which competition is
        permitted, and competitors are permitted to drag or portage watercraft
        over or around such obstructions. Those separated by obstructions of a
        permanent nature (e.g., levees, earthen or concrete dams, berms,
        roadbeds, spillways, waterfalls) are ineligible water, and competition
        in such areas is prohibited. Violation will result in disqualification
        from the Event.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">C.</strong>
      <span
        >Competitors are not restricted to fishing where they start out each day
        but are permitted to relocate to other Eligible Water during tournament
        hours.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">D.</strong>
      <span
        >Watercraft must not be anchored in such a position as to prevent or
        block access for other watercraft or powerboats. Violation will result
        in disqualification from the Event.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">E.</strong>
      <span
        >Fish caught in eligible water while wading or walking on the shore are
        eligible for submission under the following conditions:</span
      >
    </p>
    <v-card variant="outlined" class="mb-2 pa-4">
      <p class="mb-2 d-flex">
        <strong class="mr-1">i.</strong>
        <span
          >Competitor’s watercraft must be launched before fishing from it or
          vacating it to catch and land fish while wading or walking on the
          shore or an ice field. “Launched” means placing the watercraft on
          liquid water, occupying it, and going underway.</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">ii.</strong>
        <span
          >Competitor must pedal, paddle, or power his watercraft to the fishing
          location.</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">iii.</strong>
        <span
          >Competitor must remain within sight of his watercraft at all times
          during competition, and the watercraft must be visible in every fish
          photo submitted. Any Competitor who goes out of sight of his
          watercraft for any reason (e.g., restroom break, to seek or render
          aid, or to report an emergency) must cease competition fishing and
          resume it only after re-launching the watercraft.</span
        >
      </p>
    </v-card>
    <v-alert color="secondary" variant="tonal" class="mb-2">
      Violation will result in disqualification from the Event
    </v-alert>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 d-flex">
      <strong class="mr-1 text-primary">6.</strong>
      <span class="font-weight-bold">Cooperation and Assistance</span>
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">A.</strong>
      <span
        >Up until the end of the established pre-fishing period, competitors may
        fish with and receive direct assistance from other anglers and
        professional guides on the Eligible Water, except as restricted in Rule
        6.B. and 6.C., below.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">B.</strong>
      <span
        >Within 30 days prior to the Event, a Competitor may not fish on
        Eligible Water with, nor receive expert advice from, a professional
        fishing guide who receives payment for those services. Violation will
        result in Competitor’s disqualification from the Event.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">C.</strong>
      <span
        >Before and during the event, competitors may (i.) share gear, tackle
        and equipment, (ii.) assist in photographing a fish for submission, and
        (iii.) communicate with and fish in proximity to other people, including
        other Competitors (except Professional Fishing Guides, as stipulated in
        Rule 6.B., above); however, Competitors may not receive assistance in
        catching fish that are photographed and submitted for scoring, including
        boat positioning, casting, retrieving, hooking, playing, or landing a
        fish. Violation will result in Competitor’s disqualification from the
        Event.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">D.</strong>
      <span
        >Competitors may not submit photos of fish if, at any time from cast to
        landing of the fish, the competitor or watercraft was in physical
        contact with another person or watercraft. Violation will result in
        denial of the photo.</span
      >
    </p>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 d-flex">
      <strong class="mr-1 text-primary">7.</strong>
      <span class="font-weight-bold"
        >Fishing Tackle, Equipment, and Methods</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">A.</strong>
      <span
        >All fish submitted for score must be caught live by the Competitor who
        submits them for scoring using only equipment and legal methods that
        comply with all sportfishing statutes and regulations of the states and
        body of water in which they are taken. Competitors are responsible for
        research of and compliance with all applicable fishing and boating
        regulations. Snatching or snagging fish is not permitted. When visually
        fishing a bed for spawning bass, to be admissible all bass must be
        hooked inside the mouth. Violation will result in disqualification from
        the Event.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">B.</strong>
      <span
        >All fish must be caught by the Competitor on rod, reel, line and hook
        with artificial lures only. No traps, snares, set lines or other methods
        of fishing other than rod, reel and line are permissible, even if legal
        under state game laws. Violation will result in disqualification from
        the Event.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">C.</strong>
      <span
        >Acceptable measuring boards include the Ketch Co Measuring Boards
        (Original, Karbonate, or X). For 2022, the Hawg Trough will be allowed
        for all competitions inside Canada. Measuring boards may be shortened by
        removing inches from the upper end (opposite the fence) For example, one
        may cut a measuring board off at the 26 inch mark in order to fit
        crossways between a watercraft’s gunwales. However, it may not be
        broken, snapped, or cut apart and then reassembled at any point between
        the two ends. A crack that does not separate the measuring board into
        pieces and does not affect the measuring board’s accuracy may be
        repaired and reinforced for use. Violation will result in denial of
        photo.</span
      >
    </p>
    <v-card color="dark" variant="outlined" class="pa-4 mb-2">
      <p class="mb-2 d-flex">
        <strong class="mr-1">i.</strong>
        <span
          >A slight flex in a measuring board resulting from a fish’s weight is
          allowed; however, Competitors are prohibited from deliberately flexing
          or deforming a measuring board by exerting pressure or reshaping it.
          Any modification of a measuring board that results in
          misrepresentation of a fish’s actual length is grounds for a
          competitor’s disqualification from the event and may result in
          subsequent sanctions and penalties.</span
        >
      </p>
    </v-card>
    <p class="mb-2 d-flex">
      <strong class="mr-1">D.</strong>
      <span>Competitors may share measuring boards.</span>
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">E.</strong>
      <span
        >A break of the measuring board’s fence may be mended for use if the
        repair is rigid, flat and at a right angle to the measuring surface. The
        mended measuring board must be submitted to the Tournament or Series
        Director, personally or by two or more emailed photographs that clearly
        show (i.) the mended area and (ii.) a side view of the measuring surface
        and the fence. Violation will result in denial of photo.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">F.</strong>
      <span
        >Each competitor must use a mobile phone with camera or a digital camera
        or video camera capable of producing still photos with memory card
        and/or download cable to submit digital photographs.</span
      >
    </p>
    <v-alert variant="tonal" color="secondary" class="mb-2">
      When a Competitor’s smart phone becomes inoperable or is lost, he may
      share smartphones with other Competitors, provided each Competitor logs
      into his own TMS account in order to submit photos of fish he caught.
      Competitors under the same circumstances may share digital cameras,
      provided each Competitor can clearly and positively identify photos of the
      fish he caught. Violation will result in denial of photo.
    </v-alert>
    <p class="mb-2 d-flex">
      <strong class="mr-1">G.</strong>
      <span
        >Only artificial lures and biodegradable artificial lures may be used.
        No live bait, preserved bait, or prepared bait will be permitted during
        competition, with the exception of pork strips or rinds. Violation will
        result in disqualification from the Event.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">H.</strong>
      <span
        >Only ONE casting, spin-casting, spinning, or fly rod and reel may be in
        use at any one time. A rod and reel is considered to be “in use” when a
        lure attached to it by a line is in or on the water. A hung lure (one
        snagged in an overhanging tree) that is not in or on the water is not
        considered in use and the attached rod and reel may be set aside while
        another is in use.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">I.</strong>
      <span
        >Nets and grippers (e.g., Fish Grip, Boga-Grip) may be used for landing
        fish during tournament competition, but grippers must not remain
        attached to the fish in photos. Violation will result in denial or
        disqualification of the photo.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">J.</strong>
      <span
        >If a Competitor breaks his line while setting the hook or retrieving a
        fish, he is allowed to make an attempt with the rod being used or by
        hand to secure the line and land the fish to be counted as legal. He may
        NOT hook the line with another lure, rod and reel, or other device. If a
        Competitor loses his rod and reel while landing a fish he MAY use
        another rod and reel to retrieve the lost outfit. If after retrieval,
        the fish is still hooked, it may be landed, photographed, and submitted
        as a legal fish. Violation will result in disqualification from the
        Event.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">K.</strong>
      <span
        >TROLLING, LONG LINING, AND DRAGGING (i.e., lure(s) attached to a single
        rod and towed behind a watercraft while underway) is NOT permitted in TM
        Competition.Violation will result in disqualification from the
        Event.</span
      >
    </p>
    <v-alert variant="tonal" color="secondary" class="mb-2 font-weight-bold">
      <p class="mb-3">Trolling as a method of fishing is NOT allowed.</p>

      <p class="mb-3">Longlining as a method of fishing is NOT allowed.</p>

      <p class="mb-3">Dragging as a method IS allowed.</p>
      <p class="mb-3">
        Trolling is defined as directly using the vessel’s propulsion to present
        a lure.
      </p>

      <p class="mb-3">
        Longlining is defined as artificially extending the distance of a cast
        or lengthening/extending your retrieve by using continuous or
        intermittent human or mechanical power (i.e. pedaling, paddling or
        motor)
      </p>

      <p class="mb-3">
        Dragging is defined as not retrieving a cast and intentionally dragging
        the residual line and lure left in the water while the kayak is being
        moved by wind or current.
      </p>

      <p class="mb-3">
        You may use human or mechanical power while ALSO actively casting and
        retrieving without intentionally extending a cast or pulling the lure
        under mechanical power. An example of this would be moving down a
        shoreline while making and retrieving casts to a target.
      </p>

      <p class="mb-3">
        You may use human or mechanical power to maintain boat and/or lure
        position in wind or current.
      </p>
    </v-alert>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 d-flex">
      <strong class="mr-1 text-primary">8.</strong>
      <span class="font-weight-bold">Watercraft & Propulsion</span>
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">A.</strong>
      <span
        >Watercraft acceptable for use in TM Competition are those manufactured
        and marketed as canoes, kayaks, or SUPs for which paddling is the
        primary means of propulsion. Any other watercraft must be submitted to
        TM Administration for review and approval prior to use in
        competition.</span
      >
    </p>
    <p class="mb-2">
      Watercraft specifically approved as exceptions in advance of competition
      by TM Management are:
    </p>
    <p class="mb-2">
      Unless approved as exceptions by TM Management, the following watercraft
      may not be used in competition:
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1 pl-4">a.</strong
      ><span
        >Gas- or liquid-fueled motorized or hybrid fuel/electric power boats or
        personal watercraft</span
      >
    </p>

    <p class="mb-2 d-flex">
      <strong class="mr-1 pl-4">b.</strong><span>Sailboats</span>
    </p>

    <p class="mb-2 d-flex">
      <strong class="mr-1 pl-4">c.</strong><span>Jon boats</span>
    </p>

    <p class="mb-2 d-flex">
      <strong class="mr-1 pl-4">d.</strong
      ><span>Pirogues, coracles, and rowboats</span>
    </p>

    <p class="mb-2 d-flex">
      <strong class="mr-1 pl-4">e.</strong><span>Dinghies and skiffs</span>
    </p>

    <p class="mb-2 d-flex">
      <strong class="mr-1 pl-4">f.</strong
      ><span
        >Float tubes, inflatable rafts, and rigid inflatable boats (e.g.,
        Zodiac)</span
      >
    </p>

    <p class="mb-2 d-flex">
      <strong class="mr-1 pl-4">g.</strong
      ><span
        >Pontoon boats, pontoon-style pond boats, twin-hull watercraft, or
        similar.</span
      >
    </p>
    <v-alert variant="tonal" color="secondary" class="mb-2">
      Use of watercraft in competition other than those listed above as
      acceptable or exceptions will result in disqualification from the Event.
    </v-alert>
    <p class="mb-2 d-flex">
      <strong class="mr-1">B.</strong>
      <span
        >Watercraft propulsion is restricted to paddle, pedal, pole, or electric
        motor.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">C.</strong>
      <span
        >Unless specifically prohibited on the Event Page, use of a single
        electric propulsion unit per watercraft in TM-sanctioned competition is
        approved with the following restrictions:</span
      >
    </p>
    <v-card color="dark" variant="outlined" class="pa-4 mb-2">
      <p class="mb-2 d-flex">
        <strong class="mr-1">i.</strong>
        <span
          >Competitors must comply with all boating regulations pertaining to
          motorized kayak/electric-propulsion watercraft registration, use, and
          operating restrictions for the designated fishing area. Violation will
          result in disqualification from the Event.</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">ii.</strong>
        <span>Electric motor used to propel a watercraft:</span>
      </p>
    </v-card>
    <p class="mb-2 d-flex">
      <strong class="mr-1 pl-4">a.</strong
      ><span
        >must be attached to the kayak in a safe manner for operation, and</span
      >
    </p>

    <p class="mb-2 d-flex">
      <strong class="mr-1 pl-4">b.</strong
      ><span
        >may not exceed the lesser of (a.) manufacturer’s labeled Maximum
        HP/Thrust Capacity, (b.) 3 HP, or (c.) 155 foot-pound thrust. Violation
        of either condition will result in disqualification from the
        Event.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">D.</strong>
      <span
        >Towing, transport or relocation assistance from another watercraft,
        including “mothershipping,” is NOT permitted except in cases of
        emergency, as when pulling a kayak from a danger zone or restricted
        area. Once the emergency has ended, the Competitor may resume fishing
        only if the watercraft was pulled no more than 100 yards from the danger
        zone. Violation will result in disqualification from the Event.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">E.</strong>
      <span
        >Trolling (i.e., lure(s) attached to a single rod and towed behind a
        watercraft while underway) is NOT permitted in TM Competition.Violation
        will result in disqualification from the Event. If caught using the
        trolling method competitors will suffer a 3 month ban from all TM
        competition.</span
      >
    </p>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 d-flex">
      <strong class="mr-1 text-primary">9.</strong>
      <span class="font-weight-bold"
        >Photo Standards—Digital photographs of each fish submitted to judges
        for scoring must meet these criteria:</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">A.</strong>
      <span
        >Unique—Each digital photo submitted in each Event must be of a
        different fish. Subsequent digital photos of the same fish submitted by
        the same Competitor in an Event will be denied.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">B.</strong>
      <span
        >Visible/Legible—Each photo must be in sharply focused and clear enough
        for a judge to:</span
      >
    </p>
    <v-card color="dark" variant="outlined" class="pa-4 mb-2">
      <p class="mb-2 d-flex">
        <strong class="mr-1">i.</strong>
        <span
          >Verify the correct Identifier Form and Event ID Code. Inability of
          the judge to verify will result in denial of photo.</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">ii.</strong>
        <span
          >Verify the fish’s condition. Violation will result in denial of
          photo.</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">iii.</strong>
        <span
          >Verify the fish’s length. Judge will score fish at the greatest
          length of which he is certain.</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">iv.</strong>
        <span
          >Verify whether the bass’ jaw or lip, or the redfish’s nose, is in
          contact with the measuring board fence. Inability of the judge to
          verify will result in a penalty of 1 inch.</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">v.</strong>
        <span
          >Except where covered by hand or fingers, the entire fish, from its
          mouth/jaw to the tip of its caudal (tail) fin, must be visible in
          photos. If either end is covered or cropped off, the photo will be
          denied.</span
        >
      </p>
    </v-card>
    <p class="mb-2 d-flex">
      <strong class="mr-1">C.</strong>
      <span
        >Centered—Camera lens positioned directly over the center of the fish’s
        body, which contributes to accurate measurement by judges. Photos taken
        at a low angle in order obscure an open mouth may be denied or penalized
        at the Tournament or Series Director’s discretion.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">D.</strong>
      <span
        >First-generation—Each digital photo must be a first-generation image
        (not a photo or screen-shot of another photo). Violation will result in
        denial of photo.</span
      >
    </p>
    <v-alert variant="tonal" color="secondary" class="pa-4 mb-2">
      Photos submitted for scoring that, in judges’ opinions, contain evidence
      of a possible disqualifying infraction—including photos that appear to
      have been cropped or modified (e.g, image enhanced or filtered) in such a
      way as to obscure infractions—will be accepted with a score of 0.01″ and a
      note indicating the nature of the infraction is emailed to the Competitor.
      The Tournament or Series Director will review all such photos and will
      make any disqualification ruling. The Tournament or Series Director may
      also contact Competitors to request submission of original un-cropped,
      un-filtered photos for further review and verification. Failure by
      Competitors to respond or refusal to submit the original photo will result
      in denial of the photo.
    </v-alert>
    <p class="mb-2 d-flex">
      <strong class="mr-1">E.</strong>
      <span
        >Identifiable—Official TM Identifier Form above, below, or beside that
        fish (not covering any portion of the fish, unless attached to the
        Competitor’s hand or arm). Neither a hand-drawn form nor an image of the
        TM Identifier Form on a smart phone are permissible. Violation will
        result in denial of photo.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">F.</strong>
      <span
        >Background—Competitor’s watercraft clearly visible in the photograph.
        Violation will result in denial of photo.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">G.</strong>
      <span>Hand Position.</span>
    </p>
    <v-card color="dark" variant="outlined" class="pa-4 mb-2">
      <p class="mb-2 d-flex">
        <strong class="mr-1">i.</strong>
        <span
          >In bass competition, hand or finger(s) may be in contact with the
          caudal peduncle (fleshy, scaled part of the bass’ body at the base of
          the caudal fin) but not touching any portion of the caudal fin (tail
          fin). Violation will result in a 1-inch deduction in score.</span
        >
      </p>
      <v-img :src="touchImage" max-width="800" alt="hand touch fish"></v-img>
      <p class="mb-2 mt-3 d-flex">
        <strong class="mr-1">ii.</strong>
        <span
          >No portion of a hand or finger may be under the fish’s operculum
          (hard gill flap). Violation will result in denial of photo.</span
        >
      </p>
    </v-card>
    <p class="mb-2 d-flex">
      <strong class="mr-1">H.</strong>
      <span>Mouth</span>
    </p>
    <v-card color="dark" variant="outlined" class="pa-4 mb-2">
      <p class="mb-2 d-flex">
        <strong class="mr-1">i.</strong>
        <span
          >Bass’ lip or jaw or redfish’s nose must be in contact with the
          measuring board fence (bump board, upright), and the fence must be
          visible in the photo. Violation will result in denial of photo.</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">ii.</strong>
        <span
          >In bass fishing competition, a closed mouth is preferred for
          uniformity of measurement. If a judge considers the mouth to be open
          1/4 inch or more, even if it is structurally unable to close, a
          one-inch penalty will be assessed.</span
        >
      </p>
    </v-card>
    <p class="mb-2 d-flex">
      <strong class="mr-1">I.</strong>
      <span
        >Not “In-possession.” In most states, though not all, unless a fish is
        secured by a restraint device or is placed in a holding container, state
        regulatory agencies do not consider it to be “in possession” by
        Competitors, so states’ minimum length regulations do not apply for
        photo submissions. If Event takes place in a state where photographing a
        fish constitutes “possession,” and a photo is submitted of a fish that
        does not comply with state size limits, the photo will be denied. (Refer
        to regulatory compliance, Rule 7.A.)</span
      >
    </p>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 d-flex">
      <strong class="mr-1 text-primary">10.</strong>
      <span class="font-weight-bold">Scoring</span>
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">A.</strong>
      <span
        >Fish length is determined by its caudal fin (tail fin) touching or
        crossing the factory-marked quarter-inch marincrement (e.g., ridges,
        grooves) on the measuring board. If it falls short of a 1/4-inch factory
        mark, the length is rounded down to the next lower 1/4-inch mark that
        the bass’ caudal fin actually touches or crosses. In reviewing
        photographs, judges will visualize as accurately as possible where a
        1/4-inch factory mark would be if it extended the full width of a board
        and use that imaginary line to determine a fish’s length.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">B.</strong>
      <span
        >In bass fishing competition, if any portion of a bass’ caudal (tail)
        fin extends off the edge of a Measuring Board surface, the score will be
        determined by the highest 1/4-inch measurement reached or crossed by the
        portion of caudal fin on the Measuring Board. Any portion of the caudal
        fin extending off the edge of a Measuring Board is not considered in
        scoring. Alignment aids and attachments to a TM -approved measuring
        board (e.g., a cradle attachment for the Ketch measuring board) will not
        be considered part of a Measuring Board surface when determining a
        fish’s length.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">C.</strong>
      <span
        >Tournament ranking, contingency awards, Angler of the Year Points,
        Challenge Points, and Championship qualification shall be determined by
        the aggregate length of each Competitor’s photos remaining on the
        LeaderBoard when judging has concluded and the Dispute Period has
        closed. Unless otherwise stipulated on the Event Page:</span
      >
    </p>
    <v-card color="dark" variant="outlined" class="pa-4 mb-2">
      <p class="mb-2 d-flex">
        <strong class="mr-1">i.</strong>
        <span
          >In one-day tournaments the scores of the five longest fish on the
          Leader Board when judging is complete will be combined to determine
          the aggregate score.</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">ii.</strong>
        <span
          >In two-day tournaments, the scores of the five longest fish of Day 1
          and the five longest fish on Day 2 on the Leaderboard when judging is
          complete will be combined to determine the aggregate score.</span
        >
      </p>
    </v-card>
    <p class="mb-2 d-flex">
      <strong class="mr-1">D.</strong>
      <span
        >If a Competitor’s photo is denied by a judge after end-of-competition,
        it may be automatically replaced by a previously submitted and
        auto-culled photo; however, no Competitor may submit a replacement photo
        after end-of-competition time.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">F.</strong>
      <span
        >TM judges apply the following scoring criteria to adjust measurements,
        approve or deny photos, and to penalize photos by deducting points
        (length) or disqualifying a Competitor.</span
      >
    </p>
    <v-table theme="dark">
      <thead>
        <tr>
          <th class="text-left">Item</th>
          <th class="text-left">Photo Scoring Criteria</th>
          <th>Penalty</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>a. Photo</td>
          <td>
            Blurry photo/length unclear: scored at the highest mark of which
            there is no doubt
          </td>
          <td>n/a</td>
        </tr>
        <tr>
          <td>b. Photo</td>
          <td>Blurry photo; completely unable to judge</td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>c. Photo</td>
          <td>
            Appears to be second-generation (e,g, screen shot, photo of photo)
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>d. Photo</td>
          <td>
            EXIF data and other evidence substantiates photo taken outside of
            Eligible Water boundarie
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>e. Photo</td>
          <td>
            EXIF data, other evidence substantiates photo was taken outside of
            competition hours
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>f. Photo</td>
          <td>
            Multiple digital photos submitted of the same fish; unintentional
            violation
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>g. Photo</td>
          <td>
            Multiple digital photos submitted of the same fish; evidence
            indicates intentionally
          </td>
          <td>Ban</td>
        </tr>
        <tr>
          <td>h. Photo</td>
          <td>Competitor’s watercraft not visible in photo background</td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>i. Photo</td>
          <td>Entire fish not in picture</td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>j. Orientation</td>
          <td>
            Fish positioned with right side of body toward camera; head pointing
            right and caudal fin left
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>k. Orientation</td>
          <td>
            Fish positioned with dorsal fins at bottom of photo, pelvic and anal
            fins upward
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>l. Identifier</td>
          <td>
            Official identifier Form missing, counterfeit, or incorrect for
            event
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>m. Identifier</td>
          <td>
            Identifier Form is lying on or under or covering any portion of the
            fish. Nothing may touch the fish other than the board and anglers
            hand.
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>n. Identifier</td>
          <td>Incorrect Event ID Code for Event or illegible Event ID Code</td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>o. Fish</td>
          <td>
            Secured by restraining device (e.g., stringer, tape, or clip
            attached to fish). Hooks, bait, and line must be removed.
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>p. Fish</td>
          <td>Photo violates state regulations regarding possession</td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>q. Species</td>
          <td>
            Photo of any species or variety of fish other than that approved for
            the Competition
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>r. Fish</td>
          <td>
            Photo Bass caudal (tail) fin tip fails to reach 12.00″ line on
            measuring board
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>s. Board</td>
          <td>
            Lines on the board must be visible. Hand marked lines will be at the
            judges discretion. Larger than normal width lines can result in a
            deduction.
          </td>
          <td>.25”</td>
        </tr>
        <tr>
          <td>t. Board</td>
          <td>
            Boards with remarked lines, the fin must be touching the line. Score
            will be rounded down if not touching.
          </td>
          <td>.25”</td>
        </tr>
        <tr>
          <td>u. Body</td>
          <td>
            Any portion of a hand or fingers under the fish’s operculum (hard
            gill flap)
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>v. Body</td>
          <td>Finger(s) or hand touching a bass’ caudal (tail) fin</td>
          <td>1.00″</td>
        </tr>
        <tr>
          <td>w. Body</td>
          <td>
            Hand touching or covering any part of a redfish in front of
            operculum
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>x. Body</td>
          <td>
            Entire caudal (tail) fin entirely lifted away and out of contact
            with the measuring board
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>y. Body</td>
          <td>Left eye covered; not visible in photo</td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>z. Body</td>
          <td>
            Fish appears to have been snagged, snatched, or hooked in the body
            rather than mouth
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>aa. Body</td>
          <td>
            Fish appears to be dead, mutilated, frozen, mangled, or otherwise
            damaged
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>ab. Body</td>
          <td>
            Fish appears to have been mashed, mauled, or otherwise altered to
            increase its length
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>ac. Mouth</td>
          <td>
            Bass lip or jaw or nose clearly not touching measuring board fence
            (upright end plate)
          </td>
          <td>Deny</td>
        </tr>
        <tr>
          <td>ad. Mouth</td>
          <td>
            Unable to ascertain with certainty that bass lip or jaw or redfish
            nose is touching measuring board fence
          </td>
          <td>1.00″</td>
        </tr>
        <tr>
          <td>ae. Mouth</td>
          <td>
            Mouth obviously open 1/4 inch or more, even if it appears to be
            structurally unable to close completely.
          </td>
          <td>1.00″</td>
        </tr>
        <tr>
          <td>af. Mouth</td>
          <td>
            Mouth open but judge cannot discern whether it is more than ¼ inch,
            it is at the judges discretion to mark the deduction.
          </td>
          <td>.25” - 1.00”</td>
        </tr>
      </tbody>
    </v-table>
    <p class="mb-1 mt-3 d-flex">
      <strong class="mr-1 text-primary">11.</strong>
      <span class="font-weight-bold">Penalties</span>
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">A.</strong>
      <span
        >In the event of a rule violation, the Tournament or Series Director may
        impose such penalties as deemed appropriate, including without
        limitation, the following:</span
      >
    </p>
    <v-card color="dark" variant="outlined" class="pa-4 mb-2">
      <p class="mb-2 d-flex">
        <strong class="mr-1">i.</strong>
        <span
          >Reduction of score (length) as described above or determined by the
          Tournament or Series Director</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">ii.</strong>
        <span
          >Loss of catch for the day or up until the violation has been
          remedied</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">iii.</strong>
        <span>Disqualification from the tournament in question</span>
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">iv.</strong>
        <span>
          Any additional penalties determined by the Tournament or Series
          Director including but not limited to monetary fines and/or reduction
          of Competitor of Year points.</span
        >
      </p>
    </v-card>
    <v-alert variant="tonal" color="secondary" class="pa-4 mb-2">
      Subject to the dispute and appeal processes set forth below, the
      Tournament or Series Director’s decisions on violations are final.
    </v-alert>
    <p class="mb-2 d-flex">
      <strong class="mr-1">B.</strong>
      <span
        >Following a review of evidence, a determination by the TM Tournament or
        Series Director and/or Chief Operations Officer of cheating or
        conviction of fraud will result in permanent revocation of TM
        Membership, suspension from all TM competition and TM -affiliated
        Events, and series, and revocation of all TM Social Media privileges.
        The findings will be published on a password-protected TM Members-only
        web page.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">C.</strong>
      <span
        >Competitor acknowledges that (i.) the use of polygraph tests may be
        necessary to verify compliance with rules and to settle disputes and
        (ii.) entry constitutes acceptance of this requirement and agreement to
        abide by its conclusion. Refusal by a Competitor to submit to a
        polygraph test is grounds for immediate disqualification from the TM
        Event and prohibition against future participation in any TM -affiliated
        Event. Results of a polygraph test will not be the sole determining
        factor in a TM Management Review of a Competitor’s eligibility for
        awards and qualifications for future participation but may be one of
        several factors considered in such review.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">D.</strong>
      <span
        >TM Tournament or Series Director or Chief Operations Officer
        shall</span
      >
    </p>
    <v-card color="dark" variant="outlined" class="pa-4 mb-2">
      <p class="mb-2 d-flex">
        <strong class="mr-1">i.</strong>
        <span
          >Have the discretion to determine the need for a Polygraph
          examination.</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">ii.</strong>
        <span
          >Be responsible for (i.) selecting an independent expert to administer
          the polygraph test, (ii.) establishing, in consultation with the
          expert administrator, the scope of the questions that may be asked
          during the test examination, and (iii.) interpreting the
          results.</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">iii.</strong>
        <span
          >Have sole responsibility selecting the location, date, and time of
          the test nearest TM headquarters with respect to all concerned parties
          schedule and travel cost.</span
        >
      </p>
    </v-card>
    <p class="mb-2 d-flex">
      <strong class="mr-1">E.</strong>
      <span>The Competitor shall:</span>
    </p>
    <v-card color="dark" variant="outlined" class="pa-4 mb-2">
      <p class="mb-2 d-flex">
        <strong class="mr-1">i.</strong>
        <span
          >Make himself/herself available at the location selected by the
          Tournament or Series Director or Chief Operations Officer.</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">ii.</strong>
        <span>Cooperate in all respects with such tests.</span>
      </p>
    </v-card>
    <p class="mb-2 d-flex">
      <strong class="mr-1">F.</strong>
      <span
        >Competitors may be selected at random to undergo polygraph examinations
        during or following TM competitive Events.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">G.</strong>
      <span
        >Ethics - TM and all affiliates with TM hold ethics at the forefront of
        what we do. If any competitor is found to be disrespectful, harassing,
        use of foul language and/or a blatant disregard for TM rules, event
        location, sponsors, and its affiliates shall result in an immediate DQ
        from that event, a second infraction resulting in a year long ban and
        will forfeit all AOY points up to that date, a third strike resulting in
        a lifetime ban from all TM competitions, prizes, & giveaways.</span
      >
    </p>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 mt-3 d-flex">
      <strong class="mr-1 text-primary">12.</strong>
      <span class="font-weight-bold">Awarding of Prizes</span>
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">A.</strong>
      <span
        >Prizes and numbers of positions paid out are addressed on the Event
        Page. Amounts paid per rank are subject to change based on participation
        (lower value for fewer entries and higher value for more entries) and
        sponsor provision.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">B.</strong>
      <span
        >Prizes will be processed after all photos submitted for scoring have
        been judged, the results reviewed by a separate panel, and disputes have
        been resolved.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">C.</strong>
      <span
        >Product prizes (e.g. kayaks, paddles, propulsion units) may require
        additional processing or delivery time. This allows competitors some
        flexibility in selection of model, color or style. Product selection may
        be limited to specific features and models.,</span
      >
    </p>
    <v-alert variant="tonal" color="secondary" class="pa-4 mb-2">
      Recipients of sponsor product awards must fill out and submit a Prize
      Claims Form, found on the TM Website.
    </v-alert>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 mt-3 d-flex">
      <strong class="mr-1 text-primary">13.</strong>
      <span class="font-weight-bold"
        >Taxes — Competitors are responsible for all applicable taxes on both
        cash and merchandise awards. Failure to meet those obligations may
        result in disqualification from all future TM events. All prize
        recipients whose cumulative total for the year adds up to $600 or more
        must fill out and turn in an IRS Form W-9 including name, address,
        social security number, signature and date. These are used to prepare
        IRS 1099 forms for distribution in January.</span
      >
    </p>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 mt-3 d-flex">
      <strong class="mr-1 text-primary">14.</strong>
      <span class="font-weight-bold"
        >Sale of Prizes — No prizes won participating in TM Events may be
        advertised or promoted for sale on TM Facebook pages for a period of one
        full year. Violation of this rule will result in ineligibility for all
        future events and forfeiture of qualification status.</span
      >
    </p>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 mt-3 d-flex">
      <strong class="mr-1 text-primary">15.</strong>
      <span class="font-weight-bold">Safety</span>
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">A.</strong>
      <span
        >During competition, every Competitor must have all required Coast Guard
        safety equipment, (e.g., light source, whistle or other audible signal
        device, Coast Guard-approved, chest-type life preserver [PFD]). See
        <a href="www.uscgboating.org/">www.uscgboating.org/.</a></span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">B.</strong>
      <span
        >PFD Usage — At all times, except as described below (Rule 15.C. PFD
        Wear Exceptions) while on the water during Competition hours,
        Competitors must wear a fastened USCG-certified vest-style PFD or float
        jacket. A belt-style PFD, even one that deploys as a vest, is not
        permitted during Competition. When an infraction, as described above, is
        verified by the Tournament Director, the Competitor is disqualified from
        participation in the Event.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">C.</strong>
      <span
        >PFD Wear Exceptions — Competitor assumes responsibility for all
        consequences of removing his PFD. Competitor may cease competition and
        remove the PFD while his watercraft is beached, anchored or adrift only
        if doing so creates no unsafe condition. Competitors must put on and
        securely fasten the PFD before going underway or resuming
        competition.</span
      >
    </p>
    <v-alert variant="tonal" color="secondary" class="pa-4 mb-2">
      If all of the following conditions exist, Competitor may briefly remove
      his PFD while afloat (anchored or adrift) in order to change, add or
      remove articles of clothing:
    </v-alert>
    <v-card color="dark" variant="outlined" class="pa-4 mb-2">
      <p class="mb-2 d-flex">
        <strong class="mr-1">i.</strong>
        <span>No fishing lines are in the water; and</span>
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">ii.</strong>
        <span>The watercraft is not underway; and</span>
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">iii.</strong>
        <span
          >Weather and water conditions permit one to do so safely; and</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">iv.</strong>
        <span>Competitor is over the age of 12.</span>
      </p>
    </v-card>
    <v-alert variant="tonal" color="secondary" class="pa-4 mb-2">
      When finished changing his attire, Competitor must immediately put the PFD
      back on before going underway, fishing, or engaging in any other
      competition-related activity.
    </v-alert>
    <p class="mb-2 d-flex">
      <strong class="mr-1">D.</strong>
      <span
        >The Tournament or Series Director has the right to postpone or cancel
        the start of an official tournament day because of adverse weather
        conditions or other factors that would jeopardize Competitors’ safety.
        Tournament waters may also be restricted at any time because of bad
        weather.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">E.</strong>
      <span
        >In the event of weather emergencies that pose an imminent threat to
        Competitors, the Tournament or Series Director may terminate Competition
        before the scheduled end-of-competition time. An Early Termination
        Announcement with an adjusted end-of-competition time will be broadcast
        to Competitors via email and group text messaging and will also be
        posted on the TMS site and social media sites. The Announcement will
        also specify an adjusted report-in time and location. If safe to do so,
        upon receiving the Announcement, the Competitor should make his way to
        the specified location at that time.</span
      >
    </p>
    <v-alert variant="tonal" color="secondary" class="pa-4 mb-2">
      Competitor is required to stop fishing as soon as he receives the Early
      Termination Announcement. No photos of fish caught after the adjusted
      end-of-competition time may be submitted for scoring; however, to
      accommodate those seeking shelter immediately, Competitor has up to 15
      minutes after the Adjusted End Time to submit a photo of any bass caught
      before the adjusted end-of-competition time/photo submission deadline.
    </v-alert>
    <p class="mb-2 d-flex">
      <strong class="mr-1">F.</strong>
      <span
        >In the event of an emergency situation, the Competitor should call 911
        first and then notify tournament officials as soon as possible.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">G.</strong>
      <span
        >Use of alcohol or drugs (other than those purchased over-the-counter or
        prescribed by a licensed physician) by any Competitor during an Event
        will not be tolerated and will be cause for automatic disqualification
        from this and all future TM tournaments. No alcoholic beverages or other
        non-prescription stimulants or depressants shall be allowed in
        watercraft during competition days. Chemical substance addiction or
        abuse, conviction of a felony or other crimes involving moral turpitude,
        or other conduct reflecting unfavorably upon efforts to promote safety,
        sportsmanship, fair competition and compliance with tournament rules,
        shall be grounds for rejecting any application for participation and/or
        for disqualification after circumstances are reviewed by the TM Director
        of Operations. Any disqualification, suspension from, or other
        disciplinary action regarding any tournament or fishing organization,
        shall be grounds for rejecting any application for participation in a TM
        tournament, and/or disqualification from a TM tournament, after
        circumstances are reviewed by the TM Director of Operations.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">H.</strong>
      <span
        >Night Tournaments that take place between April 1 and September 30, in
        which boats leave or return after 10:30 P.M., must comply with the
        following six stipulations in order for the event(s) to be eligible for
        Event Liability Insurance coverage provided through the North American
        Tournament Association’s policy.</span
      >
    </p>
    <v-card color="dark" variant="outlined" class="pa-4 mb-2">
      <p class="mb-2 d-flex">
        <strong class="mr-1">i.</strong>
        <span
          >All participant boats must be equipped with working Coast
          Guard-approved running lights. Running lights must remain on in
          accordance with the governing state regulations. [Note: Kayaks are
          categorized by USCG as “oar-driving craft” and meet USCG requirements
          by having “at hand” an “electric torch” (a.k.a. flashlight) that can
          be used to signal other boaters. While anchored, all watercraft must
          display a 360-degree white light. Some states impose additional
          requirements.]</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">ii.</strong>
        <span
          >The TM Event Coordinator or Tournament Director shall have
          predetermined the availability of cell phone or radio coverage to
          assure reasonable communications in the event of an emergency. [Note:
          All competitors should make sure their cell phone numbers are entered
          in the TMS and the Tournament Director should have a list of all
          competitors with cell phone numbers for texting or calls.]</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">iii.</strong>
        <span
          >The TM Event Coordinator or Tournament Director shall have on hand
          the contact information for emergency rescue and medical services and
          must maintain the ability to make contact at all times during the
          tournament.</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">iv.</strong>
        <span
          >The governing authority, as required by law or ordinance, shall be
          notified of the tournament date and hours.</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">v.</strong>
        <span
          >Competitors are restricted to an on-the-water speed limit of not more
          than 35 miles per hour between sunset and sunrise.</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">vi.</strong>
        <span
          >The Tournament or Series Director shall have in place a system of
          accounting for the return of all participants. [Note: This includes
          not only an end-of-competition check-in but also a way for competitors
          who leave early to notify the TM Event Coordinator or Tournament
          Director.]</span
        >
      </p>
    </v-card>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 mt-3 d-flex">
      <strong class="mr-1 text-primary">16.</strong>
      <span class="font-weight-bold"
        >Competitor Conduct — Competitors participating in any TM sanctioned
        event must observe the applicable rules for the Competition and must
        conduct themselves at all times in a manner that reflects favorably on
        the sport of kayak bass fishing, TM, its members, officers or
        representatives, tournaments or sponsors. Competitors are expected to
        follow high standards of sportsmanship, courtesy, integrity, safety and
        conservation in support of the sport of kayak bass fishing, especially
        when in the vicinity of non-Competitors who may be on or beside Eligible
        Water. Any Competitor who violates these principles, intentionally
        violates TM Competition Rules or regulations (attempts to commit fraud)
        or behaves in ways the reflect unfavorably up KBF efforts to promote
        fisheries conservation, clean waters and courtesy, may forfeit
        tournament winnings, face suspension from future TM sanctioned events,
        be permanently banned from competition, or any combination thereof
        deemed appropriate by TM.</span
      >
    </p>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 mt-3 d-flex">
      <strong class="mr-1 text-primary">17.</strong>
      <span class="font-weight-bold">Disputes and Appeals</span>
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">H.</strong>
      <span
        >If a Competitor wishes to dispute a rules violation penalty, a judge’s
        scoring decision, a Tournament or Series Director’s decision or other
        action that materially affected the Competitor’s ranking or prize
        eligibility, must use the TM website to submit a dispute within 24 hour
        (one full day) following completion of Event judging. The dispute must
        include the following:</span
      >
    </p>
    <v-card color="dark" variant="outlined" class="pa-4 mb-2">
      <p class="mb-2 d-flex">
        <strong class="mr-1">i.</strong>
        <span>Event title, location, and date</span>
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">ii.</strong>
        <span
          >Description or identification of penalty imposed, or condition being
          disputed</span
        >
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">iii.</strong>
        <span>Reference to pertinent rule</span>
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">iv.</strong>
        <span>Reason for dispute and requested action</span>
      </p>
      <p class="mb-2 d-flex">
        <strong class="mr-1">v.</strong>
        <span
          >Competitors are restricted to an on-the-water speed limit of not more
          than 35 miles per hour between sunset and sunrise.</span
        >
      </p>
    </v-card>
    <v-alert variant="tonal" color="secondary" class="pa-4 mb-2">
      Except for situations involving fraud or other law violation, any disputes
      submitted after the 24-hour dispute deadline will be reviewed without
      affecting the outcome of an event. No change in rank or reward will result
      from disputes submitted after the deadline. Only Competitors in the Event
      where the disputed action occurred may submit disputes. Disputes must be
      handled privately. All parties directly involved will be provided a copy
      of the dispute. The Tournament or Series Director (for judge’s rulings) or
      Chief Operating Officer (for Series Director rulings) will review the
      Competitor’s claims, thoroughly investigate the disputed decision, and
      issue a ruling.
    </v-alert>
    <p class="mb-2 d-flex">
      <strong class="mr-1">B.</strong>
      <span
        >The Competitor who filed the dispute may appeal the dispute results.
        Appeals of dispute results must be presented to the TM Chief Operations
        Officer (for Tournament/Series Director’s dispute rulings) or President
        (for COO’s dispute rulings) within 24 hours from the time and date of
        ruling. An Appeals Processing Fee of $100 must be paid to TM through the
        TM Online Store. The TM executive handling the appeal will impanel an
        appeals committee comprised of one TM staff member not involved directly
        in directing or judging the Event and two TM Members who were not
        Competitors in the Event. Appeals hearings shall take place within seven
        (7) business days from the date of receipt of the written appeal, and
        all parties must make themselves available at prescribed time of
        hearing. The appeals committee will submit its findings and
        recommendations to the TM Executive handling the appeal, whose decisions
        are final. If the final ruling is in the Competitor’s favor and reverses
        or nullifies a dispute ruling, then the entire Appeal Processing Fee
        will be refunded; however, if the ruling stands, then the Fee is
        forfeited.</span
      >
    </p>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 mt-3 d-flex">
      <strong class="mr-1 text-primary">18.</strong>
      <span class="font-weight-bold">Public Comments</span>
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">A.</strong>
      <span
        >The favorable public reputation of TM as a sanctioning organization in
        the sport of kayak bass fishing, the integrity of its officials, and the
        reputation of its media channels are valuable assets and tangible
        benefits for TM. Accordingly, it is an obligation of Competitors to
        refrain from comments in public forums and social media or to the news
        media that unreasonably attack or disparage the integrity of TM
        tournaments, tournament officials, sponsors, fellow members, fellow
        Competitors or the TM organization. Competitors are encouraged to
        express themselves and have the right to question the rules officials.
        Responsible expressions of legitimate disagreement with KBF policies are
        encouraged, as opposed to attacks upon the integrity of the rules or
        officials. However, public comments that a Competitor knows, or should
        reasonably know, will harm the reputation of Topwater Media Inc, TM
        officials, TM members or sponsors, may result in disciplinary
        action.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">B.</strong>
      <span
        >Sponsor contributions will not be devalued by disputes or disagreements
        in public forums or social media platforms.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">C.</strong>
      <span
        >Publicly disputing a judge’s ruling or any dispute or appeal decision
        by a TM Tournament Director, Series Director or TM Executive will result
        in participants receiving a warning for a first offense. A repeat of the
        offense will result in disciplinary action up to disqualification from
        the current Event and permanent termination of eligibility for future TM
        Events, even if previously qualified. This provision is not intended to
        discourage legitimate disputes but to ensure that a fair and unbiased
        approach is used and the approved challenge and appeals processes are
        followed in determining the best possible outcome.</span
      >
    </p>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 mt-3 d-flex">
      <strong class="mr-1 text-primary">19.</strong>
      <span class="font-weight-bold"
        >Unavailability and Force Majeure — TM will use reasonable efforts to
        hold the Event; however, under no circumstances will TM be held
        responsible for unavailability caused by circumstances beyond TM’s
        control, including flood, fire, hurricanes, earthquakes or other “acts
        of God,” acts of government, civil unrest, acts of terror, strikes or
        labor problems nor will any Event Registration be cancelled, altered or
        amended once the Event has commenced on the basis of circumstances
        beyond TM’s reasonable control.</span
      >
    </p>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 mt-3 d-flex">
      <strong class="mr-1 text-primary">20.</strong>
      <span class="font-weight-bold"
        >Compliance with Laws — This Agreement shall be governed by the laws of
        the State of Michigan. All rights and obligations of the TM and the
        Competitor under this Agreement are subject to all applicable federal,
        state and local laws and regulations. These terms and conditions shall
        be deemed revised to conform to applicable statutes, rules and
        regulations. TM may modify the terms and conditions stated herein at any
        time in its sole and absolute discretion. Competitor shall be bound by
        the modified Agreement and agree that no other modifications shall be
        enforceable. This Agreement constitutes the entire Agreement between the
        parties and supersedes all prior agreements between the parties, whether
        written or oral. The invalidity or unenforceability of any provision
        hereof shall not affect the validity or enforceability of any other
        provision. The waiver of any breach shall not constitute a waiver of any
        subsequent breach.</span
      >
    </p>
    <v-divider class="mb-4"></v-divider>
    <p class="mb-1 mt-3 d-flex">
      <strong class="mr-1 text-primary">21.</strong>
      <span class="font-weight-bold">Waivers and Releases</span>
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">A.</strong>
      <span
        >Name and Likeness Release — By registering for this TM Event, the
        Competitor grants TM the right to use all photographs, likenesses,
        stories, accounts, details of Competitor contributions, exploits,
        interviews, bloopers, and information pertinent to the TM Event, as well
        as any video footage, photographs gathered at the Event, for marketing
        and promotional purposes. This release is granted without expectation
        for additional compensation upon submission of Event Registration.</span
      >
    </p>
    <p class="mb-2 d-flex">
      <strong class="mr-1">B.</strong>
      <span
        >Waiver and Release of Liability — As a condition of participation in a
        Competition, and by virtue of registering for the Competition, each
        Competitor agrees to accept the terms and condition of the TM Waiver and
        Release of Liability Agreement (below). This Agreement absolves TM, its
        officers, staff, and volunteers from responsibility and threat of
        litigation in the case of injury, loss, or death sustained by a
        Competitor in the Event. Registrants under the age of 18 must have their
        waiver/release form signed by the parent or adult guardian who will
        accompany them throughout competition. A printed copy of the TM Waiver
        and Release Form (below) is available at the Event HQ Check-in Table and
        must be signed and on file with TM before a registrant is eligible to
        compete.</span
      >
    </p>
  </v-container>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
export default defineComponent({
  name: "TopwaterRules",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
  },
  data: () => ({
    touchImage: require("../assets/images/touch.png"),
  }),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
