<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <!---Billing Container -->
  <v-container class="mb-10">
    <h1 class="text-h4 mt-4 mb-4">Billing Information</h1>
    <v-tabs v-model="billingTabs" color="primary" show-arrows>
      <v-tab value="editBilling" prepend-icon="mdi:mdi-tooltip-edit"
        >Edit</v-tab
      >
      <v-tab value="creditCard" prepend-icon="mdi:mdi-credit-card-multiple"
        >CREDIT CARD</v-tab
      >
      <v-tab value="payPal" prepend-icon="fab fa-paypal">PAYPAL</v-tab>
    </v-tabs>

    <v-window v-model="billingTabs">
      <!--Edit Billing information tab-->
      <v-window-item value="editBilling">
        <v-card class="mt-10">
          <!--- Edit Billing Information Form-->
          <v-form @submit.prevent>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="First Name"
                  hint="Enter your first name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Last Name"
                  hint="Enter your last name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Phone Number"
                  hint="Enter your phone number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Address"
                  hint="Enter your address"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-btn type="submit" block color="secondary" class="mt-2 pa-6"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-window-item>
      <!--Card card tab-->
      <v-window-item value="creditCard">
        <v-card>
          <div class="mt-7 mb-7">
            <v-icon
              icon="fab fa-cc-visa fa-2x"
              color="secondary"
              class="mr-2 d-inline-flex"
            ></v-icon>
            <v-icon
              icon="fab fa-cc-mastercard fa-2x"
              color="secondary"
              class="mr-2 d-inline-flex"
            ></v-icon>
            <v-icon
              icon="fab fa-cc-amex fa-2x"
              color="secondary"
              class="mr-2 d-inline-flex"
            ></v-icon>
            <v-icon
              icon="fab fa-cc-discover fa-2x"
              color="secondary"
              class="mr-2 d-inline-flex"
            ></v-icon>
          </div>
          <!--- Credit Card Form-->
          <v-form @submit.prevent>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Name"
                  hint="Enter your name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Card Number"
                  hint="Enter your card number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="Expiration Date"
                      hint="Enter your card expiration date"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="CVC"
                      hint="Enter your card cvc number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Zip Code"
                  hint="Enter your area zip code"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-btn type="submit" block color="secondary" class="mt-2 pa-6"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-window-item>
      <!--Pay with payPal tab-->
      <v-window-item value="payPal">
        <v-card class="mt-10">
          <svg height="25px" width="122px" viewBox="0 0 99 24">
            <title>paypal-logo</title>
            <g fill="none">
              <path
                d="M73.964 2.995h-5.868c-.382 0-.765.32-.83.706L64.91 18.78c-.064.322.19.578.51.578h2.997c.255 
                                    0 .51-.192.574-.513l.7-4.3a.841.841 0 0 1 .83-.705h1.85c3.83 0 6.06-1.86 6.64-5.583.26-1.605 0-2.888-.76-3.786-.83-.96-2.3-1.47-4.27-1.47m.7 
                                    5.52c-.32 2.12-1.91 2.12-3.44 2.12h-.89l.64-3.91c.07-.25.26-.38.51-.38h.32c1.09 0 2.04 0 2.55.58.32.32.39.84.32 1.61"
                fill="#009CDE"
              ></path>
              <path
                d="M32.32 2.995h-5.804a.831.831 0 0 0-.83.706l-2.36 15.02c-.063.32.192.58.51.58h2.807c.383 0 
                                    .765-.32.83-.7l.637-4.04c.064-.38.383-.7.83-.7h1.848c3.827 
                                    0 6.06-1.86 6.633-5.58.26-1.6 0-2.89-.76-3.78-.89-.98-2.36-1.5-4.34-1.5m.7 5.52c-.31 2.12-1.91 2.12-3.44 
                                    2.12h-.89l.64-3.91c.07-.25.26-.38.51-.38h.38c1.09 0 2.04 0 2.55.57.26.32.39.83.26 1.6m16.78-.07H47a.55.55 0 0 0-.51.38l-.13.77-.193-.26c-.573-.9-1.976-1.16-3.315-1.16-3.06 0-5.74 2.38-6.25 5.65-.252 1.67.13 3.21 1.02 4.3.83 1.03 2.04 1.41 3.51 1.41 2.488 0 3.89-1.6 3.89-1.6l-.126.77c-.064.32.19.58.51.58h2.487c.39 0 .77-.32.83-.705l1.53-9.63c.07-.256-.19-.513-.44-.513m-3.89 5.457c-.25 1.61-1.53 2.7-3.12 2.7-.83 0-1.46-.257-1.85-.77-.38-.513-.57-1.22-.44-1.99.26-1.604 1.53-2.695 3.13-2.695.77 0 1.47.254 1.85.77.38.51.57 1.22.45 1.99"
                fill="#003087"
              ></path>
              <path
                d="M91.438 8.45h-2.806a.554.554 0 0 0-.51.384l-.128.77-.192-.256c-.573-.9-1.977-1.155-3.316-1.155-3.06 0-5.74 2.374-6.25 5.647-.255 1.668.128 3.208 1.02 4.3.83 1.026 2.042 1.41 3.508 1.41 2.488 0 3.89-1.603 3.89-1.603l-.127.77c-.064.32.19.577.51.577h2.487c.383 0 .766-.32.83-.706l1.53-9.625c.064-.257-.19-.514-.446-.514m-3.89 5.45c-.256 1.6-1.53 2.69-3.126 2.69-.83 0-1.466-.26-1.85-.77-.382-.52-.573-1.22-.445-1.99.255-1.61 1.53-2.7 3.125-2.7.765 0 1.466.25 1.85.77.382.51.51 1.22.445 1.99"
                fill="#009CDE"
              ></path>
              <path
                d="M64.652 8.45h-2.806c-.255 0-.51.128-.7.384l-3.89 5.776-1.66-5.52c-.127-.32-.446-.577-.765-.577h-2.74c-.32 0-.57.32-.44.642l3.13 9.112-2.93 4.107c-.25.32 0 .77.39.77h2.81c.26 0 .51-.128.64-.32L65.04 9.22c.255-.322 0-.77-.383-.77"
                fill="#003087"
              ></path>
              <path
                d="M94.69 3.38l-2.423 15.337c-.064.32.19.577.51.577H95.2c.383 0 .766-.32.83-.706l2.36-15.08c.063-.32-.192-.578-.51-.578H95.2a.733.733 0 0 0-.51.45"
                fill="#009CDE"
              ></path>
              <path
                d="M15.19 5.023a2.62 2.62 0 0 0-.4-.102c-.15-.04-.294-.05-.444-.08-.528-.08-1.088-.12-1.7-.12h-.008c.61 0 1.172.05 1.7.13l.443.09c.13.05.28.06.41.11.06.02.13.04.16.07.25.09.49.19.7.29l.01-.02c-.21-.1-.42-.19-.66-.27a.824.824 0 0 1-.19-.06m-8.9 2.96l-.69 4.36-.02.22.03-.12.01-.104.68-4.35m6.99 2.44c.33-.23.63-.5.9-.8-.55.6-1.2 1.06-1.93 1.4.36-.17.71-.36 1.03-.59m2.75-5.08c.02.01.03.017.05.025.25-1.66 0-2.78-.88-3.79C14.24.465 12.48 0 10.22 0c2.26 0 4.017.466 4.98 1.592.87.998 1.122 2.114.843 3.763"
                fill="#000"
              ></path>
              <path
                d="M6.712 5.404a.812.812 0 0 1 .46-.61.748.748 0 0 1 .36-.084h5.115c.61 0 1.17.042 1.7.127.15.025.292.042.442.084.15.03.27.06.4.11.06.03.12.04.19.06.23.08.45.17.66.27.28-1.65.02-2.76-.85-3.76C14.23.47 12.48 0 10.22 0H3.69c-.46 0-.86.34-.92.805L.042 18.185c-.067.34.21.662.544.662h4.017l1.005-6.48.697-4.364.407-2.6"
                fill="#003087"
              ></path>
              <path
                d="M17.04 6.107c-.292-.28-.602-.542-.962-.728-.025.12-.042.25-.067.38-.43 2.23-1.39 3.73-2.71 4.67-1.31.95-2.99 1.34-4.88 1.34H6.51c-.22 0-.423.08-.584.21-.16.14-.27.34-.31.57l-.99 6.27-.276 1.78c-.043.29.19.57.484.57h3.39c.4 0 .75-.3.82-.7l.04-.17.653-4.08.042-.24c.06-.4.4-.7.82-.7h.5c3.29 0 5.86-1.36 6.61-5.25.31-1.64.15-2.99-.67-3.94"
                fill="#009CDE"
              ></path>
              <path
                d="M8.412 11.774c1.89 0 3.57-.39 4.884-1.34-.325.23-.67.43-1.037.595-1.12.5-2.42.72-3.84.72h-1.9a.888.888 0 0 0-.61.23c.16-.13.36-.21.58-.21H8.4m7.558-5.99c-.325 1.67-.938 2.92-1.777 3.85.85-.93 1.47-2.19 1.8-3.87.03-.13.04-.26.07-.38l-.01-.01v.02l-.06.38"
                fill="#000"
              ></path>
              <path
                d="M6.048 11.887a.906.906 0 0 1 .48-.138h1.925c1.404 0 2.695-.22 3.806-.72.73-.34 1.38-.8 1.93-1.4.84-.93 1.45-2.18 1.77-3.85.02-.13.04-.26.06-.38l-.08-.05.08.04v-.03c-.21-.1-.45-.21-.7-.29-.04-.04-.1-.05-.16-.07-.13-.04-.28-.06-.4-.1-.16-.03-.3-.06-.45-.08-.53-.09-1.09-.13-1.7-.13H7.54c-.123 0-.25.04-.36.08-.25.1-.416.34-.46.61L6.306 8l-.68 4.347-.013.106c.05-.18.15-.34.285-.456a.826.826 0 0 1 .15-.112"
                fill="#011A6A"
              ></path>
              <path
                d="M7.173 4.794a.806.806 0 0 0-.46.61l-.408 2.6.416-2.6c.05-.27.21-.508.46-.61.11-.042.24-.084.36-.084-.12 0-.25.025-.36.084m-.65 6.956c-.17 0-.34.05-.48.137.14-.083.3-.13.47-.13h1.91c1.42 0 2.72-.22 3.83-.728-1.11.5-2.4.72-3.8.72H6.53"
                fill="#000"
              >
                &lt;
              </path>
            </g>
          </svg>
          <v-row class="mt-7">
            <v-col cols="12" md="6">
              <v-text-field
                label="Payment Amount"
                hint="Enter the amount you want to pay"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-btn type="submit" block color="secondary" class="mt-2 pa-6"
                >PAY NOW <v-icon icon="fa:fab fa-paypal"></v-icon
              ></v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-window-item>
    </v-window>
  </v-container>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
export default defineComponent({
  name: "HomeView",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
  },

  data: () => ({
    billingTabs: null,
  }),

  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
