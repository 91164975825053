<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-container>
    <v-sheet :rounded="rounded" max-width="1024" class="mx-auto mb-4 mt-4">
      <h1 class="text-h4 mb-4">About Us</h1>
      <v-divider class="mb-4"></v-divider>
      <v-timeline side="end">
        <v-timeline-item dot-color="grey" size="small">
          <template v-slot:opposite>
            <div
              class="text-uppercase text-grey font-weight-bold"
              v-text="2018"
            ></div>
          </template>
          <v-alert>
            <p class="mb-5">
              <a href="./" class="text-uppercase text-primary font-weight-bold"
                >Topwater Series</a
              >
              was founded in 2018 by
              <strong class="text-uppercase font-weight-bold"
                >Kyle Van Leuven</strong
              >
              and
              <strong class="text-uppercase font-weight-bold"
                >Mike Anderson</strong
              >
              with the focus on creating a series for the anglers, by the
              anglers. Shortly after starting the series Kyle also took a role
              helping Tom Mullins and Matt Elliot running the hugely successful
              Michigan Kayak Trail.
            </p>
          </v-alert>
        </v-timeline-item>
        <v-timeline-item dot-color="grey" size="small">
          <template v-slot:opposite>
            <div
              class="text-uppercase text-grey font-weight-bold"
              v-text="2019"
            ></div>
          </template>
          <v-alert>
            <p class="mb-5">
              <a href="./" class="text-uppercase text-primary font-weight-bold"
                >Topwater Series</a
              >
              then became a "<strong class="text-uppercase font-weight-bold"
                >sister series</strong
              >" of the Michigan Kayak Trail and had a successful first season
              in 2019 holding a 12 event league series, 2 online events, and
              several open events.The first league series proved to be a huge
              success and had a loyal following of anglers throughout West
              Michigan. Angler Matt Pyatt edged out competitor Dave Mull to take
              the first League Series Angler of the Year. After the completion
              of the first season Topwater ran 2 open events on the East side of
              Michigan with a great response. Since the beginning Topwater has
              been about the community, a series for the anglers, by the anglers
            </p>
          </v-alert>
        </v-timeline-item>
        <v-timeline-item dot-color="primary" size="large">
          <template v-slot:opposite>
            <div
              class="text-uppercase text-primary font-weight-bold"
              v-text="2020"
            ></div>
          </template>
          <v-alert>
            <p class="mb-5">
              <a href="./" class="text-uppercase text-primary font-weight-bold"
                >Topwater Series</a
              >
              is not possible without the hard work of the tournament directors
              and community that support it. In 2020 Topwater is back, bigger
              and better than ever. With several individuals stepping up as
              tournament directors Topwater will have league series events
              through Michigan and a larger trail series covering the entire
              state.
            </p>
          </v-alert>
        </v-timeline-item>
      </v-timeline>
    </v-sheet>
  </v-container>
  <GMapMap
    :center="center"
    :zoom="10"
    map-type-id="terrain"
    style="width: 100%; height: 400px"
  >
    <MarkerClusterer>
      <GMapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        :icon="customMarker"
        @click="center = m.position"
      />
    </MarkerClusterer>
  </GMapMap>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
import MarkerClusterer from "@googlemaps/markerclusterer";
export default defineComponent({
  name: "AboutUs",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
    MarkerClusterer,
  },
  data: () => ({
    center: { lat: 42.963795, lng: -85.670006 },
    customMarker: require("../assets/images/map-marker.svg"),
    markers: [
      {
        position: {
          lat: 42.963795,
          lng: -85.670006,
        },
      },
    ],
  }),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
