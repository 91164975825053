<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <!--Home banner -->
  <HomeBanner />
  <!--Adds Banner -->
  <AddsBanner />
  <!---home upcomming events and tournaments reults-->
  <v-container class="upcoming-events-tournaments">
    <v-row>
      <!-- Upcoming events col -->
      <v-col cols="12" md="6">
        <UpcomingEvents />
      </v-col>
      <!-- Tournament Results col -->
      <v-col cols="12" md="6">
        <TournamentsResults />
      </v-col>
    </v-row>
  </v-container>
  <!--Homepage Topwater Ads -->
  <TopwaterAds />
  <!---Youtube video and facebook feed section-->
  <div class="youtube-facebook">
    <v-container>
      <v-row>
        <!-- Youtube video iframe -->
        <v-col cols="12" md="8">
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/EhtNgqy9Orc"
            title="Topwater Series video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <TopwaterOutfitters />
        </v-col>
        <!-- Facebook feeds iframe -->
        <v-col cols="12" md="4">
          <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FTopwaterSeries&tabs=timeline&width=370&height=700&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId"
            width="370"
            height="700"
            style="border: none; overflow: hidden"
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <!-- Top water tracker add -->
  <TopwaterTracker />
  <!--Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
// Components
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
import HomeBanner from "../components/HomeBanner.vue";
import UpcomingEvents from "../components/UpcomingEvents.vue";
import TournamentsResults from "../components/TournamentsResults.vue";
import TopwaterAds from "../components/TopwaterAds.vue";
import TopwaterOutfitters from "../components/TopwaterOutfitters.vue";
import TopwaterTracker from "../components/TopwaterTracker.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    HeaderComponent,
    QuickLinks,
    HomeBanner,
    UpcomingEvents,
    TournamentsResults,
    TopwaterAds,
    FooterComponent,
    TopwaterOutfitters,
    TopwaterTracker,
  },

  data: () => ({
    listOne: [],
    listTwo: [],
    topoMapImage: require("../assets/images/topomap.png"),
  }),

  methods: {
    async getData() {
      // errors.value = {};
      try {
        // const result = await api.home.getData({ });
        // add data to lists and render
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>

<style scoped lang="scss">
.home-banner {
  margin: -70px 0 50px;
  border-bottom: 1px solid #ffdccc;
  @media only screen and (max-width: 950px) {
    margin-top: 0;
    padding: 50px 0;
  }
}
.upcoming-events-tournaments {
  margin-bottom: 50px;
}
iframe {
  width: 100%;
}
.youtube-facebook {
  margin: 0 0 50px;
}
</style>
