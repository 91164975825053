<template>
  <GMapMap
    :center="center"
    :zoom="10"
    map-type-id="terrain"
    style="width: 100%; height: 300px"
    class="mb-7"
  >
    <MarkerClusterer>
      <GMapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        :icon="customMarker"
        @click="center = m.position"
      />
    </MarkerClusterer>
  </GMapMap>
</template>
<script>
import { defineComponent } from "vue";
import MarkerClusterer from "@googlemaps/markerclusterer";
export default defineComponent({
  name: "NearbyPlaces",
  components: {
    MarkerClusterer,
  },
  data: () => ({
    loaded: false,
    loading: false,
    center: { lat: 40.712776, lng: -74.005974 },
    customMarker: require("../../assets/images/map-marker.svg"),
    markers: [
      {
        position: {
          lat: 40.712776,
          lng: -74.005974,
        },
      },
      {
        position: {
          lat: 40.735657,
          lng: -74.1723667,
        },
      },
      {
        position: {
          lat: 40.800657,
          lng: -73.728465,
        },
      },
      {
        position: {
          lat: 40.6642699,
          lng: -73.7084645,
        },
      },
    ],
  }),
  methods: {
    onClick() {
      this.loading = true;

      setTimeout(() => {
        this.loading = false;
        this.loaded = true;
      }, 2000);
    },
  },
});
</script>
