<template>
  <!-- login form -->
  <v-form @submit.prevent>
    <v-responsive class="mx-auto pa-6" max-width="400">
      <v-img :width="400" aspect-ratio="16/9" cover :src="logoImg"></v-img>
      <v-text-field
        label="Username"
        type="email"
        hint="Enter your Email"
        required
        :rules="rules"
      ></v-text-field>
      <v-text-field
        label="Password"
        type="password"
        hint="Enter your password to access this website"
        :rules="rules"
        required
      ></v-text-field>
      <div class="text-right">
        <a class="forgot-password" href="/forgotPassword"
          >Forgot Your Password</a
        >
      </div>
      <v-checkbox label="Remember Me" color="primary"></v-checkbox>
      <v-btn
        type="submit"
        :rounded="0"
        color="orange-accent-4"
        size="large"
        block
        >Login
      </v-btn>
      <div class="text-center mt-2"></div>
      <div class="text-center not-member mt-3">
        Not a Member? <a href="/register">Register here</a>
      </div>
    </v-responsive>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    user: {
      email: "",
      password: "",
    },
    rules: [
      (value) => {
        if (value) return true;

        return "this is required field.";
      },
    ],
    logoImg: require("../../../assets/TOPLOGO.png"),
  }),

  methods: {
    async login() {
      // errors.value = {};
      try {
        // const result = await api.users.loginUser({ user: form });
        // updateUser(result.data.user);
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
};
</script>
<style scoped>
.forgot-password,
.not-member a {
  text-align: center;
  text-decoration: none;
  color: #077198;
  font-size: 12px;
}
.forgot-password:hover,
.not-member a:hover {
  color: #000;
  text-decoration: underline;
}
.not-member {
  font-size: 14px;
  color: #000;
}
.not-member a {
  font-size: 14px;
}
</style>
