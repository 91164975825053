<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-container>
    <v-row>
      <v-col cols="12" sm="3">
        <v-sheet class="pa-3" color="orange-lighten-5" rounded>
          <v-img :src="leagueLogo"></v-img>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="9">
        <h1 class="text-h4 mb-3">League Series</h1>
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <v-btn
              type="button"
              color="secondary"
              size="large"
              class="pa-6 mb-3 d-flex"
              prepend-icon="mdi:mdi-magnify"
              block
              href="FindLeague"
            >
              Find a League Near You
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" class="pb-0">
            <v-btn
              type="button"
              color="primary"
              size="large"
              class="pa-6 mb-3 d-flex"
              prepend-icon="mdi:mdi-file-edit-outline"
              href="StartLeague"
              block
              >Create a New League
            </v-btn>
          </v-col>
          <v-col cols="12" md="6" class="pb-0">
            <v-select
              chips
              label="MICHIGAN"
              color="secondary"
              hint="MICHIGAN"
              persistent-hint
              variant="solo"
              :items="[
                'California',
                'Colorado',
                'Florida',
                'Georgia',
                'Texas',
                'Wyoming',
              ]"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="pb-0">
            <v-select
              chips
              label="INDIANA"
              hint="INDIANA"
              persistent-hint
              variant="solo"
              :items="[
                'California',
                'Colorado',
                'Florida',
                'Georgia',
                'Texas',
                'Wyoming',
              ]"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              chips
              label="MISSOURI"
              hint="MISSOURI"
              persistent-hint
              variant="solo"
              :items="[
                'California',
                'Colorado',
                'Florida',
                'Georgia',
                'Texas',
                'Wyoming',
              ]"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              chips
              label="ONTARIO, CANADA"
              hint="ONTARIO, CANADA"
              persistent-hint
              variant="solo"
              :items="[
                'California',
                'Colorado',
                'Florida',
                'Georgia',
                'Texas',
                'Wyoming',
              ]"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              chips
              label="SPECIAL EVENTS"
              hint="SPECIAL EVENTS"
              persistent-hint
              variant="solo"
              :items="[
                'California',
                'Colorado',
                'Florida',
                'Georgia',
                'Texas',
                'Wyoming',
              ]"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="mt-7 mb-7"></v-divider>
  </v-container>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
export default defineComponent({
  name: "HomeView",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
  },
  data: () => ({
    leagueLogo: require("../assets/images/leagueLogo.png"),
  }),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
