<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-container>
    <v-sheet
      :rounded="rounded"
      max-width="1024"
      class="mx-auto pa-6 mb-4 mt-4"
      color="grey-lighten-5"
    >
      <h1 class="text-h4 mb-4">Contact Us</h1>
      <v-divider class="mb-4"></v-divider>
      <v-row>
        <v-col cols="12" md="6">
          <v-form fast-fail @submit.prevent>
            <v-text-field
              v-model="firstName"
              label="Name"
              :rules="firstNameRules"
            ></v-text-field>
            <v-textarea label="Message"></v-textarea>
            <v-btn
              type="submit"
              block
              color="secondary"
              size="large"
              class="mt-2"
              >Submit</v-btn
            >
          </v-form>
        </v-col>
        <v-col cols="12" md="6">
          <GMapMap
            :center="center"
            :zoom="10"
            map-type-id="terrain"
            style="width: 100%; height: 300px"
            class="mb-7"
          >
            <MarkerClusterer>
              <GMapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="false"
                :icon="customMarker"
                @click="center = m.position"
              />
            </MarkerClusterer>
          </GMapMap>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
import MarkerClusterer from "@googlemaps/markerclusterer";
export default defineComponent({
  name: "ContactUs",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
    MarkerClusterer,
  },
  data: () => ({
    firstName: "",
    firstNameRules: [
      (value) => {
        if (value?.length > 3) return true;

        return "Name must be at least 3 characters.";
      },
    ],
    center: { lat: 40.273502, lng: -86.126976 },
    customMarker: require("../assets/images/map-marker.svg"),
    markers: [
      {
        position: {
          lat: 40.273502,
          lng: -86.126976,
        },
      },
    ],
  }),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
