<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-container class="mb-10">
    <h1 class="text-h4 mb-7">Sponsors</h1>
    <v-row>
      <v-col
        v-for="logo in topwaterSponsors"
        :key="logo"
        class="d-flex child-flex light-border flex-column text-center"
        cols="4"
        md="2"
        sm="4"
      >
        <v-img
          :src="logo.logo"
          :max-width="337"
          :alt="logo.url"
          :href="logo.url"
          class="mb-2"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <a :href="logo.url">Visit Site</a>
      </v-col>
    </v-row>
  </v-container>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
export default defineComponent({
  name: "TopwaterSponsors",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
  },
  data: () => ({
    topwaterSponsors: [
      {
        logo: require("../assets/images/sponsors/ANCHORWIZARD.png"),
        url: " https://www.anchorwizard.com/",
      },
      {
        logo: require("../assets/images/sponsors/BASSCRACKJIGS.png"),
        url: "https://basscrackjigs.com/",
      },
      {
        logo: require("../assets/images/sponsors/BIOENNOPOWER.png"),
        url: "https://www.bioennopower.com/",
      },
      {
        logo: require("../assets/images/sponsors/FISHENG.png"),
        url: "https://www.fisheng.com/",
      },
      {
        logo: require("../assets/images/sponsors/FLAMBEAUOUTDOORS.png"),
        url: "https://www.flambeauoutdoors.com/",
      },
      {
        logo: require("../assets/images/sponsors/GSM.png"),
        url: "https://www.gsmoutdoors.com/",
      },
      {
        logo: require("../assets/images/sponsors/KETCH.png"),
        url: "https://ketchproducts.com/",
      },
      {
        logo: require("../assets/images/sponsors/KLEEN.png"),
        url: "https://kleenroofs.com/",
      },
      {
        logo: require("../assets/images/sponsors/MALONE.jpg"),
        url: "https://maloneautoracks.com/",
      },
      {
        logo: require("../assets/images/sponsors/181866423_108738174703950_4454784821270712741_n.jpg"),
        url: "https://www.facebook.com/odditiebaitco/",
      },
      {
        logoOldTown: require("../assets/images/sponsors/OT_Black.png"),
        url: "https://oldtownwatercraft.johnsonoutdoors.com/us",
      },
      {
        logo: require("../assets/images/sponsors/BaitShopLogo_834x.png"),
        url: "https://thebaitshop.us/home",
      },
      {
        logo: require("../assets/images/sponsors/REELSWEDE.png"),
        url: "https://reelswede.com/",
      },
      {
        logo: require("../assets/images/sponsors/RINSEKIT.png"),
        url: "https://rinsekit.com/",
      },
      {
        logo: require("../assets/images/sponsors/STRIKER.png"),
        url: "https://strikerbrands.com/",
      },
      {
        logo: require("../assets/images/sponsors/WATERDOG-OUTFITTERS.png"),
        url: "https://water-dog.com/",
      },
      {
        logo: require("../assets/images/sponsors/MAINSTREAM.png"),
        url: "https://mainstreamoutdoors.com/",
      },
      {
        logo: require("../assets/images/sponsors/YAKATTACK.png"),
        url: "https://www.yakattack.us/",
      },
      {
        logo: require("../assets/images/sponsors/2KJIGS.png"),
        url: "https://www.2kjigs.com/",
      },
      {
        logo: require("../assets/images/sponsors/BERKLEY.png"),
        url: "https://www.berkley-fishing.com/",
      },
      {
        logo: require("../assets/images/sponsors/EGO.png"),
        url: "https://www.egofishing.com/",
      },
      {
        logoJudgeJaw: require("../assets/images/sponsors/JUDGESJAWJACKERS.png"),
        url: "https://www.judgesjawjackers.com/",
      },
      {
        logo: require("../assets/images/sponsors/BLUEFOXGIFTS.png"),
        url: "https://www.bluefoxgifts.com/",
      },
      {
        logo: require("../assets/images/sponsors/FLOP.png"),
        url: "https://www.flopindustries.com/",
      },
      {
        logo: require("../assets/images/sponsors/KERCO.png"),
        url: "https://www.flopindustries.com/",
      },
      {
        logo: require("../assets/images/sponsors/MIDWEST.png"),
        url: "https://www.midwestwatercraft.com/",
      },
      {
        logo: require("../assets/images/sponsors/NEVERLOST.png"),
        url: "https://www.neverlostestore.com/",
      },
      {
        logo: require("../assets/images/sponsors/Complete-Fisherman-Logo.png"),
        url: "https://www.thecompletefisherman.us/",
      },
      {
        logo: require("../assets/images/sponsors/SOLARBAT.png"),
        url: "https://www.solarbat.com/",
      },
      {
        logo: require("../assets/images/sponsors/LaFontaine.png"),
        url: "https://www.familydeal.com/",
      },
      {
        logo: require("../assets/images/sponsors/LIPRIPPIN.png"),
        url: "https://liprippinbaits.com/",
      },
      {
        logo: require("../assets/images/sponsors/YAKPOWER.png"),
        url: "https://yak-power.com/",
      },
      {
        logo: require("../assets/images/sponsors/cropped-MOTLEYFISHING-4.png"),
        url: "https://www.motleyfishing.com/",
      },
      {
        logo: require("../assets/images/sponsors/PUREFISHING.png"),
        url: "https://www.purefishing.com/",
      },
      {
        logo: require("../assets/images/sponsors/Roguegearco.png"),
        url: "https://roguegear.com/",
      },
    ],
  }),

  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
<style lang="scss" scoped>
.light-border {
  border: 1px solid #ededed;
}
</style>
