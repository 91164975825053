<template>
  <h2 class="upcoming-events-bar">
    <span>Upcoming Events</span>
    <a href="#">View All</a>
  </h2>
  <v-table theme="custom" :color="transparent" height="350px" fixed-header>
    <thead>
      <tr>
        <th class="text-left text-secondary font-weight-bold text-uppercase">
          Date
        </th>
        <th class="text-left text-secondary font-weight-bold text-uppercase">
          Lake
        </th>
        <th class="text-right text-secondary font-weight-bold text-uppercase">
          Register
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in 10" :key="item">
        <td>April 5</td>
        <td>
          <div>Reeds Lake</div>
          <span>Roots</span>
        </td>
        <td class="text-right">
          <a href="/registermember">Register</a>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
    };
  },
};
</script>
<style lang="scss" scoped>
.upcoming-events-bar {
  font-size: 18px;
  padding: 12px 15px;
  border-left: 3px solid #ff8506;
  background: #f7f7f7;
  a {
    color: #ff8506;
    font-size: 14px;
    margin: 0 0 0 10px;
    font-weight: normal;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
