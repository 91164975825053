<template>
  <header id="header" class="justify-space-between align-center">
    <!-- logo -->
    <a class="logo" href="./"><v-img :width="120" :src="logoImg"></v-img></a>
    <!-- header navigation bar -->
    <ul class="header-navbar">
      <li v-for="n in navigation" :key="n">
        <v-menu open-on-hover transition="scale-transition">
          <template v-slot:activator="{ props }">
            <router-link :to="n.link" exact v-bind="props">
              {{ n.name }}
            </router-link>
          </template>
          <ul v-if="n.sublevel" width="300" class="mt-2 sublevel">
            <li v-for="subitem in n.sublevel" :key="subitem">
              <router-link :to="subitem.link" exact>
                {{ subitem.subname }}
              </router-link>
              <v-divider></v-divider>
            </li>
          </ul>
        </v-menu>
      </li>
    </ul>
    <!-- header right -->
    <v-sheet class="header-right" color="transparent">
      <!-- header right menu icon -->
      <v-hover>
        <template v-slot:default="{ isHovering, props }">
          <v-chip
            v-bind="props"
            class="v-menu-icon"
            :color="isHovering ? 'primary' : 'white'"
          >
            <v-icon
              color="white"
              :width="50"
              @click.stop="mobileMenuDrawer = !mobileMenuDrawer"
              aria-hidden="false"
            >
              mdi:mdi-menu
            </v-icon>
          </v-chip>
        </template>
      </v-hover>
      <v-hover>
        <template v-slot:default="{ isHovering, props }">
          <v-chip
            style="cursor: pointer"
            v-bind="props"
            :color="isHovering ? 'primary' : 'white'"
          >
            <v-icon
              :width="50"
              @click.stop="accountDrawer = !accountDrawer"
              aria-hidden="false"
            >
              mdi:mdi-account-settings
            </v-icon>
          </v-chip>
        </template>
      </v-hover>
    </v-sheet>
  </header>
  <!-- Account drawer right -->
  <v-navigation-drawer v-model="accountDrawer" temporary location="right">
    <v-list-item
      prepend-avatar="https://randomuser.me/api/portraits/men/78.jpg"
      title="John Leider"
    ></v-list-item>

    <v-divider></v-divider>

    <v-list density="compact">
      <v-list-item
        v-for="(item, i) in drwaerItems"
        :key="i"
        :value="item"
        active-color="primary"
        :href="item.url"
        class="text-decoration-none"
      >
        <template v-slot:prepend>
          <v-icon :icon="item.icon"></v-icon>
        </template>
        <v-list-item-title
          v-text="item.text"
          class="text-body-2"
        ></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <!-- Mobile menu drawer -->
  <v-navigation-drawer
    class="mobile-menu-drawer"
    v-model="mobileMenuDrawer"
    temporary
    location="left"
  >
    <a class="logo" href="/home"><v-img :width="120" :src="logoImg"></v-img></a>
    <v-divider></v-divider>
    <ul class="mobile-menu">
      <li v-for="n in navigation" :key="n">
        <div class="d-flex justify-content-between d-block">
          <a :href="n.link" v-text="n.name"></a>
          <div class="submenu-arrows" v-if="n.sublevel" @click="showSubmenu(n)">
            <v-icon
              v-if="!showMobileSubLevel && !n.subMenu"
              color="dark"
              :width="50"
              aria-hidden="false"
            >
              mdi:mdi-chevron-right
            </v-icon>
            <v-icon
              v-if="n.subMenu"
              color="dark"
              :width="50"
              aria-hidden="false"
            >
              mdi:mdi-chevron-down
            </v-icon>
          </div>
        </div>
        <!-- sub level menu -->
        <div
          v-if="n.sublevel && n.subMenu"
          class="sublevel"
          transition="scale-transition"
        >
          <ul>
            <li v-for="subitem in n.sublevel" :key="subitem">
              <a :href="subitem.link" v-text="subitem.subname"></a>
              <v-divider></v-divider>
            </li>
          </ul>
        </div>
        <v-divider></v-divider>
      </li>
    </ul>
  </v-navigation-drawer>
</template>
<script>
export default {
  data: () => ({
    logoImg: require("../../assets/TOPLOGO.png"),
    navigation: [
      { name: "Home", id: 1, link: "/" },
      {
        name: "Leagues",
        id: 2,
        link: "/leaguesIndex",
        sublevel: [
          { subname: "Find a League Near You", id: 1, link: "/FindLeague" },
          { subname: "Create New League", id: 1, link: "/StartLeague" },
          { subname: "Tournament Results", id: 1, link: "/TournamentResults" },
          { subname: "Favorites", id: 1, link: "/favorites" },
        ],
      },
      {
        name: "Special Events ",
        id: 3,
        link: "/specialEvents",
      },
      { name: "Anglers", id: 4, link: "/AnglerListing" },
      { name: "Stores", id: 5, link: "/stores" },
      {
        name: "Resources",
        id: 6,
        link: "/resources",
        sublevel: [
          { subname: "Update Account", id: 1, link: "/updateAccount" },
          { subname: "Rules", id: 1, link: "/TopwaterRules" },
          { subname: "Identifiers", id: 1, link: "/TopwaterIdentifiers" },
          { subname: "Helpful Videos", id: 1, link: "/videos" },
          { subname: "News", id: 1, link: "/news" },
          { subname: "Directory", id: 1, link: "/staffdriectory" },
        ],
      },
      { name: "About", id: 7, link: "/AboutUS" },
      { name: "Contact", id: 8, link: "/ContactUs" },
      { name: "AOY", id: 9, link: "/AoyStandings" },
      { name: "Register", id: 10, link: "/register" },
    ],
    accountDrawer: null,
    mobileMenuDrawer: null,
    drwaerItems: [
      {
        text: "Dashboard",
        icon: "mdi:mdi-view-dashboard",
        url: "/anglerdashboard",
      },
      { text: "Membership", icon: "mdi:mdi-wallet-membership" },
      { text: "View Profile", icon: "mdi:mdi-account-outline" },
      {
        text: "Profile Settings",
        icon: "mdi:mdi-account-settings",
        url: "/profilesettings",
      },
      { text: "Inbox", icon: "mdi:mdi-inbox" },
      { text: "Logout", icon: "mdi:mdi-logout" },
      { text: "Favorites", icon: "mdi:mdi-cards-heart" },
    ],
    showMobileSubLevel: null,
  }),
  methods: {
    showSubmenu(i) {
      i.subMenu = !i.subMenu;
    },
  },
};
</script>

<style scoped lang="scss">
.router-link-active {
  &.router-link-exact-active {
    color: #ff8506;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -5px;
      height: 1px;
      background: #ff8506;
    }
  }
}
#header {
  padding: 0 20px 0 0;
  display: flex;
  position: sticky;
  z-index: 100;
  border-bottom: 1px solid #eedede;
  top: 0;
  background: #077198;
  z-index: 999;
  .header-right {
    .v-menu-icon {
      display: none;
      @media only screen and (max-width: 992px) {
        display: inline-flex;
        margin-right: 10px;
      }
    }
  }
}
.header-navbar {
  display: inline-flex;
  list-style-type: none;
  @media only screen and (max-width: 992px) {
    display: none;
  }
  > li {
    margin: 0 20px;
    width: auto;
    position: relative;
    @media only screen and (max-width: 1100px) {
      margin: 0 12px;
    }
    > a {
      color: #fff;
      text-decoration: none;
      font-size: 14px;
      height: 40px;
      line-height: 40px;
    }
    &:hover {
      > a {
        color: #ff8506;
      }
      > .sublevel {
        display: block;
      }
    }
  }
}
.sublevel {
  background: #fff;
  width: 250px;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
  border-radius: 8px;
  li {
    list-style: none;
    border-top: 1px solid #f4ecec;
    position: relative;
    overflow: hidden;
    &::before {
      position: absolute;
      left: -9999px;
      top: 50%;
      content: "";
      transition: all 0.3s;
      margin: -8px 0 0;
    }
    &:first-child {
      border: 0;
    }
    a {
      padding: 12px;
      color: #2e2e2e;
      display: block;
      transition: all 0.2s;
      text-decoration: none;
      font-size: 14px;
      position: relative;
    }
    &:hover {
      &::before {
        transition: all 0.3s;
        left: 0;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-left: 8px solid #ff8506;
        border-bottom: 8px solid transparent;
      }
      a {
        color: #ff8506;
        padding-left: 20px;
        transition: all 0.2s;
      }
    }
  }
}
.mobile-menu {
  li {
    position: relative;
    .submenu-arrows {
      position: absolute;
      top: 6px;
      right: 10px;
    }
    a {
      padding: 10px 10px;
      text-decoration: none;
      color: #000;
      display: block;
      font-size: 14px;
    }
    .sublevel {
      margin: 0 0 10px;
      ul {
        background: #f4f4f4;
      }
      li a {
        padding-left: 30px;
        text-decoration: none;
        color: #000;
      }
    }
  }
}
</style>
