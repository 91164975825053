<template>
  <h2 class="mt-3 mb-2">Topwater Outfitters</h2>
  <Splide
    theme="primary"
    :options="{
      rewind: true,
      perPage: 3,
      perMove: 1,
      autoplay: true,
      pagination: false,
      wheel: false,
      drag: free,
      snap: true,
      keyboard: true,
      slideFocus: true,
      arrows: true,
      breakpoints: {
        640: {
          perPage: 2,
        },
      },
    }"
    aria-label="My Favorite Images"
  >
    <SplideSlide v-for="n in items" :key="n.id" data-splide-interval="3000">
      <a class="topwater-outfitters-link ma-3 d-block" :href="n.linkUrl">
        <v-img :src="n" alt="n" />
      </a>
    </SplideSlide>
  </Splide>
</template>
<script>
export default {
  data: () => ({
    model: null,
    items: [
      {
        src: require("../assets/images/Topwater-Outfitters/327299745_1402311603876673_2206762706216667715_n.jpg"),
      },
      {
        src: require("../assets/images/Topwater-Outfitters/NewTopwaterHat2-228x228.jpg"),
      },
      {
        src: require("../assets/images/Topwater-Outfitters/NewTopwaterSweatshirt-228x228.jpg"),
      },
      {
        src: require("../assets/images/Topwater-Outfitters/NewTopwaterTshirt-228x228.jpg"),
      },
      {
        src: require("../assets/images/Topwater-Outfitters/TopwaterDecalsBlack-200x200.jpg"),
      },
      {
        src: require("../assets/images/Topwater-Outfitters/TopwaterDecalsBlue-200x200.jpg"),
      },
      {
        src: require("../assets/images/Topwater-Outfitters/TopwaterDecalsOrange-200x200.jpg"),
      },
      {
        src: require("../assets/images/Topwater-Outfitters/TopwaterDecalsRed-200x200.jpg"),
      },
      {
        src: require("../assets/images/Topwater-Outfitters/TopwaterDecalsWhite-200x200.jpg"),
      },
      {
        src: require("../assets/images/Topwater-Outfitters/TopwaterFishingShirt-228x228.jpg"),
      },
      {
        src: require("../assets/images/Topwater-Outfitters/TopwaterStickerSmall-228x228.jpg"),
      },
    ],
  }),
};
</script>
<style scoped lang="scss">
.topwater-outfitters-link {
  border: 1px solid #ededed;
}
</style>
