<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <!---Member Registration container--->
  <v-container>
    <v-sheet class="mx-auto mt-4" :max-width="1024">
      <h1 class="text-h4 mb-2">Member Registration</h1>
      <v-divider class="mb-8"></v-divider>
      <v-form @submit.prevent>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              label="Username"
              hint="Enter your username"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="First Name"
              hint="Enter your first name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Last Name"
              hint="Enter your last name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="Email" hint="Enter your email"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Date of Birth"
              hint="Enter your date of birth"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Gender"
              hint="Enter your gender"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Country"
              hint="Enter your country name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="City"
              hint="Enter your city name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="State"
              hint="Enter your state name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Address"
              hint="Enter your address"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="Zip" hint="Enter your zip code"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Phone Number"
              hint="Enter your phone number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Password"
              hint="Enter your password"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Emergency Contact Name"
              hint="Enter your emergency contact name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Emergency Phone"
              hint="Enter your emergency phone number"
            ></v-text-field>
          </v-col>
          <!---Profile Picture -->
          <v-col cols="12">
            <v-sheet class="pa-8 pb-2" rounded color="orange-lighten-5">
              <h3 class="text-h6 mb-2">Profile Picture</h3>
              <v-divider class="pb-4"></v-divider>
              <v-row>
                <v-col cols="12" md="4">
                  <FilePreviewfrom></FilePreviewfrom>
                </v-col>
                <!---Biography-->
                <v-col cols="12">
                  <h3 class="text-h6 mb-2">Biography</h3>
                  <v-divider class="pb-4"></v-divider>
                  <v-textarea
                    counter
                    label="Biography"
                    :rules="rules"
                    :model-value="value"
                    rows="7"
                  ></v-textarea>
                </v-col>
                <!---kayak Picture kayak make / kayak model-->
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12">
                      <h3 class="text-h6 mb-2">Kayak Picture</h3>
                      <v-divider class="pb-4"></v-divider>
                      <FilePreviewfrom></FilePreviewfrom>
                    </v-col>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            label="Kayak Make"
                            hint="Enter kayak make"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            label="Kayak Model"
                            hint="Enter kayak model"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <h3 class="text-h6 mb-2">Sponsors</h3>
                  <v-divider class="pb-4"></v-divider>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Sponsors"
                        hint="Enter Sponsor here"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Sponsors"
                        hint="Enter Sponsor here"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Sponsors"
                        hint="Enter Sponsor here"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Sponsors"
                        hint="Enter Sponsor here"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Sponsors"
                        hint="Enter Sponsor here"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Sponsors"
                        hint="Enter Sponsor here"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-sheet>
            <v-row>
              <v-col cols="12" md="6">
                <h3 class="text-h6 mb-2 mt-7">Enter Social Links</h3>
                <v-divider class="pb-4"></v-divider>
                <v-text-field
                  label="Facebook"
                  hint="Enter your facebook link"
                ></v-text-field>
                <v-text-field
                  label="Instagram"
                  hint="Enter your Instagram link"
                ></v-text-field>
                <v-text-field
                  label="Youtube"
                  hint="Enter your youtube link"
                ></v-text-field>
                <v-text-field
                  label="Twitter"
                  hint="Enter your twitter link"
                ></v-text-field>
                <v-text-field
                  label="Tik Toc"
                  hint="Enter your tik toc link"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <h3 class="text-h6 mb-2 mt-7">Best Catch Pics (max 6)</h3>
                <v-divider class="pb-4"></v-divider>
                <v-row>
                  <v-col cols="6" sm="4">
                    <FilePreviewfrom></FilePreviewfrom>
                  </v-col>
                  <v-col cols="6" sm="4">
                    <FilePreviewfrom></FilePreviewfrom>
                  </v-col>
                  <v-col cols="6" sm="4">
                    <FilePreviewfrom></FilePreviewfrom>
                  </v-col>
                  <v-col cols="6" sm="4">
                    <FilePreviewfrom></FilePreviewfrom>
                  </v-col>
                  <v-col cols="6" sm="4">
                    <FilePreviewfrom></FilePreviewfrom>
                  </v-col>
                  <v-col cols="6" sm="4">
                    <FilePreviewfrom></FilePreviewfrom>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" md="6">
            <v-checkbox
              v-model="checkbox"
              :rules="[(v) => !!v || 'You must agree to continue!']"
              label="By clickin this box I agree with terms and conditions?"
              color="primary"
              required
            ></v-checkbox>
            <v-btn
              block
              :max-width="250"
              type="submit"
              color="secondary"
              class="mt-2 pa-6"
              >Payment</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
  <!--Footer component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
import FilePreviewfrom from "../components/FilePreview.vue";
export default defineComponent({
  name: "HomeView",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
    FilePreviewfrom,
  },

  data: () => ({
    checkbox: false,
  }),

  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
