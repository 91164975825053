<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-layout>
    <AnglerSidebar />
    <v-main>
      <!---Angler dashboard container -->
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <h1 class="text-h5">{{ pageTitle }}</h1>
          <v-sheet class="justify-end d-flex">
            <AnglerSearch />
            <AnglerLocation />
          </v-sheet>
        </div>
        <v-divider class="mt-2 mb-7"></v-divider>
        <!-- Send new message -->
        <v-dialog v-model="newMessage" max-width="800">
          <template v-slot:activator="{ props }">
            <v-btn color="secondary" class="mb-6" v-bind="props">
              Send New Message
            </v-btn>
          </template>

          <v-card class="pa-5">
            <h3 class="text-h6 font-weight-light mb-1">New Message</h3>
            <v-divider class="mb-3"></v-divider>
            <v-textarea label="Write your message here..."></v-textarea>
            <v-row>
              <v-col cols="5">
                <v-btn block color="primary" size="large">SEND</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn size="large" color="default" @click="newMessage = false"
                  >CANCEL</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <v-expansion-panels variant="inset">
          <v-expansion-panel class="bg-blue-lighten-5">
            <v-expansion-panel-title
              ><v-icon color="secondary" class="mr-4">mdi:mdi-email</v-icon>
              Message 1
              <template v-slot:actions>
                <v-icon color="primary" icon="mdi:mdi-delete-outline"> </v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text> Some content </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title
              ><v-icon color="secondary" class="mr-4"
                >mdi:mdi-email-open-outline</v-icon
              >
              Message 2
              <template v-slot:actions>
                <v-icon color="primary" icon="mdi:mdi-delete-outline"> </v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text> Some content </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-title
              ><v-icon color="secondary" class="mr-4"
                >mdi:mdi-email-open-outline</v-icon
              >
              Message 3
              <template v-slot:actions>
                <v-icon color="primary" icon="mdi:mdi-delete-outline"> </v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text> Some content </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-main>
  </v-layout>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
import AnglerSidebar from "../components/anglerDashboard/AnglerSidebar.vue";
import AnglerSearch from "../components/anglerDashboard/AnglerSearch.vue";
import AnglerLocation from "../components/anglerDashboard/AnglerLocation.vue";
export default defineComponent({
  name: "AnglerInbox",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
    AnglerSidebar,
    AnglerSearch,
    AnglerLocation,
  },
  data: () => ({
    pageTitle: "Inbox",
    newMessage: false,
  }),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
