<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <!---Staff Directory Container -->
  <v-container>
    <h1 class="text-h4 mb-7 mt-4">Topwater Staff Directory</h1>
    <v-tabs v-model="tab" color="primary" show-arrows>
      <v-tab value="one">Tournament Directors</v-tab>
      <v-tab value="two">Board Of Direcotrs</v-tab>
      <v-tab value="three">Admins</v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <!-- Tournament Directors Tab-->
        <v-window-item value="one">
          <v-card>
            <v-row>
              <v-col
                v-for="card in cardsDirectors"
                :key="card.title"
                cols="12"
                sm="6"
                md="4"
                class="mb-3"
              >
                <v-hover
                  v-slot="{ isHovering, props }"
                  open-delay="200"
                  close-delay="200"
                >
                  <v-card
                    :elevation="isHovering ? 4 : 1"
                    :class="{ 'on-hover': isHovering }"
                    v-bind="props"
                  >
                    <v-img
                      :src="card.src"
                      class="align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    >
                    </v-img>
                    <v-card-title
                      class="subtitle text-captilize"
                      color="primary"
                    >
                      {{ card.title }}
                      <v-dialog
                        v-model="card.dialog"
                        width="350"
                        transition="dialog-bottom-transition"
                      >
                        <template v-slot:activator="{ props }">
                          <v-btn
                            color="primary"
                            size="small"
                            variant="text"
                            icon="mdi:mdi-eye-outline"
                            v-bind="props"
                          ></v-btn>
                        </template>

                        <v-card>
                          <v-card-text class="pa-0">
                            <v-list>
                              <h2 class="text-center">
                                <span>{{ card.title }}</span>
                                <v-btn
                                  size="small"
                                  color="primary"
                                  variant="text"
                                  icon="mdi:mdi-close"
                                  @click="card.dialog = false"
                                ></v-btn>
                              </h2>
                              <v-divider></v-divider>
                              <v-list-item
                                active-color="primary"
                                rounded="shaped"
                              >
                                <v-list-item-title>
                                  <v-btn
                                    size="large"
                                    color="primary"
                                    variant="text"
                                    icon="mdi:mdi-phone-outline"
                                    @click="dialog = false"
                                  ></v-btn>
                                  {{ card.number }}
                                </v-list-item-title>
                                <v-divider></v-divider>
                                <v-list-item-title>
                                  <v-btn
                                    size="large"
                                    color="primary"
                                    variant="text"
                                    icon="mdi:mdi-email-outline"
                                    @click="dialog = false"
                                  ></v-btn>
                                  {{ card.email }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-card-title>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-card>
        </v-window-item>
        <!-- Board Of Directors Tab-->
        <v-window-item value="two">
          <v-card>
            <v-row>
              <v-col
                v-for="card in boardDirectors"
                :key="card.title"
                cols="12"
                sm="6"
                md="4"
                class="mb-3"
              >
                <v-hover
                  v-slot="{ isHovering, props }"
                  open-delay="200"
                  close-delay="200"
                >
                  <v-card
                    :elevation="isHovering ? 4 : 1"
                    :class="{ 'on-hover': isHovering }"
                    v-bind="props"
                  >
                    <v-img
                      :src="card.src"
                      class="align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    >
                    </v-img>
                    <v-card-title
                      class="subtitle text-captilize"
                      color="primary"
                    >
                      {{ card.title }}
                      <v-dialog
                        v-model="card.dialog"
                        width="350"
                        transition="dialog-bottom-transition"
                      >
                        <template v-slot:activator="{ props }">
                          <v-btn
                            color="primary"
                            size="small"
                            variant="text"
                            icon="mdi:mdi-eye-outline"
                            v-bind="props"
                          ></v-btn>
                        </template>

                        <v-card>
                          <v-card-text class="pa-0">
                            <v-list>
                              <h2 class="text-center">
                                <span>{{ card.title }}</span>
                                <v-btn
                                  size="small"
                                  color="primary"
                                  variant="text"
                                  icon="mdi:mdi-close"
                                  @click="card.dialog = false"
                                ></v-btn>
                              </h2>
                              <v-divider></v-divider>
                              <v-list-item
                                active-color="primary"
                                rounded="shaped"
                              >
                                <v-list-item-title>
                                  <v-btn
                                    size="large"
                                    color="primary"
                                    variant="text"
                                    icon="mdi:mdi-phone-outline"
                                    @click="dialog = false"
                                  ></v-btn>
                                  {{ card.number }}
                                </v-list-item-title>
                                <v-divider></v-divider>
                                <v-list-item-title>
                                  <v-btn
                                    size="large"
                                    color="primary"
                                    variant="text"
                                    icon="mdi:mdi-email-outline"
                                    @click="dialog = false"
                                  ></v-btn>
                                  {{ card.email }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-card-title>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-card>
        </v-window-item>
        <!-- Admins Tab-->
        <v-window-item value="three">
          <v-card>
            <v-row>
              <v-col
                v-for="card in admins"
                :key="card.title"
                cols="12"
                sm="6"
                md="4"
                class="mb-3"
              >
                <v-hover
                  v-slot="{ isHovering, props }"
                  open-delay="200"
                  close-delay="200"
                >
                  <v-card
                    :elevation="isHovering ? 4 : 1"
                    :class="{ 'on-hover': isHovering }"
                    v-bind="props"
                  >
                    <v-img
                      :src="card.src"
                      class="align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                    >
                    </v-img>
                    <v-card-title
                      class="subtitle text-captilize"
                      color="primary"
                    >
                      {{ card.title }}
                      <v-dialog
                        v-model="card.dialog"
                        width="350"
                        transition="dialog-bottom-transition"
                      >
                        <template v-slot:activator="{ props }">
                          <v-btn
                            color="primary"
                            size="small"
                            variant="text"
                            icon="mdi:mdi-eye-outline"
                            v-bind="props"
                          ></v-btn>
                        </template>

                        <v-card>
                          <v-card-text class="pa-0">
                            <v-list>
                              <h2 class="text-center">
                                <span>{{ card.title }}</span>
                                <v-btn
                                  size="small"
                                  color="primary"
                                  variant="text"
                                  icon="mdi:mdi-close"
                                  @click="card.dialog = false"
                                ></v-btn>
                              </h2>
                              <v-divider></v-divider>
                              <v-list-item
                                active-color="primary"
                                rounded="shaped"
                              >
                                <v-list-item-title>
                                  <v-btn
                                    size="large"
                                    color="primary"
                                    variant="text"
                                    icon="mdi:mdi-phone-outline"
                                    @click="dialog = false"
                                  ></v-btn>
                                  {{ card.number }}
                                </v-list-item-title>
                                <v-divider></v-divider>
                                <v-list-item-title>
                                  <v-btn
                                    size="large"
                                    color="primary"
                                    variant="text"
                                    icon="mdi:mdi-email-outline"
                                    @click="dialog = false"
                                  ></v-btn>
                                  {{ card.email }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-card-title>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-card>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-container>

  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
export default defineComponent({
  name: "HomeView",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
  },
  data: () => ({
    tab: null,
    cardsDirectors: [
      {
        title: "Jhon Doe",
        src: "https://i.pravatar.cc/300?img=1",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
      {
        title: "Tyler Blue",
        src: "https://i.pravatar.cc/300?img=14",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
      {
        title: "Anderson Green",
        src: "https://i.pravatar.cc/300?img=8",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
      {
        title: "Tommy Green",
        src: "https://i.pravatar.cc/300?img=10",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
      {
        title: "Emmit Brown",
        src: "https://i.pravatar.cc/300?img=7",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
      {
        title: "Steve john",
        src: "https://i.pravatar.cc/300?img=12",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
    ],
    boardDirectors: [
      {
        title: "Jhon Doe",
        src: "https://i.pravatar.cc/300?img=38",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
      {
        title: "Tyler Blue",
        src: "https://i.pravatar.cc/300?img=37",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
      {
        title: "Anderson Green",
        src: "https://i.pravatar.cc/300?img=3",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
      {
        title: "Tommy Green",
        src: "https://i.pravatar.cc/300?img=34",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
      {
        title: "Emmit Brown",
        src: "https://i.pravatar.cc/300?img=32",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
      {
        title: "Steve john",
        src: "https://i.pravatar.cc/300?img=30",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
    ],
    admins: [
      {
        title: "Jhon Doe",
        src: "https://i.pravatar.cc/300?img=50",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
      {
        title: "Tyler Blue",
        src: "https://i.pravatar.cc/300?img=41",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
      {
        title: "Anderson Green",
        src: "https://i.pravatar.cc/300?img=30",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
      {
        title: "Tommy Green",
        src: "https://i.pravatar.cc/300?img=13",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
      {
        title: "Emmit Brown",
        src: "https://i.pravatar.cc/300?img=17",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
      {
        title: "Steve john",
        src: "https://i.pravatar.cc/300?img=20",
        number: "+1124455994",
        email: "fcs@topwater.com",
        dialog: false,
      },
    ],
  }),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
>
