<template>
  <v-btn
    class="ml-4"
    color="primary"
    rounded
    variant="tonal"
    icon="mdi:mdi-map-marker-outline"
    @click.stop="locationDrawer = !locationDrawer"
  >
  </v-btn>
  <!-- location Drawer-->
  <v-navigation-drawer
    v-model="locationDrawer"
    location="top"
    temporary
    class="d-flex justify-center"
  >
    <v-img class="location-bg-image" :src="topoImage" cover></v-img>
    <v-btn
      rounded
      varient="ountlined"
      icon="mdi:mdi-close"
      color="primary"
      bordered
      class="btn-close"
      @click.stop="locationDrawer = !locationDrawer"
    >
    </v-btn>
    <v-sheet
      class="d-flex align-center mx-auto pa-4 pb-0"
      style="margin-top: 80px"
      :max-width="1024"
      rounded
    >
      <v-text-field
        prepend-inner-icon="mdi:mdi-map-marker"
        label="Location"
        color="primary"
        class="text-white"
        variant="solo"
        hint="Search for your location"
      >
      </v-text-field>
      {{ top }}
    </v-sheet>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "AnglerLocation",

  data: () => ({
    locationDrawer: null,
    topoImage: require("../../assets/images/topomap.png"),
  }),
};
</script>
<style lang="scss" scoped>
.btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.location-bg-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
