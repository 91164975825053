<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-layout>
    <AnglerSidebar />
    <v-main>
      <!---Angler dashboard container -->
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <h1 class="text-h5">{{ pageTitle }}</h1>
          <v-sheet class="justify-end d-flex">
            <AnglerSearch />
            <AnglerLocation />
          </v-sheet>
        </div>
        <v-divider class="mt-2 mb-7"></v-divider>
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card class="d-flex flex-column justify-center pa-4" height="150">
              <!--Add funds dialogue-->
              <v-dialog v-model="addFunds" max-width="600">
                <template v-slot:activator="{ props }">
                  <v-btn class="mb-3" color="secondary" v-bind="props">
                    Add Funds
                  </v-btn>
                </template>

                <v-card class="pa-4">
                  <v-card-title>Add Funds</v-card-title>
                  <v-card-text>
                    <v-text-field
                      variant="solo"
                      label="Enter Amount"
                    ></v-text-field>
                    <v-alert
                      type="info"
                      title="Please Note"
                      text="You must already have your card hooked up to your account. You can do this in your profile under billing. There will be a 3% transaction fee applied to your bill."
                      variant="tonal"
                    ></v-alert>
                  </v-card-text>
                  <v-card-actions class="pl-4 pr-4">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-btn color="primary" block>Add Funds</v-btn>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-btn color="defualt" block @click="addFunds = false"
                          >Close</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!--withdraw funds dialogue-->
              <v-dialog v-model="withdrawFunds" max-width="600">
                <template v-slot:activator="{ props }">
                  <v-btn color="primary" v-bind="props"> Withdraw Funds </v-btn>
                </template>

                <v-card class="pa-4">
                  <v-card-text>
                    <p>Current Funds</p>
                    <strong class="text-h5 font-weight-bold">$300</strong>
                    <v-text-field
                      variant="solo"
                      label="Enter Amount"
                      class="mt-4"
                    ></v-text-field>
                  </v-card-text>
                  <v-card-actions class="pl-4 pr-4">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-btn color="secondary" block>Withdraw Funds</v-btn>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-btn
                          color="defualt"
                          block
                          @click="withdrawFunds = false"
                          >Close</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card class="d-flex flex-column justify-center" height="150">
              <v-card-subtitle class="pt-5">Current Funds</v-card-subtitle>
              <v-card-title class="text-h5 pb-5 font-weight-bold">
                $23400
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card class="d-flex flex-column justify-center" height="150">
              <v-card-subtitle class="pt-5">Total Earning</v-card-subtitle>
              <v-card-title class="text-h5 pb-5 font-weight-bold">
                $3000000
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="mt-7 mb-7"></v-divider>
        <h2 class="text-h5">Transactions</h2>
        <v-list>
          <v-list-subheader>Transactions History</v-list-subheader>

          <v-list-item>
            <template v-slot:prepend>
              <v-icon icon="mdi:mdi-arrow-up"></v-icon>
            </template>

            <v-list-item-title>Alpena Tournament </v-list-item-title>
            <div><strong>-$200</strong> (Tournament Fee)</div>
            <p class="text-small text-grey-lighten-1">
              <span>May 20, 2023</span> <span>11:30 PM</span>
            </p>
          </v-list-item>
          <v-divider class="mb-4 mt-4"></v-divider>
          <v-list-item>
            <template v-slot:prepend>
              <v-icon icon="mdi:mdi-arrow-down"></v-icon>
            </template>

            <v-list-item-title>Traverse Tournament </v-list-item-title>
            <div><strong>+400</strong> (Tournament Third Place Winnings)</div>
            <p class="text-small text-grey-lighten-1">
              <span>May 20, 2023</span> <span>11:30 PM</span>
            </p>
          </v-list-item>
          <v-divider class="mb-4 mt-4"></v-divider>
          <v-list-item>
            <template v-slot:prepend>
              <v-icon icon="mdi:mdi-arrow-up"></v-icon>
            </template>

            <v-list-item-title>Alpena Tournament </v-list-item-title>
            <div><strong>-$100</strong> (Tournament Fee)</div>
            <p class="text-small text-grey-lighten-1">
              <span>May 21, 2023</span> <span>11:30 PM</span>
            </p>
          </v-list-item>
          <v-divider class="mb-4 mt-4"></v-divider>
          <v-list-item>
            <template v-slot:prepend>
              <v-icon icon="mdi:mdi-arrow-down"></v-icon>
            </template>

            <v-list-item-title>Traverse Tournament </v-list-item-title>
            <div><strong>+600</strong> (Tournament Second Place Winnings)</div>
            <p class="text-small text-grey-lighten-1">
              <span>May 22, 2023</span> <span>11:30 PM</span>
            </p>
          </v-list-item>
        </v-list>
      </v-container>
    </v-main>
  </v-layout>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
import AnglerSidebar from "../components/anglerDashboard/AnglerSidebar.vue";
import AnglerSearch from "../components/anglerDashboard/AnglerSearch.vue";
import AnglerLocation from "../components/anglerDashboard/AnglerLocation.vue";
export default defineComponent({
  name: "AddWithdrawl",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
    AnglerSidebar,
    AnglerSearch,
    AnglerLocation,
  },
  data: () => ({
    pageTitle: "Add/ Withdrawl Funds",
    addFunds: false,
    withdrawFunds: false,
  }),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
