import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import Splide from "@splidejs/vue-splide";
import SplideSlide from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";
import "./assets/scss/main.scss";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import setAuthorizationToken from "./plugins/set-authorization-token";

loadFonts();

const app = createApp(App);

app.use(router);
app.use(store);
app.use(vuetify);
app.use(Splide);
app.use(SplideSlide);
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCGXCgZzJGMnCwJoko-Xu6je6ehFWCyoCI",
  },
});
setAuthorizationToken();

app.mount("#app");
