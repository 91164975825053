<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-container>
    <h1 class="text-h4 mb-3">Terms Of Service</h1>
    <v-divider class="mb-5"></v-divider>
    <p class="mb-2">
      Yes. Cookies are small files that a site or its service provider transfers
      to your computer's hard drive through your Web browser (if you allow) that
      enables the site's or service provider's systems to recognize your browser
      and capture and remember certain information. For instance, we use cookies
      to help us remember and process the items in your shopping cart. They are
      also used to help us understand your preferences based on previous or
      current site activity, which enables us to provide you with improved
      services. We also use cookies to help us compile aggregate data about site
      traffic and site interaction so that we can offer better site experiences
      and tools in the future.
    </p>
    <p class="mb-2">
      We do not sell, trade, or otherwise transfer to outside parties your
      Personally Identifiable Information unless we provide users with advance
      notice. This does not include website hosting partners and other parties
      who assist us in operating our website, conducting our business, or
      serving our users, so long as those parties agree to keep this information
      confidential. We may also release information when it's release is
      appropriate to comply with the law, enforce our site policies, or protect
      ours or others' rights, property or safety.
    </p>
  </v-container>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
export default defineComponent({
  name: "TermsConditions",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
  },
  data: () => ({}),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
