<template>
  <v-btn
    rounded
    variant="tonal"
    icon="mdi:mdi-magnify"
    color="secondary"
    bordered
    @click.stop="searchDrawer = !searchDrawer"
  >
  </v-btn>
  <!-- Search Drawer-->
  <v-navigation-drawer
    v-model="searchDrawer"
    location="top"
    temporary
    color="secondary"
    class="d-flex justify-center"
  >
    <v-btn
      color="secondary"
      rounded
      outlined
      icon="mdi:mdi-close"
      bordered
      class="btn-close"
      @click.stop="searchDrawer = !searchDrawer"
    >
    </v-btn>
    <v-sheet
      class="d-flex align-center mx-auto pa-4 pb-0"
      color="transparent"
      style="margin-top: 80px"
      :max-width="1024"
    >
      <v-text-field
        prepend-inner-icon="mdi:mdi-magnify"
        color="secondary"
        class="text-white"
        variant="solo"
        label="Search..."
        hint="Search Topwater Series"
      >
      </v-text-field>
    </v-sheet>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "AnglerSearch",

  data: () => ({
    searchDrawer: null,
  }),
};
</script>
<style lang="scss" scoped>
.btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
