<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-container>
    <h1 class="text-h4 mb-3">Topwater Refund Policy</h1>
    <v-divider class="mb-5"></v-divider>
    <h1 class="text-h5 mb-3">Refunds &amp; Cancellations</h1>
    <v-alert
      color="red"
      title="Cancellations"
      text="In the situation where an event must be cancelled each competitor will automatically be refunded within 72 hours of cancellation."
      variant="tonal"
      class="mb-5"
    ></v-alert>
    <v-alert
      title="Alterations"
      text="In the event that a tournament must be altered, either the location or the time, due to unforeseen circumstances, anyone that has signed up will be given the option to request a refund."
      class="mb-5"
    ></v-alert>
    <v-alert title="Request to Refund" class="mb-5">
      If you decide you are unable to fish an event and would like to cancel you
      may do so. Because of the limited amount of available spots in tournaments
      and the processing time required to refund and modify event totals there
      is a deadline for each event to request a refund. To request a refund, use
      the contact page to contact a tournament director before the deadline
      listed below. All deadlines are hours before the tournament begins.
      <v-divider class="mt-4 mb-4"></v-divider>
      <ul>
        <li>League Series - <strong> 24 Hours</strong></li>
        <li>Elite Series - <strong>72 Hours</strong></li>
      </ul>
    </v-alert>
  </v-container>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
export default defineComponent({
  name: "RefundPolicy",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
  },
  data: () => ({}),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
