<template>
  <div>
    <div class="imagePreviewWrapper" @click="selectImage">
      <v-img :src="previewImage"></v-img>
      <span class="eidt-picture"
        ><v-icon color="primary" icon="mdi:mdi-file-edit-outline"></v-icon
      ></span>
    </div>

    <v-file-input
      :rules="rules"
      accept="image/png, image/jpeg, image/bmp"
      :placeholder="kayakImage"
      prepend-icon="mdi-camera"
      :label="kayakImage"
      @input="pickFile"
      ref="fileInput"
      class="d-none"
    >
    </v-file-input>
  </div>
</template>

<script>
export default {
  data() {
    return {
      previewImage: null,
    };
  },
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.imagePreviewWrapper {
  width: 100%;
  height: 100%;
  display: block;
  min-height: 150px;
  cursor: pointer;
  background: #ededed;
  position: relative;
  .eidt-picture {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
    background: rgba($color: #000000, $alpha: 0.8);
    color: #fff;
    border-radius: 0 0 20px 0;
    &:hover {
      background: #000;
    }
  }
}
</style>
