<template>
  <div class="footer-holder">
    <footer id="footer">
      <v-container>
        <v-row>
          <v-col cols="12" md="3">
            <strong>Follow us</strong>
            <div class="social-nav">
              <a href="https://www.facebook.com/TopwaterSeries/"
                ><v-icon color="white" :width="50">
                  mdi:mdi-facebook
                </v-icon></a
              >
              <a
                href="https://www.youtube.com/channel/UC0zYeoqEQ2KaOcdEN5rCrNw?view_as=subscriber"
                ><v-icon color="white" :width="50"> mdi:mdi-youtube </v-icon></a
              >
              <a href="https://www.instagram.com/topwaterseries/"
                ><v-icon color="white" :width="50">
                  mdi:mdi-instagram
                </v-icon></a
              >
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <ul>
              <li>
                <a href="./RefundPolicy">Refund Policy</a>
              </li>
              <li>
                <a href="./PrivacyPolicy">Pricacy Policy</a>
              </li>
              <li>
                <a href="./TopwaterRules">Rules</a>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" md="3">
            <ul>
              <li>
                <a href="./AnglerListing">Anglers</a>
              </li>
              <li>
                <a href="./TopwaterSponsors">Sponsors</a>
              </li>
              <li>
                <a href="./TopwaterHelp">Help</a>
              </li>
            </ul>
          </v-col>
          <v-col cols="12" md="3">
            <ul>
              <li>
                <a href="/ContactUs">Contact Us</a>
              </li>
              <li>
                <a href="./TermsConditions">Terms Of Services</a>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
      <div class="footer-bottom text-center">
        Copy Rights <a href="./">Topwater</a> All Rights Reserved
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "FooterComponent",

  data: () => ({
    trackerTopwater: require("../../assets/images/TRACKERADBOX.png"),
  }),
};
</script>
<style lang="scss" scoped>
.footer-holder {
  @media only screen and (min-width: 960px) {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  }
  position: relative;
}
#footer {
  padding: 70px 0;
  padding: 40px 0 0;
  color: #fff;
  background: #4c4c4c;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
  .social-nav {
    a {
      text-decoration: none;
      margin: 0 15px 0 0;
      font-size: 24px;
      &:hover {
        color: #ff8506;
        transition: all 0.5s;
        i {
          color: #ff8506 !important;
        }
      }
    }
  }
  ul {
    list-style-type: none;
    li {
      margin: 0 0 10px;
      a {
        color: #fff;
        &:hover {
          color: #ff8506;
        }
      }
    }
  }
}
.footer-bottom {
  background: #232323;
  font-size: 12px;
  line-height: 20px;
  padding: 10px 0;
  a {
    color: #ff8506;
    &:hover {
      color: #fff;
    }
  }
}
</style>
