<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />
  <v-layout>
    <AnglerSidebar />
    <v-main>
      <!---Angler dashboard container -->
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <h1 class="text-h5">{{ pageTitle }}</h1>
          <v-sheet class="justify-end d-flex">
            <AnglerSearch />
            <AnglerLocation />
          </v-sheet>
        </div>
        <v-divider class="mt-2 mb-7"></v-divider>
        <!-- Add New Tackle -->
        <v-dialog v-model="addNewTackle" max-width="800">
          <template v-slot:activator="{ props }">
            <v-btn color="primary" v-bind="props"> Add New Tackle </v-btn>
          </template>
          <v-card>
            <v-card-title>Add New Tackle</v-card-title>
            <v-divider></v-divider>
            <v-card-body class="pa-4">
              <!-- Add New Tacke Form -->
              <v-form @submit.prevent>
                <v-row class="mt-3 mb-3">
                  <v-col cols="12" md="8">
                    <v-row>
                      <v-col cols="12" class="pt-0 pb-0" sm="6"
                        ><v-text-field label="Name"></v-text-field
                      ></v-col>
                      <v-col cols="12" class="pt-0 pb-0" sm="6"
                        ><v-text-field label="Brand"></v-text-field
                      ></v-col>
                      <v-col cols="12" class="pt-0 pb-0" sm="6"
                        ><v-text-field label="Color"></v-text-field
                      ></v-col>
                      <v-col cols="12" class="pt-0 pb-0" sm="6"
                        ><v-text-field label="Vendor"></v-text-field
                      ></v-col>
                      <v-col cols="12" class="pt-0 pb-0" sm="6"
                        ><v-text-field label="Weight"></v-text-field
                      ></v-col>
                      <v-col cols="12" class="pt-0 pb-0" sm="6"
                        ><v-text-field label="Length"></v-text-field
                      ></v-col>
                      <v-col cols="12" class="pt-0 pb-0" sm="6"
                        ><v-text-field label="Depth"></v-text-field
                      ></v-col>
                      <v-col cols="12" class="pt-0 pb-0" sm="6"
                        ><v-text-field label="UPC"></v-text-field
                      ></v-col>
                      <v-col cols="12" class="pt-0 pb-0" sm="12"
                        ><v-textarea label="Tags"></v-textarea
                      ></v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4" class="mb-6">
                    <span>Add Bait Image</span>
                    <v-divider class="mt-2 mb-4"></v-divider>
                    <FilePreview />
                  </v-col>
                </v-row>
                <v-btn type="submit" color="secondary" width="150" class="mr-4"
                  >Save</v-btn
                >
                <v-btn color="default" @click="addNewTackle = false"
                  >Cancel</v-btn
                >
              </v-form>
            </v-card-body>
          </v-card>
        </v-dialog>
        <!-- Topbox list -->
        <v-sheet class="pa-4 pt-10">
          <v-row>
            <v-col
              cols="6"
              md="3"
              v-for="bait in topBoxList"
              :key="bait"
              class="d-flex align-center topbox-col"
              @click="bait.details = true"
            >
              <v-img :src="bait.baitImage"></v-img>
              <v-dialog v-model="bait.details" max-width="800">
                <v-card>
                  <v-card-title>{{ bait.tackleName }}</v-card-title>
                  <v-divider></v-divider>
                  <v-card-body class="pa-4">
                    <v-row>
                      <v-col cols="12" md="5">
                        <div class="d-flex mb-2">
                          <strong class="mr-3 bait-title">Brand:</strong
                          >{{ bait.brand }}
                        </div>
                        <v-divider class="mb-2"></v-divider>
                        <div class="d-flex mb-2">
                          <strong class="mr-3 bait-title">Color:</strong
                          >{{ bait.color }}
                        </div>
                        <v-divider class="mb-2"></v-divider>
                        <div class="d-flex mb-2">
                          <strong class="mr-3 bait-title">Vendor:</strong
                          >{{ bait.vendor }}
                        </div>
                        <v-divider class="mb-2"></v-divider>
                        <div class="d-flex mb-2">
                          <strong class="mr-3 bait-title">Weight:</strong
                          >{{ bait.weight }}
                        </div>
                        <v-divider class="mb-2"></v-divider>
                        <div class="d-flex mb-2">
                          <strong class="mr-3 bait-title">Length:</strong
                          >{{ bait.length }}
                        </div>
                        <v-divider class="mb-2"></v-divider>
                        <div class="d-flex mb-2">
                          <strong class="mr-3 bait-title">Depth:</strong
                          >{{ bait.depth }}
                        </div>
                        <v-divider class="mb-2"></v-divider>
                        <div class="d-flex mb-2">
                          <strong class="mr-3 bait-title">UPC:</strong
                          >{{ bait.upc }}
                        </div>
                        <v-divider class="mb-2"></v-divider>
                        <div class="d-flex mb-2">
                          <strong class="mr-3 bait-title">Tags:</strong>
                          <ul class="pl-4">
                            <li v-for="tag in bait.tags" :key="tag">
                              {{ tag }}
                            </li>
                          </ul>
                        </div>
                      </v-col>
                      <v-col cols="12" md="7" class="d-flex align-center">
                        <v-img :src="bait.baitImage"></v-img>
                      </v-col>
                    </v-row>
                  </v-card-body>
                  <v-card-actions>
                    <v-btn color="primary" @click="bait.details = false"
                      >Close</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-sheet>
      </v-container>
    </v-main>
  </v-layout>
  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
import AnglerSidebar from "../components/anglerDashboard/AnglerSidebar.vue";
import AnglerSearch from "../components/anglerDashboard/AnglerSearch.vue";
import AnglerLocation from "../components/anglerDashboard/AnglerLocation.vue";
import FilePreview from "../components/FilePreview.vue";
export default defineComponent({
  name: "TopBox",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
    AnglerSidebar,
    AnglerSearch,
    AnglerLocation,
    FilePreview,
  },
  data: () => ({
    pageTitle: "Top Box",
    addNewTackle: false,
    showBaitDetails: false,
    topBoxList: [
      {
        tackleName: "Rapala Ott's Garage Rocco Squarebill Crankbait",
        brand: "Rapala",
        color: "Bruised Citrus Shad",
        vendor: "Cabela's",
        weight: "3/8 oz",
        length: '2.5"',
        depth: "5 ft",
        upc: "3473509",
        tags: [
          "Designed by pro angler Ott Defoe",
          "Long-casting design",
          "Durable square circuit-board lip",
          "Dives to 5'",
          "Premium balsa wood construction",
          "VMC black nickel 1X-strong hybrid treble hooks",
        ],
        baitImage: require("../assets/images/topbox/rapala.png"),
        details: false,
      },
      {
        tackleName: "Bass Pro Shops XPS Bubblin' Toad",
        brand: "Bass Pro Shops",
        color: "Bluegill",
        vendor: "Bass Pro Shops",
        weight: "3/4 oz",
        length: '3.75"',
        depth: "0 ft",
        upc: "2588325",
        tags: [
          "Topwater frog with oversized chopper blade",
          "Tremendous surface wake",
          "Lively plopping sound",
          "Can be worked at any speed'",
          "Contoured body with 3D eyes",
          "Premium black nickel hooks",
        ],
        baitImage: require("../assets/images/topbox/basspro.png"),
        details: false,
      },
      {
        tackleName: "106DD Jerkbait",
        brand: "6th Sense",
        color: "Vendetta",
        vendor: "Cabela's",
        weight: "5/8 oz",
        length: '4.2"',
        depth: "7 ft",
        upc: "3515594",
        tags: [
          "Erratic dart-and-turn action",
          "Dives up to 7'",
          "Internal weight-transfer system",
          "Slow-floating or suspending presentation",
          "3 ultra-sharp treble hooks",
        ],
        baitImage: require("../assets/images/topbox/6thSense.png"),
        details: false,
      },
      {
        tackleName: "Strike King KVD Perfect Plastics Ocho",
        brand: "Strike King",
        color: "Green Pumpkin",
        vendor: "Cabelas",
        weight: "5/8 oz",
        length: '5.0"',
        depth: "Any ft",
        upc: "2992365",
        tags: [
          "Smooth beveled sides reflect light",
          "Precision molded of supersoft plastic",
          "Slow sink rate",
          "Ideal for jerkbaiting and wacky rigging",
          "Soft salt-impregnated plastic and exclusive coffee scent",
        ],
        baitImage: require("../assets/images/topbox/strikeking.png"),
        details: false,
      },
      {
        tackleName: "Z-Man Finesse TRD",
        brand: "Z-Man",
        color: "Mood Ring",
        vendor: "Cabelas",
        weight: "0 oz",
        length: '2.75"',
        depth: "Any ft",
        upc: "2774744",
        tags: [
          "The ideal finesse profile in a ready-to-fish package",
          "Eliminates the need to cut up ElaZtech baits to craft finesse baits",
          "Designed to be paired with Z-Man Finesse ShroomZ jigheads",
          'Designed for the "Ned Rig" finesse fishing technique',
        ],
        baitImage: require("../assets/images/topbox/zman.png"),
        details: false,
      },
      {
        tackleName: "Rapala Ott's Garage Rocco Squarebill Crankbait",
        brand: "Rapala",
        color: "Bruised Citrus Shad",
        vendor: "Cabela's",
        weight: "3/8 oz",
        length: '2.5"',
        depth: "5 ft",
        upc: "3473509",
        tags: [
          "Designed by pro angler Ott Defoe",
          "Long-casting design",
          "Durable square circuit-board lip",
          "Dives to 5'",
          "Premium balsa wood construction",
          "VMC black nickel 1X-strong hybrid treble hooks",
        ],
        baitImage: require("../assets/images/topbox/rapala.png"),
        details: false,
      },
      {
        tackleName: "Bass Pro Shops XPS Bubblin' Toad",
        brand: "Bass Pro Shops",
        color: "Bluegill",
        vendor: "Bass Pro Shops",
        weight: "3/4 oz",
        length: '3.75"',
        depth: "0 ft",
        upc: "2588325",
        tags: [
          "Topwater frog with oversized chopper blade",
          "Tremendous surface wake",
          "Lively plopping sound",
          "Can be worked at any speed'",
          "Contoured body with 3D eyes",
          "Premium black nickel hooks",
        ],
        baitImage: require("../assets/images/topbox/basspro.png"),
        details: false,
      },
      {
        tackleName: "106DD Jerkbait",
        brand: "6th Sense",
        color: "Vendetta",
        vendor: "Cabela's",
        weight: "5/8 oz",
        length: '4.2"',
        depth: "7 ft",
        upc: "3515594",
        tags: [
          "Erratic dart-and-turn action",
          "Dives up to 7'",
          "Internal weight-transfer system",
          "Slow-floating or suspending presentation",
          "3 ultra-sharp treble hooks",
        ],
        baitImage: require("../assets/images/topbox/6thSense.png"),
        details: false,
      },
      {
        tackleName: "Strike King KVD Perfect Plastics Ocho",
        brand: "Strike King",
        color: "Green Pumpkin",
        vendor: "Cabelas",
        weight: "5/8 oz",
        length: '5.0"',
        depth: "Any ft",
        upc: "2992365",
        tags: [
          "Smooth beveled sides reflect light",
          "Precision molded of supersoft plastic",
          "Slow sink rate",
          "Ideal for jerkbaiting and wacky rigging",
          "Soft salt-impregnated plastic and exclusive coffee scent",
        ],
        baitImage: require("../assets/images/topbox/strikeking.png"),
        details: false,
      },
      {
        tackleName: "Z-Man Finesse TRD",
        brand: "Z-Man",
        color: "Mood Ring",
        vendor: "Cabelas",
        weight: "0 oz",
        length: '2.75"',
        depth: "Any ft",
        upc: "2774744",
        tags: [
          "The ideal finesse profile in a ready-to-fish package",
          "Eliminates the need to cut up ElaZtech baits to craft finesse baits",
          "Designed to be paired with Z-Man Finesse ShroomZ jigheads",
          'Designed for the "Ned Rig" finesse fishing technique',
        ],
        baitImage: require("../assets/images/topbox/zman.png"),
        details: false,
      },
    ],
  }),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
<style lang="scss" scoped>
.topbox-col {
  background: #f7f7f7;
  border-radius: 5px;
  border: 1px solid #d3d2d2;
  cursor: pointer;
  &:hover {
    border: 1px solid #ff8506;
  }
}
.bait-title {
  width: 50px;
}
</style>
