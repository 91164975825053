<template>
  <!--Header Component-->
  <HeaderComponent />
  <!--Quick links scroller -->
  <QuickLinks />

  <v-layout>
    <AnglerSidebar />
    <v-main>
      <!---Angler dashboard container -->
      <v-container>
        <div class="d-flex justify-space-between align-center">
          <h1 class="text-h5">{{ pageTitle }}</h1>
          <v-sheet class="justify-end d-flex">
            <AnglerSearch />
            <AnglerLocation />
          </v-sheet>
        </div>
        <v-divider class="mt-2 mb-7"></v-divider>
        <h2 class="text-h5 mb-4">Featured Events</h2>
        <!-- Angler dashboard featured events -->
        <FeaturedEvents />
        <!-- Angler dashboard nearby places -->
        <h2 class="text-h5 mb-4 mt-4">Nearby Places</h2>
        <NearbyPlaces />
        <!-- Angler dashboard video-->
        <h2 class="text-h5 mb-4">Top Video</h2>
        <v-sheet class="mb-7">
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/EhtNgqy9Orc"
            title="Topwater Series video"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </v-sheet>
      </v-container>
    </v-main>
  </v-layout>

  <!-- Footer Component-->
  <FooterComponent />
</template>
<script>
import { defineComponent } from "vue";
import HeaderComponent from "../components/layout/HeaderComponent.vue";
import FooterComponent from "../components/layout/FooterComponent.vue";
import QuickLinks from "../components/QuickLinks.vue";
import AnglerSidebar from "../components/anglerDashboard/AnglerSidebar.vue";
import AnglerSearch from "../components/anglerDashboard/AnglerSearch.vue";
import AnglerLocation from "../components/anglerDashboard/AnglerLocation.vue";
import FeaturedEvents from "../components/anglerDashboard/FeaturedEvents.vue";
import NearbyPlaces from "../components/anglerDashboard/NearbyPlaces.vue";
export default defineComponent({
  name: "HomeView",
  components: {
    HeaderComponent,
    FooterComponent,
    QuickLinks,
    AnglerSidebar,
    NearbyPlaces,
    FeaturedEvents,
    AnglerSearch,
    AnglerLocation,
  },
  data: () => ({
    pageTitle: "My Angler Dashboard",
  }),
  methods: {
    async doSomething() {
      // errors.value = {};
      try {
        // const result = await api.users.soSomething({ id: "" });
        // await routerPush("route");
      } catch (e) {
        // errors.value = e.error?.errors
      }
    },
  },

  computed: {},
});
</script>
